import { progressIsFetchedForUser, flushStoredLessonProgress } from 'StoredProgress';
import {
    ensureAllContentStoredForStreams,
    studentDashboardIsFetchedForUser,
    getLocalePackIdsForStreamsToStore,
    StorableImageError,
    throwIfNotEnoughStorageAvailable,
} from 'StoredContent';
import logInDevMode from 'logInDevMode';
import { NotEnoughStorageError, storageSpaceAvailable } from 'storageSpaceAvailable';
import { EventEmitter } from 'events';
import {
    type FrontRoyalStoreDB,
    type FrontRoyalStore,
    dbHasPersistentStorage,
    SQLITE_PLUGIN,
    INDEXED_DB,
} from 'FrontRoyalStore';
import { DisconnectedError } from 'DisconnectedError';
import allTruthy from 'allTruthy';
import NetworkConnection from 'NetworkConnection';
import { type auto } from 'angular';
import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { type EventLogger } from 'EventLogger';
import { type FrontRoyalRouteService } from 'Navigation';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import cohortKeysForStudentDashboardFetch from 'FrontRoyalStore/cohortKeysForStudentDashboardFetch';
import { type AnyObject } from '@Types';
import { type StreamIguanaObject } from 'Lessons';
import { getCohort, type CurrentUserIguanaObject } from 'Users';
import showOfflineModal from './showOfflineModal';
import resolveRouteInOfflineMode from './resolveRouteInOfflineMode';
import canNavigateToCurrentRouteInOfflineMode from './canNavigateToCurrentRouteInOfflineMode';
import {
    TURNING_OFF_OFFLINE_MODE,
    TURNING_ON_OFFLINE_MODE,
    EVENT_STARTING_TO_STORE_CONTENT_FOR_OFFLINE_USE,
    EVENT_ALL_CONTENT_STORED_FOR_OFFLINE_USE,
} from './constants';

async function atLeastOneStreamWithContentStored(db: FrontRoyalStoreDB) {
    const ct = await db.publishedStreams.where({ all_content_stored: 1 }).count();
    return ct > 0;
}

export default class OfflineModeManager extends EventEmitter {
    EVENT_STARTING_TO_STORE_CONTENT_FOR_OFFLINE_USE = EVENT_STARTING_TO_STORE_CONTENT_FOR_OFFLINE_USE;
    EVENT_ALL_CONTENT_STORED_FOR_OFFLINE_USE = EVENT_ALL_CONTENT_STORED_FOR_OFFLINE_USE;

    private lastStoreContentCall: { abort?: () => void; promise: Promise<boolean> } | null = null;
    frontRoyalStore: FrontRoyalStore;
    injector: auto.IInjectorService;
    _cachedCanEnterOfflineMode: { time: number; promise: Promise<boolean> } | null = null;
    _inOfflineMode: boolean;
    _ensureCurriculumAvailableForOfflineUsePromise: Promise<unknown> | null = null;

    constructor(injector: auto.IInjectorService) {
        super();
        this.frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');
        this.injector = injector;

        // This will be set to true asynchronously
        // once a request is attempted
        this._inOfflineMode = false;

        // Note that this is asynchronous.  It takes a short
        // time for us to read the store and confirm that we
        // can enter offline mode.
        this.updateInOfflineMode().then(inOfflineMode => {
            if (!inOfflineMode) return;

            // I don't think this code is really necessary. See the docs in offline_mode.md labeled
            // "Launching the mobile app without a network connection". This just isn't how it happens.
            // If we don't see this log for a while, we can probably just remove this call to
            // updateInOfflineMode.
            const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
            ErrorLogService.notify('Surprisingly, the OfflineModeManager constructor can turn offline mode on.', null, {
                level: 'warning',
            });
        });
    }

    destroy() {
        this.removeAllListeners();
    }

    // Resolves with true if offline mode is enabled in the config,
    // and offline mode is supported on this device. Note that this
    // does not check if the store is enabled, because it is used
    // before we have loaded the current user and can check if the
    // store is enabled.
    async isOfflineModeSupported() {
        const db = await this.frontRoyalStore.getDb();

        // "PersistentStorage" here is not referring to storage.persist. It just means
        // "a database that stores data across page loads", i.e. not fake-indexed-db
        if (!dbHasPersistentStorage(db)) return false;

        const configRecord = await this.frontRoyalStore.getConfig();
        if (window.CORDOVA && configRecord?.enable_offline_mode === 'true') return true;

        // We would only really ever set it to this in local development
        if (configRecord?.enable_offline_mode === 'even_on_web') return true;

        return false;
    }

    // Resolves with true if isOfflineModeSupported() and
    // the things that are need in the database before going
    // into offline mode have been stored
    canEnterOfflineMode() {
        if (!this._cachedCanEnterOfflineMode || Date.now() - this._cachedCanEnterOfflineMode.time > 5000) {
            this._cachedCanEnterOfflineMode = {
                time: Date.now(),
                promise: this._determineCanEnterOfflineMode(),
            };
        }

        return this._cachedCanEnterOfflineMode.promise;
    }

    async _determineCanEnterOfflineMode() {
        if (!(await this.isOfflineModeSupported())) return false;

        const currentUser = await this.frontRoyalStore.getCurrentUser();

        if (!currentUser) return false;

        const stuffLoaded = await this.frontRoyalStore.retryOnHandledError(
            db =>
                allTruthy([
                    // In order to enter offline mode we need...

                    // ... a currentUser whose progress is fetched
                    progressIsFetchedForUser(currentUser.id, db),

                    // ... a currentUser whose student dashboard is fetched
                    this.frontRoyalStore.getConfig().then(configRecord =>
                        studentDashboardIsFetchedForUser(
                            {
                                userId: currentUser.id,
                                prefLocale: currentUser.pref_locale,
                                contentViewsRefreshUpdatedAt: configRecord.content_views_refresh_updated_at,
                                ...cohortKeysForStudentDashboardFetch(getCohort(currentUser)),
                            },
                            db,
                        ),
                    ),

                    // ... at least one offline stream to put on the dashboard
                    atLeastOneStreamWithContentStored(db),
                ]),
            {
                // This is checked during initialization, before we've had an opportunity to
                // enable the store
                allowDisabledFrontRoyalStore: true,
            },
        );

        return stuffLoaded;
    }

    abortStoringContentForOfflineMode() {
        return this.lastStoreContentCall?.abort?.();
    }

    async abortStoringContentAndDelete() {
        await this.abortStoringContentForOfflineMode();
        await this.frontRoyalStore.retryRequestOnHandledError('deleteContentStoredForOfflineMode');
    }

    // Users that have the FrontRoyalStore enabled have the ability to toggle
    // storing course content on/off via user preferences. If they choose to disable this feature,
    // we should flush all existing lesson progress first, and un-store all stream content
    async disableOfflineMode() {
        await Promise.all([flushStoredLessonProgress(this.injector), this.abortStoringContentAndDelete()]);
    }

    async currentUserDisabledStreamStoring() {
        const currentUser = await this.frontRoyalStore.getCurrentUser();
        return !currentUser?.pref_offline_mode || false;
    }

    /*
        Normally, api calls that fail because the user has gone
        offline never complete. The promise they generate is never
        resolved or rejected.  You can use this method to wrap an
        api call when you need to know if the user switched into
        offline mode while the call was in flight.

        The function, `fn`, provided as an argument to this method
        should make an api call and return a promise.  If, before
        the api call completes, we switch to offline mode, then the
        promise will be rejected with a DisconnectedError.  If we
        are already in offline mode then we will just throw a
        DisconnectedError immediately without ever making an api call.

        Flagging a request as a `background` request has a similar effect
        to this. The difference is that rejectInOfflineMode preserves the
        normal disconnected handling if the user loses an internet connection
        but cannot enter offline mode.  So, `background` is used when the
        user is not waiting on the results of a request and does not need to
        know if the request fails.  rejectInOfflineMode is used if you have
        special handling in the case that offline mode has been entered,
        but without offline mode you want network errors to be handled normally.
    */
    rejectInOfflineMode<T>(fn: () => Promise<T>) {
        if (this.inOfflineMode) return Promise.reject(new DisconnectedError());

        const promise = fn();

        return new Promise((resolve, reject) => {
            // Listen for us to enter offline mode.  If we
            // do, reject this promise with a DisconnectedError
            const rejectWithDisconnectedError = () => reject(new DisconnectedError());
            this.once(TURNING_ON_OFFLINE_MODE, () => {
                rejectWithDisconnectedError();
            });

            // If the promise completes normally (successfully
            // or not), return its result and cancel
            // the listener.
            promise
                .then(result => {
                    resolve(result);
                }, reject)
                .finally(() => {
                    this.removeListener(TURNING_ON_OFFLINE_MODE, rejectWithDisconnectedError);
                });
        });
    }

    async shouldBeInOfflineMode() {
        const offline = NetworkConnection.offline;
        return offline ? this.canEnterOfflineMode() : false;
    }

    get inOfflineMode() {
        return this._inOfflineMode || false;
    }

    set inOfflineMode(val) {
        const oldValue = this._inOfflineMode || false;
        this._inOfflineMode = val;

        if (oldValue && !this._inOfflineMode) {
            this.emit(TURNING_OFF_OFFLINE_MODE);
            const EventLogger = this.injector.get<EventLogger>('EventLogger');

            // label is not really important, but since we're required to have one, why not?
            EventLogger.log('offline_mode:turned_off', { label: 'off' });
        }

        if (!oldValue && this._inOfflineMode) {
            const EventLogger = this.injector.get<EventLogger>('EventLogger');
            this.emit(TURNING_ON_OFFLINE_MODE);
            // label is not really important, but since we're required to have one, why not?
            EventLogger.log('offline_mode:turned_on', { label: 'on' });
        }
    }

    // If there is no network connection and offline mode is
    // supported, then this method will switch inOfflineMode to true.
    // Otherwise, it will switch inOfflineMode to false.
    async updateInOfflineMode() {
        const origValue = this.inOfflineMode;
        this.inOfflineMode = await this.shouldBeInOfflineMode();

        // When leaving offline mode, immediately flush to get any updates that were made while we
        // were offline up to the server. This minimizes the possibility of pulling data down from the server
        // that overrides changes we made locally.
        if (origValue && !this.inOfflineMode) {
            await this.frontRoyalStore.flush();
        }

        if (this.inOfflineMode && !origValue) await this.requestPersistenceIfNecessary();

        return this.inOfflineMode;
    }

    // Called by the api error handler after a network
    // error is triggered by an api request.
    async showOfflineModalAfterDisconnectedError() {
        if (this.inOfflineMode) return true;

        if (await this.shouldBeInOfflineMode()) {
            await this.showOfflineModalThenEnterOfflineMode();

            const $route = this.injector.get<FrontRoyalRouteService>('$route');

            // If we are in the middle of resolving a route, then the route handling
            // will handle redirecting. Otherwise, we redirect them home, but only
            // if the user is on a route that they shouldn't be.
            if (!$route.frontRoyalIsResolvingRoute && !(await canNavigateToCurrentRouteInOfflineMode(this.injector))) {
                this.injector.get<FrontRoyalRootScope>('$rootScope').goHome();
            }
        }

        return this.inOfflineMode;
    }

    async resolveRoute() {
        const shouldBeInOfflineMode = await this.shouldBeInOfflineMode();
        const shouldEnterOfflineMode = !this.inOfflineMode && shouldBeInOfflineMode;

        if (shouldEnterOfflineMode) {
            // after showOfflineModal returns, inOfflineMode will be true
            await this.showOfflineModalThenEnterOfflineMode();
        }

        if (!shouldBeInOfflineMode) {
            this.inOfflineMode = false;
        }

        if (this.inOfflineMode) return resolveRouteInOfflineMode(this.injector);

        return null;
    }

    async showOfflineModalThenEnterOfflineMode() {
        await showOfflineModal(this.injector);
        await this.requestPersistenceIfNecessary();
        this.inOfflineMode = true;
    }

    async requestPersistenceIfNecessary() {
        const dbTechnology = await this.frontRoyalStore.getDbTechnology();
        let storagePersisted;

        // The SQLITE_PLUGIN persists things. That's the whole point of it.
        if (dbTechnology === SQLITE_PLUGIN) {
            storagePersisted = true;
        } else if (dbTechnology === INDEXED_DB) {
            storagePersisted = (await navigator?.storage?.persisted()) || false;
        } else {
            const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
            ErrorLogService.notifyInProd('Unexpected db technology in requestPersistenceIfNecessary', null, {
                dbTechnology,
            });
            return;
        }

        let requestedPersist = false;
        let persistRequestDuration;
        let userAskedToApprovePersist = false;
        if (!storagePersisted) {
            requestedPersist = true;
            const requestedPersistAt = Date.now();
            storagePersisted = (await navigator?.storage?.persist()) || false;

            // We track the duration here so we can make a guess as to whether or not the
            // user was prompted to allow storage to persist.  If the duration is short, then
            // they must not have been. If it was long, then they probably were.
            persistRequestDuration = Date.now() - requestedPersistAt;
            userAskedToApprovePersist = persistRequestDuration > 1000;
        }

        const EventLogger = this.injector.get<EventLogger>('EventLogger');
        EventLogger.log('offline_mode:determined_persistence', {
            label: storagePersisted,
            db_technology: dbTechnology,
            storage_persisted: storagePersisted,
            requested_persist: requestedPersist,
            persist_request_duration: persistRequestDuration,
            user_asked_to_approve_persist: userAskedToApprovePersist,
        });
    }

    // Called by the validation responder after an auth call fails
    async attemptOfflineAuthentication() {
        const inOfflineMode = await this.updateInOfflineMode();

        if (!inOfflineMode) return null;
        // We always expect to find a currentUser here, since we would
        // not have entered offline mode otherwise, ValidationResponder
        // seems like it would handle it fine even if we ended up returning
        // undefined here.
        return this.frontRoyalStore.getCurrentUser();
    }

    // FIXME: once all streams are coming from the store, this can just be a synchronous
    // check on `stream.all_content_stored`
    async streamIsAvailableOffline(stream: StreamIguanaObject) {
        return this.streamIdIsAvailableOffline(stream.id);
    }

    async streamIdIsAvailableOffline(streamId: string) {
        const canEnterOfflineMode = await this.canEnterOfflineMode();

        if (!canEnterOfflineMode) return false;

        const publishedStreamIdsWithAllContentStored = (await this.frontRoyalStore.retryRequestOnHandledError(
            'publishedStreamIdsWithAllContentStored',
        ))!;
        return !!publishedStreamIdsWithAllContentStored[streamId];
    }

    /*
        We wrap the public ensureCurriculumAvailableForOfflineUse around an internal
        call to handle the case where there is a second call to ensureCurriculumAvailableForOfflineUse
        before a previous one is complete.  In that case we just want to wait for the first
        one to finish before starting the second one.
    */
    async ensureCurriculumAvailableForOfflineUse() {
        const user = this.injector.get<FrontRoyalRootScope>('$rootScope').currentUser!;

        const isOfflineModeSupported = await this.isOfflineModeSupported();

        if (!isOfflineModeSupported) return undefined;

        if (this._ensureCurriculumAvailableForOfflineUsePromise) {
            this._ensureCurriculumAvailableForOfflineUsePromise = this._ensureCurriculumAvailableForOfflineUsePromise

                // use `finally` instead of `then` because we want to kick off the next call
                // regardless of whether the last one succeeded
                .finally(() => {
                    if (!user.pref_offline_mode) return;
                    this._ensureCurriculumAvailableForOfflineUse(user);
                });
        } else if (user.pref_offline_mode) {
            this._ensureCurriculumAvailableForOfflineUsePromise = this._ensureCurriculumAvailableForOfflineUse(user);
        }

        return this._ensureCurriculumAvailableForOfflineUsePromise;
    }

    async emitStoredStreamEvent({
        id: _id, // we used to use this back when this was integrated with puppeteer specs
        storedStreamCount,
        totalCount,
    }: {
        id: string;
        storedStreamCount: number;
        totalCount: number;
    }) {
        logInDevMode(this.injector, `${storedStreamCount} of ${totalCount} streams stored`);
    }

    async _ensureCurriculumAvailableForOfflineUse(user: CurrentUserIguanaObject) {
        const ErrorLogService = this.injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
        const EventLogger = this.injector.get<EventLogger>('EventLogger');
        try {
            // Before we even bother determining what streams should be stored for
            // offline use and storing those streams, we first check if the device
            // has enough storage space available to accommodate the streams.
            await throwIfNotEnoughStorageAvailable();
            const streamPackIds = await getLocalePackIdsForStreamsToStore(this.injector, user);
            this.emit(EVENT_STARTING_TO_STORE_CONTENT_FOR_OFFLINE_USE, streamPackIds);
            logInDevMode(
                this.injector,
                `There are ${streamPackIds.length} streams in the curriculum that could be loaded for offline mode.`,
            );

            // ensureAllContentStoredForStreams promise returns true if it completed and
            // false if it was aborted
            this.lastStoreContentCall = ensureAllContentStoredForStreams(streamPackIds, user, this.injector, {
                onStreamStored: (opts: Parameters<typeof this.emitStoredStreamEvent>[0]) => {
                    this.emitStoredStreamEvent(opts);
                },
            });
            const result = await this.lastStoreContentCall.promise;
            if (result) {
                this.emit(EVENT_ALL_CONTENT_STORED_FOR_OFFLINE_USE, streamPackIds);
            }
        } catch (err) {
            const error = err as AnyObject & Error;
            // We delete the db when the user logs out, but async functions that handle storing
            // content and things of that nature may still be trying to interact with the db even
            // though it's been closed.
            if (error.name === 'DatabaseClosedError') return;

            // If we go offline while trying to do this, just resolve.
            // We will try again later
            if (error.constructor === DisconnectedError) return;

            // Custom error thrown if we failed to fetch a blob for offline mode.
            // See fetchArrayBuffer in StoredImagesRepo.
            if (error.constructor === StorableImageError) return;

            let extraForSentry;

            // A NotEnoughStorageError can be thrown directly by the throwIfNotEnoughStorageAvailable in
            // `_ensureCurriculumAvailableForOfflineUse`or by `ensureAllContentStoredForStreams`.
            if (error.constructor === NotEnoughStorageError) {
                // We want to get a sense of how frequently these errors are occurring, so we log
                // it to Sentry as a warning, being sure to avoid the front royal store in the process.
                const spaceAvailable = await storageSpaceAvailable();
                EventLogger.log('offline_mode:skipping_offline_content_load', {
                    storage_space_available: spaceAvailable,
                });

                // We don't need to log to sentry in this case. It isn't really an error.
                return;
            }

            // For any other error, we want to know about it in Sentry, but it does not need to bubble up.  The code
            // that calls this is not going to do anything special if this fails.
            ErrorLogService.notifyInProd(error, undefined, extraForSentry);
        }
    }
}
