import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useCallback, useEffect, useState, useMemo, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { type User } from '@sendbird/chat';
import GroupChannel from '@sendbird/uikit-react/GroupChannel';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash/fp';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import { setPresetNewMessageRecipient, setShowNewMessage, setShowProfileUserId } from '../actions';
import { useStudentsToMessage } from '../utils/useStudentsToMessage';
import {
    getChannelFromUserChannelMap,
    getPresetNewMessageRecipient,
    getShowNewMessage,
    getShowProfileUserId,
} from '../selectors';
import { MessageInputWithoutContext } from './MessageInput';
import { DateSeparator } from './DateSeparator';
import { MessageContent } from './MessageContent';
import { SelectedUserPill } from './SelectedUserPill';
import { SelectUsersList } from './SelectUsersList';
import { NewChannelInfo } from './NewChannelnfo';
import { UserProfile } from './UserProfile';

const classes = {
    container: (show: boolean) =>
        clsx(
            'sm:tw-flex',
            'tw-w-full',
            'tw-border-s',
            'tw-border-slate-grey-mid-light',
            show ? 'tw-flex' : 'tw-hidden',
        ),
    channelBuilderContainer: (showingProfile: boolean) =>
        clsx(
            'tw-flex',
            'tw-flex-col',
            { 'tw-w-full': !showingProfile, 'md:tw-flex tw-hidden': showingProfile },
            'tw-relative',
            'tw-h-full',
            'tw-transition-all',
            'tw-grow',
        ),
    profilePanelContainer: (showingProfile: boolean) =>
        clsx('tw-z-10', 'tw-h-full', 'tw-border-s', 'tw-border-slate-grey-light', 'tw-transition-all', {
            'tw-w-0': !showingProfile,
            'tw-w-full md:tw-basis-[300px] md:tw-max-w-[300px] md:tw-grow-0 md:tw-shrink-0': showingProfile,
        }),
    header: {
        container: clsx(
            'tw-w-full',
            'tw-h-16',
            'tw-border-b',
            'tw-flex',
            'tw-border-slate-grey-mid-light',
            'tw-justify-between',
            'tw-items-center',
            'tw-flex-shrink-0',
        ),
        text: clsx('tw-text-lg', 'tw-text-black', 'tw-font-semibold', 'tw-ms-[20.5px]'),
        button: clsx('tw-me-[20.5px]', 'tw-text-lg', 'tw-text-black', 'sm:tw-hidden'),
    },
    addUser: {
        container: clsx(
            'tw-min-h-[50px]',
            'tw-border-b',
            'tw-border-slate-grey-mid-light',
            'tw-px-[20.5px]',
            'tw-flex',
            'tw-flex-shrink-0',
        ),
        label: clsx(
            'tw-text-[16px]',
            'tw-text-slate-grey',
            'tw-h-[50px]',
            'tw-w-fit',
            'tw-flex',
            'tw-items-center',
            'tw-me-[9px]',
        ),
        input: {
            container: clsx('tw-flex', 'tw-w-full', 'tw-flex-wrap', 'tw-items-center'),
            element: clsx(
                'tw-text-[16px]',
                'tw-placeholder-slate-grey-mid-darker',
                'tw-text-black',
                'tw-flex',
                'tw-flex-grow',
                'tw-border-none',
                'tw-min-w-[65px]',
                'tw-max-w-full',
                'focus:tw-outline-none',
            ),
        },
    },
    selectedUsersContainer: (showExistingPreview: boolean) =>
        clsx(
            showExistingPreview ? 'tw-m-0 tw-ml-[-1px] tw-mb-[-25px]' : 'tw-mx-[15.5px] tw-my-[11.5px]',
            'tw-flex-grow',
            'tw-overflow-hidden',
        ),
    suggestedLabel: clsx('tw-text-xs', 'tw-text-slate-grey', 'tw-mb-[15px]', 'tw-px-[10px]', 'tw-font-semibold'),
    inputContainer: clsx(
        'tw-h-[95px]',
        'tw-border-t',
        'tw-border-slate-grey-mid-light',
        'tw-pb-[24px]',
        'tw-pt-[15px]',
        'tw-w-full',
    ),
};

const ChannelPreview = ({ url }: { url: string }) => (
    <GroupChannel
        channelUrl={url}
        renderChannelHeader={() => <div className="tw-hidden" />}
        renderCustomSeparator={DateSeparator}
        renderMessageContent={p => <MessageContent {...p} onRefSet={() => undefined} />}
        renderMessageInput={() => <div className="tw-hidden" />}
    />
);

export const ChannelBuilder = () => {
    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);
    const dispatch = useDispatch();
    const show = useSelector(getShowNewMessage);
    const profileUserId = useSelector(getShowProfileUserId);
    const presetNewMessageRecipient = useSelector(getPresetNewMessageRecipient);
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { usersToAdd, suggestedUsers, userProfilesById: initialProfilesById } = useStudentsToMessage();

    const userProfilesById = useMemo(
        () =>
            presetNewMessageRecipient
                ? { ...initialProfilesById, [presetNewMessageRecipient.id]: presetNewMessageRecipient.profile }
                : initialProfilesById,
        [presetNewMessageRecipient, initialProfilesById],
    );

    const [inputValue, setInputValue] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [showSuggested, setShowSuggested] = useState(false);
    const selectedUserIds = useMemo(() => selectedUsers.map(u => u.userId), [selectedUsers]);
    const [showExisting, setShowExisting] = useState(false);
    const existingChannelForSelectedUsers = useSelector(state => getChannelFromUserChannelMap(state, selectedUserIds));

    useEffect(() => {
        if (!selectedUsers.length && suggestedUsers.length && !inputValue && !showSuggested) {
            setShowSuggested(true);
        }
        if ((selectedUsers.length || inputValue) && showSuggested) {
            setShowSuggested(false);
        }
    }, [inputValue, selectedUsers.length, showSuggested, suggestedUsers.length]);

    useEffect(() => {
        if (existingChannelForSelectedUsers && selectedUsers.length && !inputValue) {
            setShowExisting(true);
        }
        if (!existingChannelForSelectedUsers) {
            setShowExisting(false);
        }
    }, [existingChannelForSelectedUsers, inputValue, selectedUsers.length, showExisting]);

    useEffect(() => {
        if (inputValue) {
            setFilteredUsers(
                uniqBy('userId', usersToAdd.concat(suggestedUsers)).filter(u =>
                    u.nickname
                        .toLocaleLowerCase()
                        .match(inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').toLocaleLowerCase()),
                ),
            );
        } else {
            setFilteredUsers([]);
        }
    }, [inputValue, suggestedUsers, usersToAdd]);

    useEffect(() => {
        if (!selectedUsers.length && !!presetNewMessageRecipient) {
            const user = sdk.buildUserFromSerializedData(presetNewMessageRecipient.serializedUser);
            setSelectedUsers([user]);
            dispatch(setPresetNewMessageRecipient(null));
        }
    }, [selectedUsers, presetNewMessageRecipient, usersToAdd, suggestedUsers, dispatch, sdk]);

    const handleListItemClick = useCallback((user: User) => {
        setInputValue('');
        setSelectedUsers(users =>
            users.find(u => u.userId === user.userId)
                ? users.filter(u => u.userId !== user.userId)
                : users.concat(user),
        );
    }, []);

    const checked = useCallback((user: User) => !!selectedUsers.find(u => u.userId === user.userId), [selectedUsers]);

    return (
        <div className={classes.container(show)}>
            <div className={classes.channelBuilderContainer(!!profileUserId)}>
                <div className={classes.header.container}>
                    <h3 className={classes.header.text}>{t('messaging.newMessage')}</h3>
                    <button
                        type="button"
                        className={classes.header.button}
                        onClick={() => dispatch(setShowNewMessage(false))}
                    >
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className={classes.addUser.container}>
                    <div className={classes.addUser.label}>{t('messaging.toInput')}</div>
                    <div className={classes.addUser.input.container}>
                        {selectedUsers.map(u => (
                            <SelectedUserPill key={u.userId} user={u} handleListItemClick={handleListItemClick} />
                        ))}

                        <input
                            className={classes.addUser.input.element}
                            value={inputValue}
                            placeholder={!selectedUsers.length ? t('messaging.searchClassmates')! : undefined}
                            onChange={e => {
                                if (
                                    showExisting &&
                                    e.currentTarget.value !== inputValue &&
                                    existingChannelForSelectedUsers
                                ) {
                                    setShowExisting(false);
                                } else if (!showExisting && !e.currentTarget.value && existingChannelForSelectedUsers) {
                                    setShowExisting(true);
                                }
                                setInputValue(e.currentTarget.value);
                            }}
                        />
                    </div>
                </div>
                <div className={classes.selectedUsersContainer(showExisting)}>
                    {showSuggested && (
                        <>
                            <p className={classes.suggestedLabel}>{t('messaging.suggested')}</p>
                            <SelectUsersList
                                users={suggestedUsers}
                                checked={checked}
                                handleListItemClick={handleListItemClick}
                                userProfilesById={userProfilesById}
                            />
                        </>
                    )}
                    {showExisting ? (
                        <ChannelPreview url={existingChannelForSelectedUsers} />
                    ) : filteredUsers.length ? (
                        <SelectUsersList
                            users={filteredUsers}
                            checked={checked}
                            handleListItemClick={handleListItemClick}
                            userProfilesById={userProfilesById}
                        />
                    ) : (
                        <NewChannelInfo users={selectedUsers} userProfilesById={userProfilesById} />
                    )}
                </div>
                <div className={classes.inputContainer}>
                    <MessageInputWithoutContext
                        usersToMessage={selectedUsers}
                        existingChannel={existingChannelForSelectedUsers}
                    />
                </div>
            </div>
            <div className={classes.profilePanelContainer(!!profileUserId)}>
                {!!profileUserId && (
                    <UserProfile
                        profileUserId={profileUserId}
                        onSendMessageClick={() => {
                            dispatch(setShowProfileUserId(null));
                        }}
                    />
                )}
            </div>
        </div>
    );
};
