
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_header_view_model = window.Smartly.locales.modules.en.back_royal.navigation.app_header_view_model || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_header_view_model = {...window.Smartly.locales.modules.en.back_royal.navigation.app_header_view_model, ...{
    "begin_lesson": "Begin Lesson",
    "begin_test": "Begin Test",
    "lesson_complete": "Lesson Complete",
    "test_complete": "Test Complete",
    "screen_complete": "Screen complete",
    "try_again": "Try Again"
}
}
    