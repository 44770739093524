import clsx from 'clsx';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useSelector } from 'react-redux';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useMessagingConfig } from '../utils/useMessagingConfig';
import { ChannelList } from './ChannelList';
import { Channel } from './Channel';
import { getActiveChannelUrl, getLoading } from '../selectors';
import { ChannelBuilder } from './ChannelBuilder';

const classes = {
    container: clsx(
        'messageWidget',
        'sm:tw-shadow-[0px_0px_8px_5px_rgba(0,0,0,.2)]',
        'sm:tw-rounded-[8px]',
        'tw-absolute',
        'tw-z-[101]',
        'tw-w-full',
        'sm:tw-w-[calc(100vw-76px)]',
        'sm:tw-max-w-[948px]',
        'md:tw-max-w-[1024px]',
        'tw-h-[calc(100vh-40px-54px)]',
        'sm:tw-h-[75vh]',
        'sm:tw-max-h-[554px]',
        'md:tw-h-[85vh]',
        'md:tw-max-h-[800px]',
        'tw-top-[40px]',
        'sm:tw-top-[71.5px]',
        'tw-left-0',
        'ltr:sm:tw-left-[calc(100vw-calc(min(948px,calc(100vw-76px))+38px))]',
        'rtl:sm:tw-right-[calc(100vw-calc(min(948px,calc(100vw-76px))+38px))]',
        'ltr:md:tw-left-[calc(100vw-calc(min(1024px,calc(100vw-76px))+38px))]',
        'rtl:md:tw-right-[calc(100vw-calc(min(1024px,calc(100vw-76px))+38px))]',
        'rtl:sm:tw-left-auto',
        'tw-px-0',
        'tw-transition-transform',
        'tw-duration-[250ms]',
        'tw-opacity-0 tw-ease-out',
        'tw-opacity-100 tw-ease-in',
    ),
    arrow: clsx(
        'tw-top-[-4px]',
        'sm:tw-top-[-6px]',
        'tw-right-[95px]',
        'ltr:sm:tw-right-[56px]',
        'ltr:md:tw-right-[98px]',
        'rtl:sm:tw-left-[56px]',
        'rtl:md:tw-left-[98px]',
        'tw-h-[8.5px]',
        'sm:tw-h-[20px]',
        'tw-w-[8.5px]',
        'sm:tw-w-[20px]',
        'tw-bg-white',
        'tw-rotate-45',
        'tw-absolute',
    ),
    sendbirdWrapper: clsx(
        'tw-relative',
        'tw-h-full',
        'sm:tw-overflow-hidden',
        'sm:tw-rounded-[8px]',
        'tw-flex',
        'tw-bg-white',
    ),
    modalOverlay: clsx(
        'tw-relative',
        'tw-inset-0',
        'tw-h-[100vh]',
        'tw-w-[100vw]',
        'tw-bg-transparent',
        'tw-z-[100]',
        'tw-cursor-default',
    ),
};

export const MessageWidget = () => {
    const { appId, accessToken, userId } = useMessagingConfig();
    const sendbirdState = useSendbirdStateContext();
    const loading = useSelector(getLoading);
    const activeChannel = useSelector(getActiveChannelUrl);
    if (!userId || !accessToken || !appId || !sendbirdState) return null;

    return (
        <div className={classes.modalOverlay}>
            <div className={classes.container} aria-hidden onClick={e => e.stopPropagation()}>
                <div className={classes.arrow} />
                <div className={classes.sendbirdWrapper}>
                    {loading ? (
                        <FrontRoyalSpinner className="no-delay tw-m-auto" />
                    ) : (
                        <>
                            <ChannelList />
                            {activeChannel ? <Channel /> : <ChannelBuilder />}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
