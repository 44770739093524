import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getMostRecentPositionText } from 'CareerProfiles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { type SummarizedProgram, type SummarizedProgramLogo } from 'StudentNetwork/StudentNetwork.types';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { faClock, faLocationDot } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { useCurrentUserIguanaObject, useNavigationHelper } from 'FrontRoyalAngular';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { type AnyObject } from '@Types';
import { useChannelMetaData } from '../utils/useChannelMetaData';
import { MemberAvatars } from './MemberAvatars';
import {
    setActiveChannelUrl,
    setOpenChat,
    setPresetNewMessageRecipient,
    setShowChannelInfo,
    setShowNewMessage,
    setShowProfileUserId,
} from '../actions';
import { useStudentsToMessage } from '../utils/useStudentsToMessage';
import { DetailsPanelActionButton } from './DetailsPanelActionButton';

type Props = {
    profileUserId: string;
    channel?: GroupChannelType;
    onSendMessageClick?: () => void;
};

const classes = {
    container: clsx('tw-h-full', 'tw-flex-col', 'tw-flex'),
    header: {
        container: clsx(
            'tw-h-[64px]',
            'tw-grow-0',
            'tw-shrink-0',
            'tw-basis-[64px]',
            'tw-border-b',
            'tw-border-solid',
            'tw-border-slate-grey-mid-light',
            'tw-flex',
            'tw-items-center',
            'tw-justify-between',
            'tw-ps-5',
            'tw-pe-3',
        ),
        text: clsx('tw-font-semibold', 'tw-text-lg', 'tw-text-black'),
        closeButton: clsx(
            'hover:tw-bg-slate-grey-lightest',
            'tw-flex',
            'tw-justify-center',
            'tw-items-center',
            'tw-rounded-lg',
            'tw-p-2',
            'tw-cursor-pointer',
        ),
        closeIcon: clsx('tw-text-black', 'tw-text-lg'),
    },
    body: {
        container: clsx('tw-h-[calc(100%-64px)]', 'tw-overflow-y-auto'),
    },
    profile: {
        container: clsx('tw-p-5', 'tw-border-b', 'tw-border-solid', 'tw-border-slate-grey-mid-light'),
        avatar: {
            container: clsx('tw-mb-5', 'tw-flex', 'tw-justify-center', 'tw-items-center'),
            avatarImage: clsx('tw-h-[100px]', 'tw-w-[100px]'),
        },
        nameAndPosition: {
            container: clsx('tw-mb-5'),
            userName: clsx('tw-text-xl', 'tw-font-semibold', 'tw-text-black'),
            mostRecentPosition: clsx('tw-text-[16px]', 'tw-leading-[22px]', 'tw-text-black'),
        },
        currentStateData: {
            container: clsx('tw-mb-[30px]'),
            item: clsx('tw-flex', 'tw-items-center', 'tw-justify-start', 'tw-mb-2'),
            itemIcon: clsx(
                'tw-text-[16px]',
                'tw-me-3',
                'tw-text-slate-grey',
                'tw-leading-[22px]',
                'tw-w-[16px]',
                'tw-inline-block',
            ),
            itemOnlineStatus: (isOnline: boolean) =>
                clsx('tw-h-[10px]', 'tw-w-[10px]', 'tw-rounded-full', 'tw-me-[14px]', 'tw-ms-[3px]', {
                    'tw-bg-green': isOnline,
                    'tw-bg-transparent tw-border tw-border-solid tw-border-slate-grey': !isOnline,
                }),
            itemText: clsx('tw-text-[16px]', 'tw-text-black', 'tw-leading-[22px]'),
        },
        actions: {
            container: clsx(),
        },
    },
    programs: {
        container: clsx('tw-px-5', 'tw-py-[30px]', 'tw-border-b', 'tw-border-solid', 'tw-border-slate-grey-mid-light'),
        heading: clsx('tw-font-semibold', 'tw-text-[16px]', 'tw-mb-5', 'tw-text-black'),
        list: clsx('tw-list-none', 'tw-p-0'),
        listItem: clsx('tw-mb-[10px]', 'tw-flex', 'tw-items-start', 'tw-justify-start', 'tw-text-black'),
        logo: clsx('tw-w-[20px]', 'tw-h-[20px]', 'tw-me-2', 'tw-mt-[2px]'),
    },
    additionalActions: {
        container: clsx('tw-px-5', 'tw-py-[30px]', 'tw-border-b', 'tw-border-solid', 'tw-border-slate-grey-mid-light'),
        heading: clsx('tw-font-semibold', 'tw-text-[16px]', 'tw-mb-5', 'tw-text-black'),
    },
    noData: {
        container: clsx('tw-p-5', 'tw-flex', 'tw-justify-center', 'tw-items-center'),
        message: clsx('tw-text-slate-grey', 'tw-italic', 'tw-text-[16px]', 'tw-animate-fade-in'),
    },
};

export const UserProfile = ({ profileUserId, channel, onSendMessageClick }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal');
    const currentUser = useCurrentUserIguanaObject();
    const { loadRoute } = useNavigationHelper();
    const { summarizedProfilesByUserId, userProfilesById, sendbirdUsersByUserId, isLoading } = useStudentsToMessage();
    const metaData = useChannelMetaData(channel ?? null);
    const channelType = metaData?.channelType;
    const [isBlocked, setIsBlocked] = useState<boolean | null>(null);
    const [blockingOrUnblocking, setBlockingOrUnblocking] = useState(false);
    const [showNoDataMessage, setShowNoDataMessage] = useState(false);
    const sendbirdState = useSendbirdStateContext();
    const sdk = sendbirdSelectors.getSdk(sendbirdState);

    const sendbirdUser = sendbirdUsersByUserId[profileUserId];
    const summarizedProfile = summarizedProfilesByUserId[profileUserId];
    const careerProfileJson = userProfilesById[profileUserId];

    const allDataAvailable = sendbirdUser && summarizedProfile && careerProfileJson;

    const mostRecentPositionText = useMemo(() => {
        if (!careerProfileJson) return '';
        return getMostRecentPositionText(careerProfileJson, t);
    }, [careerProfileJson, t]);

    const programsByStartDateDesc = useMemo(() => {
        if (!summarizedProfile) return [];
        const programs: (SummarizedProgram & { logo: SummarizedProgramLogo })[] = [];
        Object.values(summarizedProfile.programs).forEach(brand => {
            brand.programs.forEach(program => {
                programs.push({ ...program, logo: { ...brand.logo } });
            });
        }, []);
        return programs.sort((a, b) => b.startDate - a.startDate);
    }, [summarizedProfile]);

    const handleCloseClick = useCallback(() => {
        dispatch(setShowProfileUserId(null));
    }, [dispatch]);

    const handleSendMessageClick = useCallback(() => {
        if (onSendMessageClick) {
            onSendMessageClick();
            return;
        }

        if (sendbirdUser && currentUser?.sendbird_access_token) {
            dispatch(setShowProfileUserId(null));
            dispatch(setShowChannelInfo(false));
            dispatch(
                setPresetNewMessageRecipient({
                    id: sendbirdUser.userId,
                    profile: careerProfileJson,
                    serializedUser: sendbirdUser.serialize() as AnyObject,
                }),
            );
            dispatch(setShowNewMessage(true));
        }
    }, [onSendMessageClick, sendbirdUser, currentUser?.sendbird_access_token, dispatch, careerProfileJson]);

    const handleViewInNetworkClick = useCallback(() => {
        loadRoute(`/student-network?ids=${careerProfileJson!.userId}`);
        dispatch(setOpenChat(false));
    }, [loadRoute, careerProfileJson, dispatch]);

    const handleArchiveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.hide({ hidePreviousMessages: false, allowAutoUnhide: true });
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);

    const getIsUserBlocked = useCallback(async () => {
        if (!sdk) return;
        const blockedUsers = await sdk.createBlockedUserListQuery({ userIdsFilter: [profileUserId] }).next();
        setIsBlocked(blockedUsers.length > 0);
    }, [profileUserId, sdk]);

    const handleBlockOrUnblockUserClick = useCallback(async () => {
        if (!sdk) return;
        setBlockingOrUnblocking(true);
        if (isBlocked) {
            await sdk.unblockUserWithUserId(profileUserId);
        } else {
            await sdk.blockUserWithUserId(profileUserId);
        }
        await getIsUserBlocked();
        setBlockingOrUnblocking(false);
    }, [profileUserId, sdk, isBlocked, getIsUserBlocked]);

    useEffect(() => {
        getIsUserBlocked();
    }, [getIsUserBlocked]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowNoDataMessage(true);
        }, 300);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.text}>{t('messaging.messaging.profile')}</h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon className={classes.header.closeIcon} icon={faTimes} />
                </button>
            </div>

            {isLoading && (
                <div>
                    <FrontRoyalSpinner color="force-brand" className="no-delay" />
                </div>
            )}
            {!isLoading && allDataAvailable && (
                <div className={classes.body.container}>
                    <div className={classes.profile.container}>
                        <div className={classes.profile.avatar.container}>
                            <MemberAvatars
                                className={classes.profile.avatar.avatarImage}
                                members={[sendbirdUser]}
                                showOnlineStatus={false}
                            />
                        </div>
                        <div className={classes.profile.nameAndPosition.container}>
                            <h4 className={classes.profile.nameAndPosition.userName}>{sendbirdUser.nickname}</h4>
                            <p className={classes.profile.nameAndPosition.mostRecentPosition}>
                                {mostRecentPositionText}
                            </p>
                        </div>
                        <div className={classes.profile.currentStateData.container}>
                            <p className={classes.profile.currentStateData.item}>
                                <FontAwesomeIcon
                                    className={classes.profile.currentStateData.itemIcon}
                                    icon={faLocationDot}
                                />
                                <span className={classes.profile.currentStateData.itemText}>
                                    {summarizedProfile.location}
                                </span>
                            </p>
                            <p className={classes.profile.currentStateData.item}>
                                <span
                                    className={classes.profile.currentStateData.itemOnlineStatus(
                                        sendbirdUser.connectionStatus === 'online',
                                    )}
                                />
                                <span className={classes.profile.currentStateData.itemText}>
                                    {t(
                                        `messaging.messaging.${
                                            sendbirdUser.connectionStatus === 'online' ? 'online' : 'offline'
                                        }`,
                                    )}
                                </span>
                            </p>
                            <p className={classes.profile.currentStateData.item}>
                                <FontAwesomeIcon className={classes.profile.currentStateData.itemIcon} icon={faClock} />
                                <span className={classes.profile.currentStateData.itemText}>
                                    {moment(new Date()).tz(summarizedProfile.timezone).format('h:mm A')}{' '}
                                    {t('messaging.messaging.local_time')}
                                </span>
                            </p>
                        </div>
                        <div className={classes.profile.actions.container}>
                            <DetailsPanelActionButton onClick={handleSendMessageClick}>
                                {t('messaging.messaging.message')}
                            </DetailsPanelActionButton>
                            {careerProfileJson!.prefStudentNetworkPrivacy !== 'anonymous' && (
                                <DetailsPanelActionButton onClick={handleViewInNetworkClick}>
                                    {t('messaging.messaging.view_in_network')}
                                </DetailsPanelActionButton>
                            )}
                        </div>
                    </div>
                    {programsByStartDateDesc.length > 0 && (
                        <div className={classes.programs.container}>
                            <h4 className={classes.programs.heading}>{t('messaging.messaging.programs')}</h4>
                            <ul className={classes.programs.list}>
                                {programsByStartDateDesc.map(p => (
                                    <li key={`${p.title}-${p.startDate}`} className={classes.programs.listItem}>
                                        <img
                                            className={classes.programs.logo}
                                            src={p.logo.imageOnly}
                                            alt={p.logo.alt}
                                        />{' '}
                                        <span>{p.title.replace(',', '')}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    <div className={classes.additionalActions.container}>
                        <h4 className={classes.additionalActions.heading}>
                            {t('messaging.messaging.additional_actions')}
                        </h4>
                        {channelType === '1-1' && (
                            <DetailsPanelActionButton onClick={handleArchiveChatClick}>
                                {t('messaging.messaging.archive_chat')}
                            </DetailsPanelActionButton>
                        )}
                        {isBlocked !== null && (
                            <DetailsPanelActionButton
                                variant="danger"
                                onClick={handleBlockOrUnblockUserClick}
                                disabled={blockingOrUnblocking}
                            >
                                {blockingOrUnblocking ? (
                                    <FrontRoyalSpinner color="force-brand" className="no-delay" />
                                ) : (
                                    t(`messaging.messaging.${isBlocked ? 'unblock_user' : 'block_user'}`)
                                )}
                            </DetailsPanelActionButton>
                        )}
                    </div>
                </div>
            )}
            {!isLoading && !allDataAvailable && showNoDataMessage && (
                <div className={classes.noData.container}>
                    <p className={classes.noData.message}>{t('messaging.messaging.profile_not_available')}</p>
                </div>
            )}
        </div>
    );
};
