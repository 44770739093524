import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useCallback, useMemo } from 'react';
import { useMessagingConfig } from '../utils';
import { setActiveChannelUrl, setShowChannelInfo, setShowProfileUserId } from '../actions';
import { useStudentsToMessage } from '../utils/useStudentsToMessage';
import { MemberAvatars } from './MemberAvatars';
import { DetailsPanelActionButton } from './DetailsPanelActionButton';

const classes = {
    container: clsx('tw-h-full', 'tw-flex', 'tw-flex-col'),
    header: {
        container: clsx(
            'tw-h-[64px]',
            'tw-grow-0',
            'tw-shrink-0',
            'tw-basis-[64px]',
            'tw-border-b',
            'tw-border-solid',
            'tw-border-slate-grey-mid-light',
            'tw-flex',
            'tw-items-center',
            'tw-justify-between',
            'tw-ps-5',
            'tw-pe-3',
        ),
        text: clsx('tw-font-semibold', 'tw-text-lg', 'tw-text-black'),
        closeButton: clsx(
            'hover:tw-bg-slate-grey-lightest',
            'tw-flex',
            'tw-justify-center',
            'tw-items-center',
            'tw-rounded-lg',
            'tw-p-2',
            'tw-cursor-pointer',
        ),
        closeIcon: clsx('tw-text-black', 'tw-text-lg'),
    },
    body: {
        container: clsx('tw-h-[calc(100%-64px)]', 'tw-overflow-y-auto'),
    },
    avatarAndName: {
        container: clsx('tw-p-5', 'tw-border-b', 'tw-border-solid', 'tw-border-slate-grey-mid-light'),
        avatar: clsx('tw-h-[100px]', 'tw-w-[100px]', 'tw-mx-auto', 'tw-mb-[14px]'),
        names: clsx(
            'tw-text-xl',
            'tw-font-semibold',
            'tw-text-center',
            'tw-mb-[10px]',
            'tw-leading-[28px]',
            'tw-text-black',
        ),
    },
    members: {
        container: clsx(
            'tw-pt-[30px]',
            'tw-px-0',
            'tw-pb-5',
            'tw-border-b',
            'tw-border-solid',
            'tw-border-slate-grey-mid-light',
        ),
        heading: clsx('tw-font-semibold', 'tw-text-[16px]', 'tw-mb-3', 'tw-px-5', 'tw-text-black'),
        list: clsx('tw-p-0', 'tw-m-0', 'tw-list-none', 'tw-w-full'),
        listItem: clsx('tw-m-0', 'tw-w-full'),
        listItemButton: clsx(
            'tw-w-full',
            'tw-flex',
            'tw-justify-start',
            'tw-items-start',
            'tw-px-5',
            'tw-py-[8px]',
            'tw-transition-colors',
            'hover:tw-bg-slate-grey-extra-light',
            'hover:tw-cursor-pointer',
        ),
        memberAvatar: clsx('tw-h-[30px]', 'tw-w-[30px]', 'tw-me-[10px]', 'tw-mt-0', 'tw-shrink-0'),
        avatarIndicatorOuter: clsx('tw-h-[12px]', 'tw-w-[12px]'),
        avatarIndicatorInner: clsx('tw-min-w-[9px]', 'tw-min-h-[9px]'),
        memberNameAndLocation: {
            container: clsx('tw-grow'),
            name: clsx(
                'tw-block',
                'tw-text-[16px]',
                'tw-leading-[1]',
                'tw-text-start',
                'tw-font-semibold',
                'tw-text-black',
            ),
            location: clsx('tw-block', 'tw-text-[13px]', 'tw-text-start', 'tw-text-slate-grey'),
        },
    },
    additionalActions: {
        container: clsx('tw-pt-[30px]', 'tw-px-5', 'tw-pb-5'),
        heading: clsx('tw-font-semibold', 'tw-text-[16px]', 'tw-mb-5', 'tw-text-black'),
    },
};

export const GroupChannelInfo = ({ channel }: { channel: GroupChannelType }) => {
    const dispatch = useDispatch();
    const { userId } = useMessagingConfig();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });

    const membersThatAreNotMe = useMemo(
        () => channel.members.filter(member => member.userId !== userId),
        [channel.members, userId],
    );

    const names = useMemo(
        () =>
            membersThatAreNotMe
                .map(member => member.nickname.split(/\s/)[0])
                .map((name, _, a) => {
                    const suffix =
                        name === a.at(-1)
                            ? ''
                            : name === a.at(-2)
                            ? `${membersThatAreNotMe.length > 2 ? ',' : ''} ${t('messaging.and')}`
                            : ',';
                    return `${name}${suffix}`;
                })
                .join(' '),
        [membersThatAreNotMe, t],
    );

    const truncatedNames = useMemo(() => {
        if (membersThatAreNotMe.length <= 4) return names;

        const membersOverTwo = membersThatAreNotMe.length - 2;
        const firstNames = membersThatAreNotMe.slice(0, 2).map(member => member.nickname.split(/\s/)[0]);
        return `${firstNames[0]}, ${firstNames[1]}, ${t('messaging.and_n_others', { count: membersOverTwo })}`;
    }, [membersThatAreNotMe, names, t]);

    const { summarizedProfilesByUserId, isLoading } = useStudentsToMessage();

    const handleCloseClick = useCallback(() => {
        dispatch(setShowChannelInfo(false));
    }, [dispatch]);

    const handleMemberClick = useCallback(
        (memberUserId: string) => {
            dispatch(setShowProfileUserId(memberUserId));
        },
        [dispatch],
    );

    const handleArchiveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.hide({ hidePreviousMessages: false, allowAutoUnhide: true });
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);

    const handleLeaveChatClick = useCallback(async () => {
        if (!channel) return;
        await channel.leave();
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
        dispatch(setActiveChannelUrl(''));
    }, [channel, dispatch]);
    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.text}>{t('messaging.chat_information')}</h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon className={classes.header.closeIcon} icon={faTimes} />
                </button>
            </div>

            {isLoading && (
                <div>
                    <FrontRoyalSpinner color="force-brand" className="no-delay" />
                </div>
            )}
            {!isLoading && (
                <div className={classes.body.container}>
                    <div className={classes.avatarAndName.container}>
                        <MemberAvatars
                            members={membersThatAreNotMe}
                            showOnlineStatus={false}
                            className={classes.avatarAndName.avatar}
                        />
                        <h3 className={classes.avatarAndName.names}>{truncatedNames}</h3>
                    </div>

                    <div className={classes.members.container}>
                        <h4 className={classes.members.heading}>{t('messaging.members')}</h4>
                        <ul className={classes.members.list}>
                            {membersThatAreNotMe.map(member => {
                                const summarizedProfile = summarizedProfilesByUserId[member.userId];
                                return (
                                    <li key={member.userId} className={classes.members.listItem}>
                                        <button
                                            type="button"
                                            className={classes.members.listItemButton}
                                            onClick={() => handleMemberClick(member.userId)}
                                        >
                                            <MemberAvatars
                                                members={[member]}
                                                className={classes.members.memberAvatar}
                                                showOnlineStatus
                                                onlineIndicatorOuterClassName={classes.members.avatarIndicatorOuter}
                                                onlineIndicatorInnerClassName={classes.members.avatarIndicatorInner}
                                            />
                                            <span className={classes.members.memberNameAndLocation.container}>
                                                <span className={classes.members.memberNameAndLocation.name}>
                                                    {member.nickname}
                                                </span>
                                                <span className={classes.members.memberNameAndLocation.location}>
                                                    {summarizedProfile?.location ?? ''}
                                                </span>
                                            </span>
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className={classes.additionalActions.container}>
                        <h4 className={classes.additionalActions.heading}>{t('messaging.additional_actions')}</h4>
                        <DetailsPanelActionButton onClick={handleArchiveChatClick}>
                            {t('messaging.archive_chat')}
                        </DetailsPanelActionButton>
                        <DetailsPanelActionButton variant="danger" onClick={handleLeaveChatClick}>
                            {t('messaging.leave_chat')}
                        </DetailsPanelActionButton>
                    </div>
                </div>
            )}
        </div>
    );
};
