
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.privacy_terms_footer = window.Smartly.locales.modules.en.back_royal.navigation.privacy_terms_footer || {};
window.Smartly.locales.modules.en.back_royal.navigation.privacy_terms_footer = {...window.Smartly.locales.modules.en.back_royal.navigation.privacy_terms_footer, ...{
    "privacy_policy": "Privacy Policy",
    "terms": "Terms"
}
}
    