module.exports = {
    'SCREEN-MD-MIN': 992,

    FONT_FAMILY_REGULAR: 'ProximaNovaSoft-Regular',
    FONT_FAMILY_SEMIBOLD: 'ProximaNovaSoft-Semibold',
    FONT_FAMILY_BOLD: 'ProximaNovaSoft-Bold',
    FONT_FAMILY_ITALICS: 'ProximaNova-RegIt',

    // whole palette (clockwise from 12)

    COLOR_V3_RED: '#E7384D',
    COLOR_V3_RED_LIGHT: '#ffdbdb',
    COLOR_V3_RED_DARK: '#842138',

    COLOR_V3_PURPLE: '#9557EC',
    COLOR_V3_PURPLE_NOT_QUITE_AS_DARK: '#7E4AC7',
    COLOR_V3_PURPLE_DARK: '#513584',
    COLOR_V3_PURPLE_HOVER: '#503883',
    COLOR_V3_PURPLE_LIGHT: '#d7baff',

    COLOR_V3_BLUE: '#4D7AFF',

    COLOR_V3_BLUE_CAREERS_LIGHTER: '#6e92ff', // used for background in desktop candidate card

    // oratner do we need to change this? its referred to in the UI_V3 buttons page
    // but doesnt exist in the color palette
    COLOR_V3_BLUE_LIGHT: '#B9CAFE',
    COLOR_V3_BLUE_LIGHTER: '#eff3fe',
    COLOR_V3_BLUE_NOT_SO_LIGHT: '#1a58c2',
    COLOR_V3_BLUE_DARK: '#194695',
    COLOR_V3_BLUE_DARKEST: '#0c2d67',
    COLOR_V3_BLUE_COMPLETION: '#1E5479',
    COLOR_V3_BLUE_HOVER: '#1C4894',
    COLOR_V3_BLUE_TWITTER: '#4099FF',
    COLOR_V3_BLUE_LINKEDIN: '#0077B5',
    COLOR_V3_BLUE_FACEBOOK: '#3b5998',
    COLOR_V3_BLUE_GOOGLE: '#4285f4',
    COLOR_V3_BLUE_WECHAT: '#47bb36',

    // Hover colors for social links
    COLOR_V3_BLUE_GOOGLE_HOVER: '#5a95f5',
    COLOR_V3_BLUE_FACEBOOK_HOVER: '#5074be',
    COLOR_V3_BLUE_TWITTER_HOVER: '#5aa7ff',
    COLOR_V3_BLUE_LINKEDIN_HOVER: '#0088cf',
    COLOR_V3_BLUE_WECHAT_HOVER: '#53c842',

    COLOR_V3_TURQUOISE_LIGHT: '#95D6CB',
    COLOR_V3_TURQUOISE: '#1DD0B4',
    COLOR_V3_TURQUOISE_DARK: '#106E6E',
    COLOR_V3_TURQUOISE_GRASSY_DARK: '#147867',
    COLOR_V3_TURQUOISE_HOVER: '#155F5E',

    COLOR_V3_GREEN: '#0DD037',
    COLOR_V3_GREEN_DARK: '#0A7823',
    COLOR_V3_GREEN_LIGHT: '#dbffe5',

    COLOR_V3_YELLOW: '#FFBE00',
    COLOR_V3_YELLOW_LIGHT: '#ffedba',
    COLOR_V3_YELLOW_DARK: '#896600',

    COLOR_V3_ORANGE: '#FF6357',
    COLOR_V3_ORANGE_DARK: '#953529',

    COLOR_V3_CORAL: '#FF4D63',
    COLOR_V3_CORAL_DARK: '#A52E3B',
    COLOR_V3_CORAL_HOVER: '#A12E3D',

    COLOR_V3_WHITE: '#FFFFFF',

    COLOR_V3_DARK_GREY: '#8F817D',
    COLOR_V3_GREY_COMPLETION: '#D8D8D8',

    COLOR_V3_GREY: '#9c9c9c',
    COLOR_V3_GREY_NOT_SO_LIGHT: '#9b9b9b',
    COLOR_V3_GREY_LIGHT: '#cacaca',
    COLOR_V3_GREY_LIGHTER: '#eaeaea',
    COLOR_V3_GREY_DARK: '#909090',
    COLOR_V3_GREY_DARKISH: '#666665',
    COLOR_V3_GREY_DARKER: '#4a4a4a',
    COLOR_V3_GREY_DARK_BLUE: '#3e495c',
    COLOR_V3_GREY_SHUTTLE: '#59687c',

    $COLOR_V3_BETA_GREY: '#5c5752',

    // background palette
    COLOR_V3_BEIGE_FOR_TEXT: '#776F65',
    COLOR_V3_BEIGE_BEYOND_BEYOND_DARK: '#847D76',
    COLOR_V3_BEIGE_BEYOND_DARK: '#A9A197',
    COLOR_V3_BEIGE_DARKEST: '#B5A99E',
    COLOR_V3_BEIGE_DARKER: '#C0B8AE',
    COLOR_V3_BEIGE_DARK: '#D4CCC3',
    COLOR_V3_BEIGE_MIDDARK: '#DFD9D2',
    COLOR_V3_BEIGE_MEDIUM: '#EAE6E1',
    COLOR_V3_BEIGE: '#EEEBE8',
    COLOR_V3_BEIGE_LIGHT: '#F7F4F0',
    COLOR_V3_BEIGE_LIGHTER: '#FAF6F0',
    COLOR_V3_BEIGE_LIGHTEST: '#F5F3F1',

    // Colors from pixelmatters
    COLOR_PIXELMATTERS_GREEN: '#54de6f',
    COLOR_PIXELMATTERS_GREEN_DARKER: '#4bc463',
    COLOR_PIXELMATTERS_GREY_DARK: '#757068',
    COLOR_PIXELMATTERS_GREY_LIGHT: '#dfd9d3',

    // Convenience Aliases

    // $COLOR_ANSWER_CORRECT: $COLOR_V3_GREEN;
    // $COLOR_ANSWER_INCORRECT: $COLOR_V3_YELLOW;
    // $COLOR_ANSWER_NEUTRAL: $COLOR_V3_BLUE;
    // $BLANK_UNDERLINE_SELECTED_COLOR: $COLOR_V3_CORAL;
    // $BLANK_UNDERLINE_UNSELECTED_COLOR: rgb(0, 0, 0);

    // $FILL_IN_THE_BLANK_UNSELECTED_BOX_SHADOW: inset 0px 0px 8px 0px rgba($BLANK_UNDERLINE_UNSELECTED_COLOR, 0.3);
    // $FILL_IN_THE_BLANK_SELECTED_BOX_SHADOW: inset 0px 0px 0px 2px $BLANK_UNDERLINE_SELECTED_COLOR,
    // inset 0px 0px 12px 0px $BLANK_UNDERLINE_SELECTED_COLOR;
    // $BLANKS_ON_IMAGE_SELECTED_BOX_SHADOW: inset 0px 0px 0px 2px rgba(# fff, 0.8),
    // 0px 0px 2px 1px rgba('BLANK_UNDERLINE_SELECTED_COLOR, 0.8);

    // Useful dark colors

    COLOR_V3_PLUM: '#600447',
    COLOR_V3_EGGPLANT: '#35092C',
    COLOR_V3_BLACK: '#010101',

    // map colors
    COLOR_V3_MAP_BLUE_DARKER: '#464f5d',
    COLOR_V3_MAP_BLUE_DARK: '#5C687C',
    COLOR_V3_MAP_BLUE_MID: '#9CA8B9',
    COLOR_V3_MAP_BLUE_MIDLIGHT: '#d7dbe2',
    COLOR_V3_MAP_BLUE_LIGHT: '#EEF0F3',

    // Registration and Login colors

    LOGIN_REGISTER_TEXT_GRAY: '#615A52',
    COLOR_MARKETING_CORAL_HOVER: '#e54558',
    COLOR_MARKETING_TURQUOISE: '#14CCA7',

    // Miscellaneous marketing colors from pixelmatters
    COLOR_MARKETING_LIGHT_GRAY: '#ccc',
    COLOR_MARKETING_GRAY: '#aaa',

    COLOR_V3_SIDE_NAV_ACTIVE: '#4a4a4a',
    COLOR_V3_MESSAGE_BOX_COLOR: '#2d3e53',
    COLOR_V3_BLUISH_GRAY_COLOR: '#2d3e53',
    COLOR_V3_BLUISH_GRAY_LIGHT_COLOR: '#d8dade',

    // Valar Landing/Login/Signup Pages
    COLOR_V3_PRIMARY_GRAY: '#b1b1b1',
    COLOR_V3_NEUTRAL_100: '#e7e8eb',
    COLOR_V3_NEUTRAL_200: '#cfd1d6',
    COLOR_V3_NEUTRAL_400: '#878b99',
    COLOR_V3_NEUTRAL_500: '#6f7485',
    COLOR_V3_NEUTRAL_700: '#3f465c',
    COLOR_V3_SECONDARY_DEEP_BLUE: '#1f45b6',

    // Tutorbot-specific Colors
    COLOR_V3_TUTORBOT_BORDER_GREY: '#D9D6D3',
    COLOR_V3_TUTORBOT_CHAT_TEXT_GREY: '#7B746A',
    COLOR_V3_TUTORBOT_EVALUATION_ICON_GREY: '#CFCDCD',
};
