import { type CurrentUserIguanaObject } from 'Users';
import navigationSectionConfigs from './navigationSectionConfigs';
import { type NavigationSectionConfig } from './Navigation.types';
import getDirectiveForEvents from './getDirectiveForEvents';

export * from './Navigation.types';
export * from './useAppHeaderViewModel';
export * from './constants';

export function getAvailableNavigationSections(
    $injector: angular.auto.IInjectorService,
    currentUser: CurrentUserIguanaObject,
    sectionsToHide: string[] = [],
): [NavigationSectionConfig[], NavigationSectionConfig[]] {
    const allConfigs = navigationSectionConfigs($injector, currentUser);
    const visibleConfigs = allConfigs.filter(config => {
        if (sectionsToHide.includes(config.name)) return false;

        return config.shouldBeVisible();
    });

    return [visibleConfigs, allConfigs];
}

export { getDirectiveForEvents };
