import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import { setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import template from 'Navigation/angularModule/views/sessions_navigation.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('sessionsNavigation', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const $window = $injector.get('$window');
        const $timeout = $injector.get('$timeout');
        const $route = $injector.get('$route');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const safeApply = $injector.get('safeApply');
        const JoinConfig = $injector.get('JoinConfig');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $location = $injector.get('$location');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                subscription: '<',
                urlPrefix: '<',
            },
            link(scope, elem) {
                NavigationHelperMixin.onLink(scope);

                const config = ConfigFactory.getSync();
                setupBrandNameProperties($injector, scope, { config });
                setupBrandScopeProperties(
                    $injector,
                    scope,
                    ['sessionsNavigationClosedLogo', 'sessionsNavigationOpenLogo'],
                    {
                        config,
                    },
                );

                JoinConfig.getConfig(scope.urlPrefix).then(urlConfig => {
                    scope.showNavElements = true;
                    scope.hasSubtitleMessage = !!urlConfig.subtitle_message;
                    scope.showFullWidthNavigation = !!urlConfig.full_width_navigation;

                    if (scope.showFullWidthNavigation) {
                        const translationHelper = new TranslationHelper('authentication.sign_in');

                        scope.fullWidthCtaText = scope.isOnSignInPage()
                            ? translationHelper.get('do_not_have_an_account')
                            : translationHelper.get('already_have_an_account');
                        scope.fullWidthCtaButtonText = scope.isOnSignInPage()
                            ? translationHelper.get('sign_up')
                            : translationHelper.get('log_in');
                    }
                });

                scope.isOnSignInPage = () => $location.path().includes('/sign-in');

                scope.ctaButtonClicked = () => {
                    if (scope.isOnSignInPage()) {
                        scope.gotoJoin();
                    } else {
                        scope.gotoSignIn();
                    }
                };

                Object.defineProperty(scope, 'currentRouteDirective', {
                    get() {
                        return $route && $route.current && $route.current.$$route && $route.current.$$route.directive;
                    },
                    configurable: true, // for tests
                });

                Object.defineProperty(scope, 'extraTallBackgroundGradient', {
                    get() {
                        // If there is a subtitle above the form container, then everything
                        // is pushed down and we need to make the background gradient
                        // extra tall to accomodate it
                        return scope.hasSubtitleMessage && scope.currentRouteDirective !== 'forgot-password';
                    },
                });

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                scope.navToggleClick = value => {
                    scope.navOpen = angular.isDefined(value) ? value : !scope.navOpen;

                    elem.find('.nav-elements ul li').removeClass('animate');
                    if (scope.navOpen) {
                        elem.find('.nav-elements ul li').each(function (i) {
                            const t = $(this);
                            $timeout(() => {
                                t.addClass('animate');
                            }, (i + 1) * 125);
                        });
                    }
                };

                // Start with nav closed
                scope.navOpen = false;

                // On resize of window, detect if nav is open and close it
                function onResize() {
                    if ($($window).width() > 767 && scope.navOpen) {
                        safeApply(scope, () => {
                            scope.navToggleClick(false);
                        });
                    }
                }
                $($window).on('resize', onResize);

                // remove listener when destroying directive
                scope.$on('$destroy', () => {
                    $($window).off('resize', onResize);
                });
            },
        };
    },
]);
