
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.OfflineMode = window.Smartly.locales.modules.en.back_royal.OfflineMode || {};
window.Smartly.locales.modules.en.back_royal.OfflineMode.showOfflineModal = window.Smartly.locales.modules.en.back_royal.OfflineMode.showOfflineModal || {};
window.Smartly.locales.modules.en.back_royal.OfflineMode.showOfflineModal = {...window.Smartly.locales.modules.en.back_royal.OfflineMode.showOfflineModal, ...{
    "click_to_continue_to_dashboard": "Click to continue to your dashboard and view offline courses.",
    "continue": "Continue",
    "only_offline_courses_available": "Until you reconnect, you will only be able to view courses that have been stored for offline use.  Click to continue.",
    "you_are_offline": "You are offline"
}
}
    