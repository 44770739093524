import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useCurrentUser } from 'FrontRoyalAngular';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Fade } from 'Fade/Fade';
import { useSelector, useDispatch } from 'react-redux';
import { MessageWidget } from './MessageWidget';
import { useMessagingConfig } from '../utils/useMessagingConfig';
import { MessagingProvider } from './MessagingProvider';
import { UnreadCount } from './UnreadCount';
import { getOpenChat } from '../selectors';
import { setOpenChat, setShowNewMessage } from '../actions';

const classes = {
    container: (open: boolean, mouseDown: boolean) =>
        clsx(
            'tw-absolute',
            'ltr:tw-right-[86px]',
            'rtl:tw-right-[86px]',
            'rtl:sm:tw-left-[86px]',
            'rtl:sm:tw-right-auto',
            'tw-top-[calc(50%-13px)]',
            'sm:tw-top-[calc(50%-18px)]',
            'tw-w-[25px]',
            'tw-h-[25px]',
            'sm:tw-w-[37px]',
            'sm:tw-h-[37px]',
            'md:tw-w-[125px]',
            'tw-border-none',
            'sm:tw-border-solid',
            'sm:tw-border',
            'tw-border-white',
            'tw-border-opacity-50',
            { 'tw-border-opacity-100': open },
            { 'tw-bg-coral-dark/[0.6] sm:tw-bg-coral valar:tw-bg-blue-dark/[0.6] sm:valar:tw-bg-blue': !open },
            { 'tw-bg-coral-dark sm:tw-bg-coral-dark/[.65] valar:tw-bg-blue-dark sm:valar:tw-bg-blue-dark/[.65]': open },
            { 'sm:tw-bg-coral-dark/[.25] sm:valar:tw-bg-blue-dark/[.25]': mouseDown },
            'active:tw-border-opacity-100',
            'hover:tw-border-opacity-100',
            'tw-rounded-[4px]',
            'sm:tw-rounded-full',
            'md:tw-rounded-[18px]',
            'tw-p-[5px]',
            'sm:tw-p-[10px]',
            'md:tw-p-[11px_15px_9px_15px]',
            'tw-z-[101]',
            'tw-cursor-pointer',
        ),
    content: clsx('tw-h-full', 'tw-flex', 'tw-items-center', 'tw-text-white'),
    text: clsx('tw-hidden', 'md:tw-block', 'tw-ms-[7px]'),
    sendbirdProviderWrapper: (open: boolean) => clsx('sendbirdProviderWrapper', open ? 'open' : 'closed'),
};

export const MessagesButton = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const user = useCurrentUser();
    const { appId, accessToken, userId } = useMessagingConfig();

    const open = useSelector(getOpenChat);
    const [mouseDown, setMouseDown] = useState(false);

    if (!user?.messagingEnabled || !appId || !accessToken || !userId) return null;

    return (
        <>
            <button
                type="button"
                className={classes.container(open, mouseDown)}
                onMouseDown={() => setMouseDown(true)}
                onMouseUp={() => setMouseDown(false)}
                onMouseOut={() => setMouseDown(false)}
                onBlur={() => setMouseDown(false)}
                onClick={() => {
                    if (open) {
                        dispatch(setShowNewMessage(false));
                    }
                    dispatch(setOpenChat(!open));
                }}
            >
                <div className={classes.content}>
                    <FontAwesomeIcon icon={faCommentDots} color="white" />
                    <p className={classes.text}>{t('messaging.messages')}</p>
                </div>
            </button>
            <div className={classes.sendbirdProviderWrapper(open)}>
                <MessagingProvider>
                    <>
                        <UnreadCount />
                        <Fade open={open} onClick={() => dispatch(setOpenChat(false))}>
                            <MessageWidget />
                        </Fade>
                    </>
                </MessagingProvider>
            </div>
        </>
    );
};
