
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.sharing_buttons = window.Smartly.locales.modules.en.back_royal.navigation.sharing_buttons || {};
window.Smartly.locales.modules.en.back_royal.navigation.sharing_buttons = {...window.Smartly.locales.modules.en.back_royal.navigation.sharing_buttons, ...{
    "email": "Email",
    "share": "Share",
    "tweet": "Tweet"
}
}
    