import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';
import sbSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';

const classes = {
    container: (count: number) =>
        clsx(
            'tw-absolute',
            'tw-pointer-events-none',
            'tw-h-[19px]',
            count > 99 ? 'tw-w-[26px]' : 'tw-w-[19px]',
            'tw-rounded-full',
            'tw-border',
            'tw-border-white',
            'tw-overflow-hidden',
            'sm:tw-top-[calc(50%-23px)]',
            'tw-right-[79px]',
            'rtl:sm:tw-right-auto',
            'rtl:sm:tw-left-[79px]',
            'tw-top-[calc(50%-18px)]',
            'sm:tw-right-[82px]',
            'tw-z-[110]',
        ),
    content: clsx(
        'tw-pointer-events-none',
        'tw-text-center',
        'tw-text-xxs',
        'tw-font-semibold',
        'tw-bg-blue',
        'tw-text-white',
        'tw-h-full',
        'tw-w-full',
    ),
};

export const UnreadCount = () => {
    const [unreadCount, setUnreadCount] = useState(0);
    const sbContext = useSendbirdStateContext();

    const sdk = sbSelectors.getSdk(sbContext);

    const messageChangeHandler = useMemo(
        () =>
            new UserEventHandler({
                onTotalUnreadMessageCountChanged: count => {
                    setUnreadCount(count.groupChannelCount);
                },
            }),
        [],
    );

    useEffect(() => {
        // Get initial count at mount
        sdk.groupChannel.getTotalUnreadMessageCount().then(v => {
            setUnreadCount(v);
        });
    }, [sdk]);

    useEffect(() => {
        // update count as new messages are received
        sdk.addUserEventHandler('message_count', messageChangeHandler);

        return () => {
            sdk.removeUserEventHandler('message_count');
        };
    }, [messageChangeHandler, sdk]);

    return (
        unreadCount > 0 && (
            <div className={classes.container(unreadCount)}>
                <div className={classes.content}>{unreadCount > 99 ? '99+' : unreadCount}</div>
            </div>
        )
    );
};
