import { type BaseMessage } from '@sendbird/chat/message';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getChannelName, useMessagingConfig, getRelativeTimeLocaleKey } from '../utils';
import { getChannelsByUrl } from '../selectors';
import { setActiveChannelUrl, setShowMessageSearch, setTargetSearchMessage } from '../actions';
import { MemberAvatars } from './MemberAvatars';

type Props = {
    messages: BaseMessage[];
    showChannelName: boolean;
};

const classes = {
    list: clsx('tw-w-full', 'tw-list-none', 'tw-p-0', 'tw-mb-5'),
    listItem: clsx('tw-w-full', 'tw-mb-3'),
    messageButton: clsx(
        'tw-w-full',
        'tw-px-5',
        'tw-py-3',
        'hover:tw-bg-slate-grey-extra-light',
        'tw-bg-transparent',
        'tw-transition-all',
        'tw-rounded-lg',
        'tw-flex',
        'tw-items-center',
        'tw-justify-between',
        'tw-cursor-pointer',
    ),
    avatar: clsx('tw-w-[40px]', 'tw-h-[40px]', 'tw-mt-[0.15rem]', 'tw-me-3', 'tw-self-start', 'tw-shrink-0'),
    messageInfo: clsx('tw-block', 'tw-flex-1'),
    senderAndDateWrapper: clsx('tw-flex', 'tw-items-center', 'tw-justify-between'),
    sender: clsx('tw-text-base', 'tw-font-semibold', 'tw-text-black'),
    message: clsx('tw-block', 'tw-text-sm', 'tw-text-slate-grey', 'tw-text-start'),
    date: clsx('tw-text-xs', 'tw-text-slate-grey'),
    channelName: clsx(
        'tw-block',
        'tw-w-full',
        'tw-text-start',
        'tw-text-xs',
        'tw-text-black',
        'tw-font-semibold',
        'tw-line-clamp-1',
        'tw-overflow-ellipsis',
    ),
};

export const MessageSearchResultsList = ({ messages, showChannelName }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const channelsByUrl = useSelector(getChannelsByUrl);
    const { userId } = useMessagingConfig();

    const handleMessageClick = useCallback(
        async (message: BaseMessage) => {
            const { channelUrl, messageId, createdAt } = message;
            dispatch(setShowMessageSearch(false));
            dispatch(setActiveChannelUrl(channelUrl));

            setTimeout(() => {
                // Navigates much more smoothly if we wait a sec here for channel to load and message refs to be set
                dispatch(setTargetSearchMessage({ messageId, createdAt }));
            }, 500);
        },
        [dispatch],
    );

    return (
        <ul className={classes.list}>
            {messages.map(message => {
                const channelName =
                    getChannelName(channelsByUrl[message.channelUrl], userId!) || t('messaging.no_members');
                return (
                    (message.isUserMessage() || message.isFileMessage()) && (
                        <li key={message.messageId} className={classes.listItem}>
                            <button
                                type="button"
                                className={classes.messageButton}
                                onClick={() => handleMessageClick(message)}
                            >
                                <MemberAvatars
                                    members={[message.sender]}
                                    className={classes.avatar}
                                    showOnlineStatus={false}
                                />
                                <span className={classes.messageInfo}>
                                    <span className={classes.senderAndDateWrapper}>
                                        <span className={classes.sender}>{message.sender.nickname}</span>
                                        <span className={classes.date}>
                                            {t(`messaging.${getRelativeTimeLocaleKey(new Date(message.createdAt))}`, {
                                                formattedDate: moment(message.createdAt).format('M/D/YY'),
                                            })}
                                        </span>
                                    </span>
                                    {showChannelName && (
                                        <span className={classes.channelName}>
                                            {t('messaging.in_chat')}: {channelName}
                                        </span>
                                    )}
                                    <span className={classes.message}>
                                        {message.isUserMessage() ? message.message : message.name}
                                    </span>
                                </span>
                            </button>
                        </li>
                    )
                );
            })}
        </ul>
    );
};
