import { ChannelType } from '@sendbird/chat';
import { type BaseMessage, type FileMessage } from '@sendbird/chat/message';
import {
    type CustomType,
    type SerializedChannel,
    type SerializedGroupChannel,
    type SerializedOpenChannel,
} from '../messaging.types';

export const isImageMessage = (message: BaseMessage): message is FileMessage =>
    message.isFileMessage() && message.type.startsWith('image');
export const isVideoMessage = (message: BaseMessage): message is FileMessage =>
    message.isFileMessage() && message.type.startsWith('video');
export const isImageOrVideo = (message: BaseMessage): boolean =>
    message.isFileMessage() && (isImageMessage(message) || isVideoMessage(message));

// Type guards for serialized channels
export const isSerializedGroupChannel = (
    serializedChannel: SerializedChannel,
): serializedChannel is SerializedGroupChannel => serializedChannel.channelType === ChannelType.GROUP;
export const isSerializedOpenChannel = (
    serializedChannel: SerializedChannel,
): serializedChannel is SerializedOpenChannel => serializedChannel.channelType === ChannelType.OPEN;

export const parseCustomType = (customTypeString: string) => {
    try {
        return JSON.parse(customTypeString) as CustomType;
    } catch {
        return null;
    }
};

export const stringifyCustomType = (customType: CustomType) => JSON.stringify(customType);
