/* We export constants in two different ways.
    First, we export each individual constant at the top
    level.  This is nice because then you can do:

        import oreo from 'Oreo';
        console.log(oreo.COLOR_V3_PURPLE);

    We also export constants as an object.  This is
    nice because you can do the following. Once we are
    totally moved away from angular and into a modular world,
    we might not need this anymore.

        import oreo from 'Oreo';
        angular.module('FrontRoyal.Oreo', [])
            .constant(oreoConstants)

*/

import constants from './constants';

const oreo = { ...constants };
oreo.constants = constants;
export default oreo;
