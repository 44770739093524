import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import 'FrontRoyalUiBootstrap/popover';
import template from 'Navigation/angularModule/views/app_menu.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('appMenu', [
    '$injector',
    function factory($injector) {
        const $window = $injector.get('$window');
        const $location = $injector.get('$location');
        const $route = $injector.get('$route');
        const ngToast = $injector.get('ngToast');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const TranslationHelper = $injector.get('TranslationHelper');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const offlineModeManager = $injector.get('offlineModeManager');
        const ConfigFactory = $injector.get('ConfigFactory');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                scope.offlineModeManager = offlineModeManager;
                scope.viewModel = AppHeaderViewModel;

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                // This has to be a getter/setter so it doesn't get
                // eaten up by the ng-if
                Object.defineProperty(scope, 'tourStep', {
                    get() {
                        return scope.$tourStep;
                    },
                    set(val) {
                        scope.$tourStep = val;
                    },
                });

                Object.defineProperty(scope, 'showApplicationLink', {
                    get() {
                        return !!scope.currentUser?.canCurrentlySubmitProgramApplication;
                    },
                });

                Object.defineProperty(scope, 'popoverIsOpen', {
                    get() {
                        return scope.$popoverIsOpen ?? false;
                    },
                    set(val) {
                        scope.$popoverIsOpen = val;
                    },
                });

                //-------------------------
                // Menu Display
                //-------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('navigation.app_menu');

                // NOTE: we also have CSS that hides these on media queries for web, but we want to ensure
                // that in hybrid, we never display this content as these modules will not be loaded. this
                // may change in a future date if we decide to support editor / admin on mobile clients.
                scope.editorAndReportsAndAdminEnabled = !$window.CORDOVA;

                //-------------------------
                // Navigation / Actions
                //-------------------------
                scope.clearStreamProgress = () => {
                    if ($window.confirm(translationHelper.get('clear_progress_confirm'))) {
                        scope.currentUser.clearAllProgress().then(() => {
                            $location.url('/courses');
                            $route.reload();
                            ngToast.create({
                                content: translationHelper.get('clear_progress_success'),
                                className: 'success',
                            });
                        });
                    }
                };

                ConfigFactory.getConfig().then(config => {
                    scope.config = config;
                });

                scope.goToRoute = (route, $event) => {
                    if ($event) {
                        // Prevent issues navigating on mobile devices
                        $event.preventDefault();
                        $event.stopPropagation();
                    }
                    // Close the popover before navigating to new route
                    scope.popoverIsOpen = false;
                    scope.loadRoute(route);
                };
            },
        };
    },
]);
