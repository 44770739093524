import { type BaseMessage } from '@sendbird/chat/message';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { type AnyObject } from '@Types';
import { isEqual } from 'lodash/fp';
import { type CareerProfile } from 'CareerProfiles';
import { type SerializedChannel, type MessagingSlice } from './messaging.types';

const initialState: MessagingSlice = {
    activeChannelUrl: '',
    replyMessageId: null,
    showChannelInfo: false,
    showProfileUserId: null,
    showMessageSearch: false,
    targetSearchMessage: null,
    highlightedMessageId: null,
    openChat: false,
    loading: false,
    showNewMessage: false,
    presetNewMessageRecipient: null,
    draftUser: null,
    userChannelMap: {},
    channelsByUrl: {},
};

export const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        setActiveChannelUrl(state, action: PayloadAction<string>) {
            state.showNewMessage = !action.payload;
            state.activeChannelUrl = action.payload;
        },
        setReplyMessageId(state, action: PayloadAction<number | null>) {
            state.replyMessageId = action.payload;
        },
        setShowChannelInfo(state, action: PayloadAction<boolean>) {
            state.showChannelInfo = action.payload;
        },
        setShowProfileUserId(state, action: PayloadAction<string | null>) {
            state.showProfileUserId = action.payload;
        },
        setShowMessageSearch(state, action: PayloadAction<boolean>) {
            state.showMessageSearch = action.payload;
        },
        setTargetSearchMessage(state, action: PayloadAction<Pick<BaseMessage, 'messageId' | 'createdAt'> | null>) {
            state.targetSearchMessage = action.payload;
        },
        setHighlightedMessageId(state, action: PayloadAction<number | null>) {
            state.highlightedMessageId = action.payload;
        },
        setOpenChat(state, action: PayloadAction<boolean>) {
            if (!action.payload) {
                state.draftUser = null;
            }
            state.openChat = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setShowNewMessage(state, action: PayloadAction<boolean>) {
            if (action.payload) {
                state.activeChannelUrl = '';
            }
            state.showNewMessage = action.payload;
        },
        setPresetNewMessageRecipient(
            state,
            action: PayloadAction<{ id: string; profile: CareerProfile; serializedUser: AnyObject } | null>,
        ) {
            state.presetNewMessageRecipient = action.payload;
        },
        setDraftUser(
            state,
            action: PayloadAction<{ id: string; profile: CareerProfile; serializedUser: AnyObject } | null>,
        ) {
            state.draftUser = action.payload;
        },
        setUserChannelMap(state, action: PayloadAction<AnyObject<string>>) {
            if (!isEqual(state.userChannelMap, action.payload)) {
                state.userChannelMap = action.payload;
            }
        },
        registerChannel(state, action: PayloadAction<SerializedChannel>) {
            state.channelsByUrl[action.payload.url] = action.payload;
        },
    },
});

export const { actions } = messagingSlice;
