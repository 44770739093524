import 'FrontRoyalStore/angularModule';
import 'Injector/angularModule';
import 'ErrorLogging/angularModule';
import 'FrontRoyalTimer/angularModule';

import OfflineModeManager from './OfflineModeManager';

angular
    .module('OfflineMode', ['FrontRoyalStore', 'Injector', 'FrontRoyal.ErrorLogService', 'FrontRoyalTimer'])
    .factory('offlineModeManager', [
        '$injector',
        $injector => {
            const injector = $injector.get('injector');
            const $rootScope = $injector.get('$rootScope');

            const offlineModeManager = new OfflineModeManager(injector);

            $rootScope.$on('$destroy', () => {
                offlineModeManager.destroy();
            });

            return offlineModeManager;
        },
    ]);
