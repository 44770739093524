import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import 'FrontRoyalUiBootstrap/popover';
import { setupStyleHelpers, setupBrandNameProperties, setupBrandScopeProperties } from 'AppBranding';
import { getAvailableNavigationSections, NavigationSectionKey } from 'Navigation';
import template from 'Navigation/angularModule/views/app_menu_user.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { getProgramSwitcherConfigs } from 'ProgramSwitcher';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('appMenuUser', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const offlineModeManager = $injector.get('offlineModeManager');
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                scope.viewModel = AppHeaderViewModel;
                NavigationHelperMixin.onLink(scope);
                scope.offlineModeManager = offlineModeManager;
                scope.popoverIsOpen = false;

                ConfigFactory.getConfig().then(config => {
                    scope.config = config;
                    setupStyleHelpers($injector, scope, { config });
                    setupBrandNameProperties($injector, scope, { config });
                    setupBrandScopeProperties($injector, scope, ['supportsSelectProgram']);
                });

                // since this can be rendered before authentication
                // completes, we have to watch for currentUser to change.
                // We also want to watch prefUserProgramState so that we can
                // get a fresh set of associated degree families if it changes.
                scope.$watchGroup(
                    [() => $rootScope.currentUser, () => $rootScope.currentUser?.prefUserProgramState],
                    values => {
                        const [currentUser] = values;
                        scope.currentUser = currentUser;

                        if (currentUser) {
                            scope.programConfigs = getProgramSwitcherConfigs($injector, currentUser);

                            const [navSections, allNavSections] = getAvailableNavigationSections(
                                $injector,
                                scope.currentUser,
                                [
                                    NavigationSectionKey.switch_program, // switch_program option is only needed on mobile
                                    NavigationSectionKey.application_status, // application_status should only be accessible via settings_dir
                                    NavigationSectionKey.application, // unique styling, so omit from `navSections` ng-repeat
                                    NavigationSectionKey.feedback, // unique styling, so omit from `navSections` ng-repeat
                                    NavigationSectionKey.sign_out, // unique styling, so omit from `navSections` ng-repeat
                                    NavigationSectionKey.nominations, // is in app menu on desktop and settings menu on mobile,
                                ],
                            );

                            scope.allNavSections = allNavSections;
                            // The remaining sections are iterated over in an ng-repeat
                            scope.availableSections = navSections;
                        }
                    },
                );

                // This method was added in lieu of using `popover-trigger="'click'" because it allows the user to miss a click
                // (ie, clicking on a header element) and not have the popover close on them. This way, clicking within the popover
                // itself only causes it to close when a nav section link is clicked.
                scope.navSectionOnClick = (sectionName, arg) => {
                    const navSection = scope.allNavSections.find(config => config.name === sectionName);
                    scope.hidePopover();
                    navSection.onClick(arg);
                };

                scope.$watchGroup(['currentUser', 'viewModel.layout'], () => {
                    scope.popoverTemplate = scope.currentUser ? 'default-popover' : 'no-user-popover';
                    scope.popoverClass = scope.currentUser ? 'menu-user-popover' : 'menu-no-user-popover';
                });

                scope.hidePopover = () => {
                    scope.popoverIsOpen = false;
                };
            },
        };
    },
]);
