import { type ChangeEvent, useCallback, useMemo, useState } from 'react';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSendbirdStateContext } from '@sendbird/uikit-react/useSendbirdStateContext';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { type GroupChannelContextType, useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import { ImageWithFallback } from 'ImageWithFallback';
import { faCommentLines } from '@fortawesome/pro-thin-svg-icons/faCommentLines';
import { type User } from '@sendbird/chat';
import { type BaseMessage } from '@sendbird/chat/message';
import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { useTranslation } from 'react-i18next';
import { getReplyMessageId } from '../selectors';
import { isImageMessage, isVideoMessage } from '../utils/typeUtils';
import { setActiveChannelUrl, setReplyMessageId } from '../actions';

const classes = {
    container: (parentMessage: boolean) =>
        clsx('tw-mt-[5px]', 'tw-border-0', { 'tw-border-t tw-border-[#0000001f]': parentMessage }, 'tw-px-[24.5px]'),
    parentMessage: {
        container: clsx('tw-flex', 'tw-justify-between', 'tw-py-4'),
        image: {
            container: clsx('tw-me-2', 'tw-flex', 'tw-items-center'),
            content: clsx('tw-max-h-11', 'tw-rounded-lg'),
        },
        messageInfo: {
            container: clsx('tw-flex', 'tw-w-full', 'tw-flex-col'),
            user: clsx('tw-font-semibold', 'tw-text-[#000000e0]', 'tw-text-xs', 'tw-flex'),
            content: clsx('tw-text-[#00000061]', 'tw-text-xxs', 'tw-break-anywhere', 'tw-line-clamp-3'),
        },
        exitButton: clsx(
            'tw-me-4',
            'tw-text-xl',
            'tw-p-1',
            'tw-rounded-lg',
            'hover:tw-bg-[#F2EFEC]',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
            'tw-w-[30px]',
            'tw-h-[30px]',
        ),
    },
    input: {
        container1: clsx(
            'tw-flex',
            'tw-rounded-[1.5em]',
            'tw-border',
            'tw-border-slate-grey-mid-light',
            'tw-bg-white',
            'tw-px-4',
            'tw-py-3',
            'tw-text-slate-grey',
            'tw-shadow-smallish',
        ),
        container2: clsx('tw-flex', 'tw-flex-1', 'tw-justify-between', 'tw-self-end'),
        commentIcon: {
            wrapper: clsx('tw-flex', 'tw-items-center'),
            icon: clsx('tw-text-xl', 'tw-text-slate-grey', 'tw-me-[15px]'),
        },
        textArea: {
            wrapper: clsx('tw-grid', 'tw-w-full'),
            content: clsx(
                'tw-overflow-hidden',
                'tw-resize-none',
                'tw-max-h-[285px]',
                'tw-overflow-y-auto',
                'tw-bg-transparent',
                'tw-placeholder-slate-grey',
                'tw-outline-none',
                'tw-scrollbar-hide',
                'disabled:tw-cursor-not-allowed',
                'disabled:tw-placeholder-slate-grey-mid-light',
            ),
            contentClone: clsx(
                'tw-invisible',
                'tw-whitespace-pre-wrap',
                'tw-resize-none',
                'tw-max-h-[285px]',
                'tw-overflow-y-auto',
                'tw-bg-transparent',
                'tw-placeholder-slate-grey',
                'tw-outline-none',
                'tw-scrollbar-hide',
                'disabled:tw-cursor-not-allowed',
                'disabled:tw-placeholder-slate-grey-mid-light',
            ),
        },
        iconButton: {
            wrapper: clsx('tw-flex', 'tw-flex-[0_0_1.5rem]', 'tw-items-center', 'tw-justify-center'),
            fileInputLabel: (disabled: boolean) =>
                clsx(
                    'tw-m-0',
                    'tw-block',
                    'tw-border-none',
                    'tw-bg-transparent',
                    'tw-p-0',
                    'tw-text-black',
                    'tw-transition-opacity',
                    'hover:tw-brightness-90',
                    disabled ? 'tw-cursor-not-allowed tw-opacity-70' : 'tw-cursor-pointer',
                ),
            fileInput: clsx('tw-hidden'),
            icon: clsx('tw-text-md', 'rtl:-tw-scale-x-100'),
            submitButton: clsx(
                'tw-m-0',
                'tw-block',
                'tw-border-none',
                'tw-bg-transparent',
                'tw-p-0',
                'tw-transition-opacity hover:tw-brightness-90',
                'disabled:tw-cursor-not-allowed',
                'disabled:tw-opacity-80',
                'quantic:tw-text-coral',
                'valar:tw-text-blue',
            ),
        },
    },
};

const MessageInputComponent = ({
    currentChannel,
    scrollToBottom = () => undefined,
    messages = [],
    usersToMessage,
    existingChannelUrl,
}: {
    currentChannel?: GroupChannelContextType['currentChannel'];
    scrollToBottom?: (animated?: boolean) => void;
    messages?: BaseMessage[];
    usersToMessage?: User[];
    existingChannelUrl?: string;
}) => {
    const messagingContext = useSendbirdStateContext();
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const isNewChannel = useMemo(() => !!usersToMessage, [usersToMessage]);

    const parentMessageId = useSelector(getReplyMessageId);

    const parentMessage = useMemo(
        () =>
            (parentMessageId
                ? messages.find(m => m.messageId === parentMessageId) || null
                : null) as CoreMessageType | null,
        [messages, parentMessageId],
    );

    const sendUserMessage = sendbirdSelectors.getSendUserMessage(messagingContext);
    const sendFileMessage = sendbirdSelectors.getSendFileMessage(messagingContext);
    const createChannel = sendbirdSelectors.getCreateGroupChannel(messagingContext);
    const getChannel = sendbirdSelectors.getGetGroupChannel(messagingContext);

    const [text, setText] = useState('');
    const isEmpty = useMemo(() => text.length < 1, [text.length]);

    const inputChangeHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setText(event.target.value);
    }, []);

    const getOrCreateChannel = useCallback(async () => {
        let channel = existingChannelUrl ? await getChannel(existingChannelUrl) : currentChannel;

        if (usersToMessage?.length && !channel) {
            channel = await createChannel({
                invitedUserIds: usersToMessage.map(u => u.userId),
            }).then(c => {
                c.createMetaData({ channelType: usersToMessage.length > 1 ? 'group' : '1-1' });
                return c;
            });
        }
        return channel!;
    }, [createChannel, currentChannel, existingChannelUrl, getChannel, usersToMessage]);

    const fileMessageHandler = useCallback(
        async (e: ChangeEvent<HTMLInputElement>) => {
            if (e?.target?.files?.[0]) {
                const file = e.target.files[0];

                const channel = await getOrCreateChannel();

                sendFileMessage(channel!, {
                    file,
                    parentMessageId: parentMessage?.messageId,
                    isReplyToChannel: !!parentMessage,
                })
                    .onPending(_p => {
                        dispatch(setReplyMessageId(null));
                    })
                    .onSucceeded(_m => {
                        if (!currentChannel) {
                            dispatch(setActiveChannelUrl(channel.url));
                        }
                        setTimeout(() => {
                            scrollToBottom(true);
                        }, 250);
                    })
                    .onFailed(_e => {
                        // TODO: check if state handling needed
                    });
            }
        },
        [currentChannel, dispatch, getOrCreateChannel, parentMessage, scrollToBottom, sendFileMessage],
    );

    const submitText = useCallback(async () => {
        const channel = await getOrCreateChannel();

        sendUserMessage(channel, {
            message: text,
            parentMessageId: parentMessage?.messageId,
            isReplyToChannel: !!parentMessage,
        })
            .onPending(_p => {
                setText('');
                dispatch(setReplyMessageId(null));
                // TODO: check if state handling needed
            })
            .onSucceeded(_m => {
                if (!currentChannel) {
                    dispatch(setActiveChannelUrl(channel.url));
                }
                setTimeout(() => {
                    scrollToBottom(true);
                }, 250);
            })
            .onFailed(_e => {
                // TODO: check if state handling needed
            });
    }, [currentChannel, dispatch, getOrCreateChannel, parentMessage, scrollToBottom, sendUserMessage, text]);

    return (
        <div className={classes.container(!!parentMessage)}>
            {parentMessage && (
                <div className={classes.parentMessage.container}>
                    <div className={classes.parentMessage.image.container}>
                        {isImageMessage(parentMessage) && (
                            <ImageWithFallback
                                src={parentMessage.url}
                                alt="parent message"
                                width={100}
                                height={100}
                                className={classes.parentMessage.image.content}
                            />
                        )}
                        {isVideoMessage(parentMessage) && (
                            <video
                                src={parentMessage.url}
                                className={classes.parentMessage.image.content}
                                width={100}
                                height={100}
                            >
                                <track kind="captions" />
                            </video>
                        )}
                    </div>
                    <div className={classes.parentMessage.messageInfo.container}>
                        <p className={classes.parentMessage.messageInfo.user}>
                            {parentMessage.isAdminMessage()
                                ? t('messaging.replyToAdmin')
                                : t('messaging.replyToName', { name: parentMessage.sender.nickname })}
                        </p>
                        <p className={classes.parentMessage.messageInfo.content}>
                            {parentMessage.isFileMessage()
                                ? parentMessage.name
                                : !parentMessage.isMultipleFilesMessage()
                                ? parentMessage.message
                                : t('messaging.files')}
                        </p>
                    </div>
                    <button
                        onClick={() => dispatch(setReplyMessageId(null))}
                        type="button"
                        className={classes.parentMessage.exitButton}
                    >
                        <FontAwesomeIcon icon={faXmark} color="#776F65" />
                    </button>
                </div>
            )}
            <div className={classes.input.container1}>
                <div className={classes.input.container2}>
                    <div className={classes.input.commentIcon.wrapper}>
                        <FontAwesomeIcon icon={faCommentLines} className={classes.input.commentIcon.icon} />
                    </div>
                    <div className={classes.input.textArea.wrapper}>
                        <textarea
                            className={classes.input.textArea.content}
                            style={{ gridArea: '1 / 1 / 2 / 2' }}
                            rows={1}
                            onChange={inputChangeHandler}
                            disabled={usersToMessage && !usersToMessage.length}
                            value={text}
                            onKeyDown={e => {
                                if (e.key !== 'Enter') return;
                                e.preventDefault();
                                submitText();
                            }}
                            placeholder={
                                parentMessage
                                    ? t('messaging.replyTo')!
                                    : isNewChannel
                                    ? t('messaging.startNewMessage')!
                                    : currentChannel?.members.filter(
                                          ({ userId }) => userId !== messagingContext.config.userId,
                                      ).length === 1
                                    ? t('messaging.messageTo', {
                                          name: currentChannel?.members.find(
                                              member => member.userId !== messagingContext.config.userId,
                                          )!.nickname,
                                      })!
                                    : t('messaging.messageGroup')!
                            }
                        />
                        <div
                            aria-hidden
                            // See AutoGrowTextAreaField.tsx for what is happening here
                            className={clsx(classes.input.textArea.contentClone)}
                            style={{ gridArea: '1 / 1 / 2 / 2' }}
                        >
                            {text}
                        </div>
                    </div>
                    <div className={classes.input.iconButton.wrapper}>
                        {isEmpty ? (
                            <label
                                htmlFor="attach-chat-file"
                                className={classes.input.iconButton.fileInputLabel(
                                    !!usersToMessage && !usersToMessage.length,
                                )}
                            >
                                <input
                                    id="attach-chat-file"
                                    type="file"
                                    className={classes.input.iconButton.fileInput}
                                    onChange={fileMessageHandler}
                                    disabled={usersToMessage && !usersToMessage.length}
                                    onClick={event => {
                                        event.currentTarget.value = '';
                                    }}
                                />
                                <FontAwesomeIcon className={classes.input.iconButton.icon} icon={faPaperclip} />
                            </label>
                        ) : (
                            <button
                                className={classes.input.iconButton.submitButton}
                                type="submit"
                                onClick={submitText}
                            >
                                <FontAwesomeIcon className={classes.input.iconButton.icon} icon={faPaperPlaneTop} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const MessageInputInContext = () => {
    const { currentChannel, scrollToBottom, messages } = useGroupChannelContext();

    return (
        <MessageInputComponent currentChannel={currentChannel!} scrollToBottom={scrollToBottom} messages={messages} />
    );
};

export const MessageInputWithoutContext = ({
    usersToMessage,
    existingChannel,
}: {
    usersToMessage?: User[];
    existingChannel?: string;
}) => <MessageInputComponent usersToMessage={usersToMessage} existingChannelUrl={existingChannel} />;
