import { type FrontRoyalRouteService } from './Navigation.types';
import { REACT_ROUTER_DIRECTIVE_NAME } from './constants';

/*
    Back when we were just using AngularJS, it seemed useful to track locations based on
    the directive, regardless of which path was pointing at that directive. With react routes, though,
    the directive is always react-router, which isn't very useful. It's not exactly analgous to fall
    back to the path, but it works fine with all the situations we have now, and should be good enough
    for logging forever, I'd guess.
*/
export default function getDirectiveForEvents(
    $route: FrontRoyalRouteService,
    $location: angular.ILocationService,
): string | null {
    const directive = $route.current?.$$route?.directive;
    if (directive === REACT_ROUTER_DIRECTIVE_NAME) {
        return $location.path();
    }
    return directive || null;
}
