
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.sessions_navigation = window.Smartly.locales.modules.en.back_royal.navigation.sessions_navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.sessions_navigation = {...window.Smartly.locales.modules.en.back_royal.navigation.sessions_navigation, ...{
    "pricing": "Pricing",
    "login": "Login",
    "join": "Get Started Now"
}
}
    