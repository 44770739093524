export default angular.module('OrderedHash', []).factory('OrderedHash', [
    '$injector',
    () => ({
        create(vals) {
            const orderedHash = [];
            vals.forEach(pair => {
                const key = pair[0];
                const val = pair[1];
                orderedHash.push(val);
                orderedHash[key] = val;
            });
            return orderedHash;
        },
    }),
]);
