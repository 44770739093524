import { type User } from '@sendbird/chat';
import clsx from 'clsx';
import { twMerge } from 'Utils/customTwMerge';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';

type Props = {
    members: User[];
    showOnlineStatus?: boolean;
    className?: string;
    onlineIndicatorOuterClassName?: string;
    onlineIndicatorInnerClassName?: string;
};

const classes = {
    avatar: {
        container: clsx(
            'tw-block',
            'tw-relative',
            'tw-h-[56px]',
            'tw-w-[56px]',
            'tw-rounded-full',
            'tw-bg-center',
            'tw-bg-cover',
            'tw-bg-no-repeat',
            'tw-bg-transparent',
        ),
        onlineIndicator: (isOnline: boolean) => ({
            outer: clsx(
                'tw-w-[14px]',
                'tw-h-[14px]',
                'tw-rounded-full',
                'tw-border-2',
                'tw-border-white',
                'tw-absolute',
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'tw-bottom-0',
                'tw-right-0',
                'tw-border-solid',
                { 'tw-bg-white': !isOnline },
                { 'tw-bg-green-600': isOnline },
            ),
            inner: clsx(
                'tw-block',
                'tw-min-w-[11px]',
                'tw-min-h-[11px]',
                'tw-rounded-full',
                'tw-border',
                'tw-border-solid',
                { 'tw-border-transparent tw-bg-green-600': isOnline },
                { 'tw-border-slate-grey tw-bg-transparent': !isOnline },
            ),
        }),
    },
    multiAvatar: {
        person1Avatar: clsx(
            'tw-absolute',
            'tw-bottom-0',
            'tw-left-0',
            'tw-h-[65%]',
            'tw-w-[65%]',
            'tw-rounded-full',
            'tw-bg-center',
            'tw-bg-cover',
            'tw-bg-no-repeat',
            'tw-bg-white',
            'tw-border-2',
            'tw-border-white',
            'tw-border-solid',
            'tw-z-10',
        ),
        person2Avatar: clsx(
            'tw-absolute',
            'tw-top-0',
            'tw-right-0',
            'tw-h-[65%]',
            'tw-w-[65%]',
            'tw-rounded-full',
            'tw-bg-center',
            'tw-bg-cover',
            'tw-bg-no-repeat',
            'tw-bg-white',
            'tw-border-2',
            'tw-border-white',
            'tw-border-solid',
            'tw-z-0',
        ),
    },
};

export const MemberAvatars = ({
    members,
    showOnlineStatus = false,
    className,
    onlineIndicatorInnerClassName,
    onlineIndicatorOuterClassName,
}: Props) => (
    <>
        {members.length === 0 && (
            <span
                className={twMerge(classes.avatar.container, className)}
                style={{
                    backgroundImage: `url(${defaultAvatarSrc})`,
                }}
            />
        )}

        {members.length === 1 && (
            <span
                className={twMerge(classes.avatar.container, className)}
                style={{
                    backgroundImage: `url(${members[0].plainProfileUrl}), url(${defaultAvatarSrc})`,
                }}
            >
                {showOnlineStatus && (
                    <span
                        className={twMerge(
                            classes.avatar.onlineIndicator(members[0]?.connectionStatus === 'online').outer,
                            onlineIndicatorOuterClassName,
                        )}
                    >
                        <span
                            className={twMerge(
                                classes.avatar.onlineIndicator(members[0]?.connectionStatus === 'online').inner,
                                onlineIndicatorInnerClassName,
                            )}
                        />
                    </span>
                )}
            </span>
        )}

        {members.length > 1 && (
            <span className={twMerge(classes.avatar.container, className)}>
                {/* Show the avatars of the first two channel members for groups */}
                <span
                    className={classes.multiAvatar.person1Avatar}
                    style={{
                        backgroundImage: `url(${members[0].plainProfileUrl}), url(${defaultAvatarSrc})`,
                    }}
                />
                <span
                    className={classes.multiAvatar.person2Avatar}
                    style={{
                        backgroundImage: `url(${members[1].plainProfileUrl}), url(${defaultAvatarSrc})`,
                    }}
                />
            </span>
        )}
    </>
);
