import { type GroupChannelListItemProps } from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListItem';
import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MemberAvatars } from './MemberAvatars';
import { getRelativeTimeLocaleKey } from '../utils/getRelativeTimeLocaleKey';
import { getChannelName } from '../utils/getChannelName';
import { isImageMessage, isVideoMessage, useMessagingConfig } from '../utils';
import { type SerializedChannel } from '../messaging.types';
import { setActiveChannelUrl, setShowChannelInfo, registerChannel, setShowProfileUserId } from '../actions';

const classes = {
    container: (isSelected: boolean) =>
        clsx(
            'tw-h-[84px]',
            'tw-w-full',
            'tw-min-w-[320px]',
            'tw-border-s-[5px]',
            'tw-border-solid',
            'tw-flex',
            'tw-justify-start',
            'tw-items-center',
            'tw-transition-all',
            'tw-ps-[11px]',
            'tw-pe-[16px]',
            {
                'tw-border-transparent tw-bg-white hover:tw-bg-slate-grey-extra-light': !isSelected,
                'tw-border-black tw-bg-slate-grey-extra-light': isSelected,
            },
        ),
    avatar: {
        container: clsx('tw-shrink-0', 'tw-h-[56px]', 'tw-w-[56px]', 'tw-me-2'),
    },
    channelInfo: {
        container: clsx('tw-flex-grow'),
        nameAndDate: {
            container: clsx('tw-flex', 'tw-justify-between', 'tw-items-center', 'tw-w-full', 'tw-h-[1em]'),
            name: clsx(
                'tw-font-semibold',
                'tw-text-black',
                'tw-text-[16px]',
                'tw-line-clamp-1',
                'tw-grow-1',
                'tw-overflow-ellipsis',
                'tw-text-left',
            ),
            date: clsx('tw-shrink-0', 'tw-text-slate-grey', 'tw-text-xxs'),
        },
        lastMessage: {
            container: clsx('tw-flex'),
            message: clsx(
                'tw-text-slate-grey',
                'tw-text-[13px]',
                'tw-text-left',
                'tw-line-clamp-2',
                'tw-overflow-ellipsis',
                'tw-grow',
                'tw-shrink',
                'tw-leading-[1.2]',
                'tw-h-[32px]',
            ),
            unreadCount: clsx(
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'tw-min-w-[20px]',
                'tw-h-[20px]',
                'tw-ms-2',
                'tw-px-1',
                'tw-rounded-full',
                'tw-bg-coral',
                'tw-text-white',
                'tw-text-[12px]',
                'tw-font-semibold',
                'tw-grow-0',
                'tw-shrink-0',
                'tw-self-center',
            ),
        },
    },
};

export const ChannelListItem = ({ channel, isSelected = false }: GroupChannelListItemProps) => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { userId } = useMessagingConfig();
    const dispatch = useDispatch();

    const serializedChannel = useMemo(() => channel.serialize() as SerializedChannel, [channel]);

    const membersThatAreNotMe = useMemo(
        () => channel.members.filter(member => member.userId !== userId),
        [channel.members, userId],
    );

    const channelName = useMemo(() => getChannelName(serializedChannel, userId!), [userId, serializedChannel]);

    const relativeTimeLocaleKey = useMemo(() => {
        if (!channel.lastMessage?.createdAt) return null;
        return getRelativeTimeLocaleKey(new Date(channel.lastMessage.createdAt));
    }, [channel.lastMessage]);

    const handleChannelClick = useCallback(() => {
        dispatch(setActiveChannelUrl(channel.url));
        dispatch(setShowChannelInfo(false));
        dispatch(setShowProfileUserId(null));
    }, [dispatch, channel.url]);

    useEffect(() => {
        dispatch(registerChannel(serializedChannel));
    }, [dispatch, serializedChannel]);

    return (
        <button
            type="button"
            className={classes.container(isSelected)}
            title={channelName}
            onClick={handleChannelClick}
        >
            <MemberAvatars
                className={classes.avatar.container}
                members={membersThatAreNotMe}
                showOnlineStatus={membersThatAreNotMe.length === 1}
            />
            <span className={classes.channelInfo.container}>
                <span className={classes.channelInfo.nameAndDate.container}>
                    <span className={classes.channelInfo.nameAndDate.name}>
                        {membersThatAreNotMe.length === 0 ? `(${t('messaging.no_members')})` : channelName}
                    </span>
                    <span className={classes.channelInfo.nameAndDate.date}>
                        {relativeTimeLocaleKey
                            ? t(`messaging.${relativeTimeLocaleKey}`, {
                                  formattedDate: moment(new Date(channel.lastMessage!.createdAt)).format('M/D/YY'),
                              })
                            : null}
                    </span>
                </span>
                <span className={classes.channelInfo.lastMessage.container}>
                    <span className={classes.channelInfo.lastMessage.message}>
                        {!!channel.lastMessage &&
                            (isImageMessage(channel.lastMessage)
                                ? `(${t('messaging.image')}) ${channel.lastMessage.name}`
                                : isVideoMessage(channel.lastMessage)
                                ? `(${t('messaging.video')}) ${channel.lastMessage.name}`
                                : channel.lastMessage?.isFileMessage()
                                ? `(${t('messaging.file')}) ${channel.lastMessage.name}`
                                : channel.lastMessage?.message)}
                    </span>
                    {channel.unreadMessageCount > 0 && (
                        <span className={classes.channelInfo.lastMessage.unreadCount}>
                            {channel.unreadMessageCount}
                        </span>
                    )}
                </span>
            </span>
        </button>
    );
};
