import { createSelector } from '@reduxjs/toolkit';
import { type MessagingSlice } from './messaging.types';

type State = { messaging: MessagingSlice };

export const getActiveChannelUrl = (state: State) => state.messaging.activeChannelUrl;
export const getReplyMessageId = (state: State) => state.messaging.replyMessageId;
export const getShowMessageSearch = (state: State) => state.messaging.showMessageSearch;
export const getTargetSearchMessage = (state: State) => state.messaging.targetSearchMessage;
export const getHighlightedMessageId = (state: State) => state.messaging.highlightedMessageId;
export const getOpenChat = (state: State) => state.messaging.openChat;
export const getLoading = (state: State) => state.messaging.loading;
export const getShowNewMessage = (state: State) => state.messaging.showNewMessage;
export const getPresetNewMessageRecipient = (state: State) => state.messaging.presetNewMessageRecipient;
export const getDraftUser = (state: State) => state.messaging.draftUser;
export const getShowChannelInfo = (state: State) => state.messaging.showChannelInfo;
export const getShowProfileUserId = (state: State) => state.messaging.showProfileUserId;
export const getShowChannelDetailsPanel = (state: State) =>
    state.messaging.showChannelInfo || !!state.messaging.showProfileUserId;
export const getUserChannelMap = (state: State) => state.messaging.userChannelMap;
export const getChannelsByUrl = (state: State) => state.messaging.channelsByUrl;

export const getChannelFromUserChannelMap = createSelector(
    [getUserChannelMap, (_, userIds: string[]) => userIds],
    (channelMap, userIds) => channelMap[JSON.stringify(userIds.sort())],
);
