import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { MessageMenu } from '@sendbird/uikit-react/ui/MessageMenu';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';
import { setActiveChannelUrl } from '../actions';
import { getActiveChannelUrl } from '../selectors';
import { actions } from '../messagingSlice';

const { setReplyMessageId } = actions;

type Props = {
    open: boolean;
    message: CoreMessageType;
    messageIsFromOtherUser: boolean;
    onClickButton: () => void;
};

const classes = {
    menu: {
        wrapper: (open: boolean) =>
            clsx(
                'tw-flex',
                'tw-h-[40px]',
                'tw-w-[40px]',
                'tw-items-center',
                'tw-justify-center',
                'tw-rounded-lg',
                { 'hover:tw-bg-slate-grey-lightest': !open },
                { 'tw-bg-slate-grey-lightest': open },
            ),
        icon: clsx('tw-text-[28px]', 'tw-text-slate-grey'),
    },
};

export const MessageContextMenu = ({ open, message, messageIsFromOtherUser, onClickButton, ...rest }: Props) => {
    const dispatch = useDispatch();
    const { currentChannel } = useGroupChannelContext();
    const activeChannelUrl = useSelector(getActiveChannelUrl);

    if (message.isAdminMessage()) return null;

    return (
        <MessageMenu
            channel={currentChannel}
            message={message}
            {...rest}
            renderTrigger={({ onClick, ...triggerProps }) => (
                <button
                    {...triggerProps}
                    type="button"
                    onClick={e => {
                        onClickButton();
                        onClick?.(e);
                    }}
                    className={classes.menu.wrapper(open)}
                >
                    <FontAwesomeIcon icon={faEllipsisV} className={classes.menu.icon} />
                </button>
            )}
            renderMenuItems={({ items }) => (
                <div>
                    <items.CopyMenuItem />
                    <items.ReplyMenuItem
                        onClick={() => {
                            dispatch(setReplyMessageId(message.messageId));
                            dispatch(setActiveChannelUrl(currentChannel!.url));
                        }}
                    />
                    {!messageIsFromOtherUser && activeChannelUrl && <items.DeleteMenuItem />}
                </div>
            )}
        />
    );
};
