import angularModule from 'Navigation/angularModule/scripts/navigation_module';

angularModule.factory('Navigation.ShareService', [
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const EventLogger = $injector.get('EventLogger');
        const ErrorLogService = $injector.get('ErrorLogService');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');
        const isMobile = $injector.get('isMobile');

        const sharePlugin = $window.plugins?.socialsharing;

        //-----------------------
        // Sharing Provider Meta
        //-----------------------

        const shareProviderMeta = {
            facebook: {
                serviceUrl: 'https://www.facebook.com/sharer/sharer.php',
                urlField: 'u',
                titleField: 't',
            },

            linkedin: {
                serviceUrl: 'http://www.linkedin.com/shareArticle',
                urlField: 'url',
                titleField: 'title',
                descriptionField: 'text',
                sourceField: 'source',
                additionalMeta: 'mini=true',
            },

            twitter: {
                serviceUrl: 'https://twitter.com/intent/tweet',
                sourceField: 'source',
                descriptionField: 'text',
                urlField: 'url',
            },

            email: {
                serviceUrl: 'mailto:',
                titleField: 'subject',
                descriptionField: 'body',
            },
        };

        function shareViaBrowser(shareLink) {
            // support window options based on context (native browser vs inAppBrowser plugin)
            // see also: https://github.com/apache/cordova-plugin-inappbrowser
            let windowFeatures = 'location=no';
            if (!$window.CORDOVA) {
                const height = 350;
                const width = 450;
                const leftPosition = $window.screen.width / 2 - (width / 2 + 10);
                const topPosition = $window.screen.height / 2 - (height / 2 + 50);
                windowFeatures += `,toolbar=no,status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},menubar=no,scrollbars=no,directories=no`;
            }
            NavigationHelperMixin.loadUrl(shareLink, '_blank', windowFeatures);
        }

        return {
            share(context, serviceName, shareInfo, logInfo) {
                const shareDetails = this.openShareWindow(serviceName, shareInfo);

                //---------------------------
                // Logging
                //---------------------------

                logInfo = logInfo || {};
                EventLogger.log(
                    'share',
                    angular.extend(
                        {
                            label: context,
                            service: serviceName,
                            campaign: shareDetails.campaignMedium,
                        },
                        logInfo,
                    ),
                );
            },

            shareMobileApp(shareInfo) {
                if (sharePlugin) {
                    sharePlugin.share(shareInfo.title, null, null, shareInfo.url);
                } else {
                    throw new Error('shareMobileApp() is for mobile app only');
                }

                // FIXME: https://trello.com/c/ihn45kkM/925-feat-add-logging-support-to-cordova-shareservice-calls
            },

            openShareWindow(serviceName, shareInfo) {
                const meta = shareProviderMeta[serviceName];
                const shareDetails = angular.extend({}, shareInfo, shareInfo[serviceName]);

                //---------------------------
                // Share Info Validation
                //---------------------------

                if (!meta) {
                    throw new Error(`No sharing service for '${serviceName}' found`);
                }

                // the email service via the plugin doesn't require a url or title, so we don't trigger validations for it
                if (
                    !(sharePlugin && serviceName === 'email') &&
                    (!shareDetails || !shareDetails.url || !shareDetails.title)
                ) {
                    throw new Error(
                        'In order to perform a social sharing function, a URL and title must be specified at a minimum',
                    );
                }

                //---------------------------
                // Tracking Campaign Logic
                //---------------------------

                if ((shareDetails.campaignDescription || shareDetails.campaignMedium) && shareDetails.url) {
                    shareDetails.url += `${shareDetails.url.includes('?') ? '&' : '?'}utm_source=${encodeURIComponent(
                        serviceName,
                    )}`;

                    if (shareDetails.campaignMedium) {
                        shareDetails.url += `${'&utm_medium='}${encodeURIComponent(shareDetails.campaignMedium)}`;
                    }

                    if (shareDetails.campaignDescription) {
                        shareDetails.url += `&utm_campaign=${encodeURIComponent(shareDetails.campaignDescription)}`;
                    }

                    if (shareDetails.campaignContent) {
                        shareDetails.url += `&utm_content=${encodeURIComponent(shareDetails.campaignContent)}`;
                    }
                }

                //---------------------------
                // Common URL Fields
                //---------------------------

                const shareLink = `${meta.serviceUrl}?${meta.titleField}=${encodeURIComponent(shareDetails.title)}&${
                    meta.urlField ? `${meta.urlField}=${encodeURIComponent(shareDetails.url)}&` : ''
                }${meta.sourceField ? `${meta.sourceField}=${encodeURIComponent(shareDetails.url)}&` : ''}${
                    meta.descriptionField
                        ? `${meta.descriptionField}=${encodeURIComponent(shareDetails.description)}&`
                        : ''
                }${meta.additionalMeta ? `${meta.additionalMeta}&` : ''}`;

                //---------------------------
                // Special-Case Sharing
                //---------------------------

                // SEE ALSO: https://github.com/EddyVerbruggen/SocialSharing-PhoneGap-Plugin#email
                if (serviceName === 'email' && sharePlugin) {
                    _.forEach(['to', 'cc', 'bcc', 'files'], field => {
                        shareDetails[field] = shareDetails[field] || null;
                    });

                    // use social sharing plugin for email if in hybrid mode
                    sharePlugin.shareViaEmail(
                        shareDetails.description,
                        shareDetails.title,
                        shareDetails.to,
                        shareDetails.cc,
                        shareDetails.bcc,
                        shareDetails.files,
                        shareDetails.onSuccess,
                        shareDetails.onError,
                    );
                } else if (serviceName === 'email' && isMobile()) {
                    // if mobile and email sharing, launch link directly (iOS popup is nicer)
                    $window.location = shareLink;
                } else if (['facebook', 'twitter'].includes(serviceName) && sharePlugin) {
                    // if using the plugin, try and determine if the service is installed and available, if so, try it with browser fallback
                    const deviceSpecificServiceName =
                        $window.device?.platform === 'iOS' ? `com.apple.social.${serviceName}` : serviceName;

                    sharePlugin.canShareVia(
                        deviceSpecificServiceName,
                        shareDetails.title,
                        null,
                        shareDetails.url,
                        null,
                        function canShareViaSuccess() {
                            // detection success - attempt native app share

                            function shareMethodSuccess(type) {
                                // cancellations are expected
                                if (type !== 'cancelled') {
                                    ErrorLogService.notify(`Plugin sharing via ${serviceName} failed`, undefined, {
                                        code: type,
                                    });
                                }
                            }

                            function shareMethodFailure(err) {
                                // failures after detection are not expected
                                ErrorLogService.notify(
                                    `Plugin sharing via ${serviceName} failed despite passing canShareVia checks. Defaulting to URL sharing ...`,
                                    err,
                                );
                                shareViaBrowser(shareLink);
                            }

                            const shareArgs = [
                                shareDetails.title,
                                null,
                                shareDetails.url,
                                null,
                                shareMethodSuccess,
                                shareMethodFailure,
                            ];

                            if (serviceName === 'facebook') {
                                sharePlugin.shareViaFacebook(...shareArgs);
                            } else {
                                sharePlugin.shareViaTwitter(...shareArgs);
                            }
                        },
                        function canShareViaFailure() {
                            // detection failure - browser share fallback
                            shareViaBrowser(shareLink);
                        },
                    );
                } else {
                    shareViaBrowser(shareLink);
                }

                return shareDetails;
            },
        };
    },
]);
