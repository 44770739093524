
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_header = window.Smartly.locales.modules.en.back_royal.navigation.app_header || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_header = {...window.Smartly.locales.modules.en.back_royal.navigation.app_header, ...{
    "back": "BACK",
    "chapter_number_x": "Chapter {{number}} •",
    "close": "CLOSE",
    "exit": "EXIT",
    "last_saved_by_x_at_x": "Last saved by {{editor}} at {{updatedAt}} (not pinned)",
    "logged_in_as_x": "Logged in as <strong>{{email}}.</strong> Hard refresh (Cmd+R) to return to your user.",
    "mba": "SHOW MBA",
    "playlists": "SHOW PLAYLISTS",
    "save_and_exit": "Save and Exit",
    "saved_by_x_at_x": "Saved by {{editor}} at {{updatedAt}}",
    "menu": "MENU <i class=\"fa fa-bars\"></i>"
}
}
    