import { type ReactNode } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = () => document.getElementById('sendbird-modal-root') as HTMLDivElement;

export const MessagingModal = ({ children }: { children: ReactNode }) => {
    const element = (
        <div className="file-viewer-container tw-absolute tw-left-0 tw-top-0 tw-z-[10000] tw-h-full tw-w-full tw-rounded-[8px] tw-bg-white">
            {children}
        </div>
    );

    return createPortal(element, modalRoot());
};
