import moment from 'moment';

// Determine the locale key in the `messaging.messaging.[...]` namespace for the relative time of a message.
// The resulting locale strings are 'Today', 'Yesterday', 'Monday', 'Tuesday', etc., and '9/3/24'.
export const getRelativeTimeLocaleKey = (dateToCompare: Date) => {
    const now = moment(new Date());
    const date = moment(dateToCompare);
    const isToday = now.isSame(date, 'day');
    const isYesterday = now.isSame(date.clone().add(1, 'day'), 'day');
    const isWithinLastWeek = now.diff(date, 'days') < 7;

    if (isToday) return 'today';

    if (isYesterday) return 'yesterday';

    // If the date is within the last week, return the day of the week, e.g. "Monday"
    if (isWithinLastWeek) return date.format('dddd');

    // Otherwise, use the formatted date
    return 'formattedDate';
};
