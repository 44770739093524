import wrapAngularHttpTimeout from 'wrapAngularHttpTimeout';
import NetworkConnection from './NetworkConnection';

/*
    At least in the cordova emulator, when we lose a network connection,
    an in flight request might hang forever.  We want to proactively cancel
    that request so that the UI can respond.  See https://trello.com/c/9JmOKFxH for
    a more detailed description of the issue.

    Implementation:

    When we intercept an outgoing request, we start listening for the
    `offline` event.  If we observe it, we wait 5 seconds.  At that point,
    if we are still offline, then we cancel the request.

*/
export default function cancelRequestOnLostConnectionInterceptor($injector) {
    const $q = $injector.get('$q');
    const $timeout = $injector.get('$timeout');

    return {
        request: config => {
            let cancelRequestIfStillOfflineInNSeconds;
            let waitNSecondsTimeout;
            const promise = $q(_cancelRequest => {
                cancelRequestIfStillOfflineInNSeconds = () => {
                    waitNSecondsTimeout = $timeout(5000);

                    waitNSecondsTimeout.then(() => {
                        if (NetworkConnection.offline) {
                            _cancelRequest();
                        }
                    });
                    window.removeEventListener('offline', cancelRequestIfStillOfflineInNSeconds);
                };
            });

            window.addEventListener('offline', cancelRequestIfStillOfflineInNSeconds);
            config.stopListeningForCancelRequestOnLostConnection = () => {
                window.removeEventListener('offline', cancelRequestIfStillOfflineInNSeconds);
                $timeout.cancel(waitNSecondsTimeout);
            };

            // Since there might already be a `timeout` value in the config,
            // we need wrapAngularHttpTimeout
            wrapAngularHttpTimeout(config, promise, $injector);

            return config;
        },

        response: response => {
            if (response?.config?.stopListeningForCancelRequestOnLostConnection) {
                response.config.stopListeningForCancelRequestOnLostConnection();
            }
            return response;
        },

        responseError: response => {
            if (response?.config?.stopListeningForCancelRequestOnLostConnection) {
                response.config.stopListeningForCancelRequestOnLostConnection();
            }
            throw response;
        },
    };
}
