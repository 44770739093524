import PlaceFormatter from './PlaceFormatter';

const { locationString, formattedAddress, placeCity, placeState, placeCountry, placeCountryLong } = PlaceFormatter;

export {
    // export the whole object for anyone who wants it
    PlaceFormatter,
    // export individual ones when that is all we need
    locationString,
    formattedAddress,
    placeCity,
    placeState,
    placeCountry,
    placeCountryLong,
};
