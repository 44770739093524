import { type RenderCustomSeparatorProps } from '@sendbird/uikit-react/types/types';
import clsx from 'clsx';
import { formattedUserFacingMonthDayYearLong } from 'DateHelpers';

const classes = {
    container: clsx(
        'tw-relative',
        'tw-left-[-24px]',
        'tw-flex',
        'tw-w-[calc(100%+48px)]',
        'tw-items-center',
        'tw-py-2',
    ),
    line: clsx('tw-inline-block', 'tw-h-[1px]', 'tw-w-full', 'tw-border-none', 'tw-bg-slate-grey-mid-light'),
    dateWrapper: clsx('tw-mx-[6.5px]', 'tw-my-0', 'tw-flex', 'tw-whitespace-nowrap'),
    date: clsx('tw-text-xxs', 'tw-font-semibold', 'tw-text-slate-grey'),
};

export const DateSeparator = ({ message }: RenderCustomSeparatorProps) => (
    <div className={classes.container}>
        <div className={classes.line} />
        <div className={classes.dateWrapper}>
            <span className={classes.date}>{formattedUserFacingMonthDayYearLong(new Date(message.createdAt))}</span>
        </div>
        <div className={classes.line} />
    </div>
);
