
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_menu_user = window.Smartly.locales.modules.en.back_royal.navigation.app_menu_user || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_menu_user = {...window.Smartly.locales.modules.en.back_royal.navigation.app_menu_user, ...{
    "account": "Account",
    "profile": "Profile",
    "preferences": "Preferences",
    "give_feedback": "Give Feedback",
    "sign_out": "Sign Out",
    "why_us": "Why {{brandName}}",
    "mba_curriculum": "MBA Curriculum",
    "for_companies": "For Companies",
    "method": "Method",
    "login": "Login",
    "billing": "Billing",
    "program": "Program",
    "settings": "Settings"
}
}
    