/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type User } from '@sendbird/chat';
import clsx from 'clsx';
import { ImageWithFallback } from 'ImageWithFallback';
import { type KeyboardEvent, type FC, useState, useRef, useCallback } from 'react';
import { useClickAway } from 'react-use';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';

const pillClasses = {
    container: (selected: boolean) =>
        clsx(
            'tw-flex',
            'tw-my-[5px]',
            'tw-me-2',
            'tw-min-h-[27px]',
            'tw-rounded-[12.5px]',
            'tw-pr-[10px]',
            'tw-border',
            'tw-transition-colors',
            'tw-border-transparent',
            'hover:tw-border-slate-grey',
            'focus:tw-outline-none',
            selected ? 'tw-bg-slate-grey tw-border-slate-grey' : 'tw-bg-slate-grey-extra-light',
        ),
    avatar: clsx(
        'tw-h-[25px]',
        'tw-w-[25px]',
        'tw-me-[5px]',
        'tw-self-center',
        'tw-rounded-full',
        'tw-pointer-events-none',
    ),
    name: (selected: boolean) =>
        clsx(
            'tw-font-semibold',
            'tw-text-[16px]',
            'tw-pointer-events-none',
            'tw-break-anywhere',
            selected ? 'tw-text-white' : 'tw-text-black',
        ),
    icon: (selected: boolean) =>
        clsx(
            'tw-text-[16px]',
            selected ? 'tw-text-slate-grey-mid' : 'tw-text-slate-grey',
            'tw-ms-[5px]',
            'tw-hidden',
            'sm:tw-block',
            'tw-flex-shrink-0',
        ),
};

export const SelectedUserPill: FC<{ user: User; handleListItemClick: (user: User) => void }> = ({
    handleListItemClick,
    user,
}) => {
    const [selected, setSelected] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useClickAway(ref, () => {
        if (selected) {
            setSelected(false);
        }
    });

    const onClick = useCallback(() => {
        setSelected(v => !v);
        ref.current?.focus();
    }, []);

    const onKeyDown = useCallback(
        (e: KeyboardEvent, u: User) => {
            if (selected && (e.key === 'Delete' || e.key === 'Backspace')) {
                handleListItemClick(u);
            }
        },
        [handleListItemClick, selected],
    );

    return (
        <div
            ref={ref}
            tabIndex={0}
            className={pillClasses.container(selected)}
            onClick={onClick}
            onKeyDown={e => onKeyDown(e, user)}
        >
            <ImageWithFallback
                className={pillClasses.avatar}
                src={user.profileUrl}
                alt={user.nickname}
                fallbackSrc={defaultAvatarSrc}
            />
            <div className={pillClasses.name(selected)}>{user.nickname}</div>
            <button
                type="button"
                className={pillClasses.icon(selected)}
                onClick={e => {
                    e.stopPropagation();
                    handleListItemClick(user);
                }}
            >
                <FontAwesomeIcon icon={faXmark} className="tw-pointer-events-none" />
            </button>
        </div>
    );
};
