
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_menu = window.Smartly.locales.modules.en.back_royal.navigation.app_menu || {};
window.Smartly.locales.modules.en.back_royal.navigation.app_menu = {...window.Smartly.locales.modules.en.back_royal.navigation.app_menu, ...{
    "admin_tools": "Admin",
    "choose_a_group": "Choose a group",
    "clear_progress": "Clear Progress",
    "clear_progress_confirm": "Are you sure you want to clear all your lesson progress?",
    "clear_progress_success": "Successfully cleared existing lesson progress",
    "dashboard": "Dashboard",
    "edit_content": "Edit Content",
    "frame_events_report": "Frame Events Report",
    "give_feedback": "Give Feedback",
    "grade_lessons": "Grade Lessons",
    "home": "Home",
    "courses": "Courses",
    "careers": "Careers",
    "student_network": "Network",
    "playlists": "Playlists",
    "login": "Login",
    "manage_global_metadata": "Manage Metadata",
    "manage_cohorts": "Manage Cohorts",
    "manage_groups": "Manage Groups",
    "manage_institutions": "Manage Institutions",
    "manage_users": "Manage Users",
    "batch_edit_users": "Edit Multiple Users",
    "reports": "Reports",
    "pricing": "Pricing",
    "settings": "Settings",
    "sign_up": "Sign Up",
    "source": "Source",
    "inbox": "Inbox",
    "manage_careers": "Manage Careers",
    "mba": "Manage MBA and Institutions",
    "post": "Post",
    "resources": "Resources",
    "ai": "AI",
    "ai_bot": "AI Bot",
    "beta": "beta",
    "nominations": "Nominations",
    "ai_advisor": "AI Advisor",
    "application": "Application",
    "apply": "Apply"
}
}
    