
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.messaging = window.Smartly.locales.modules.en.back_royal.messaging || {};
window.Smartly.locales.modules.en.back_royal.messaging.messaging = window.Smartly.locales.modules.en.back_royal.messaging.messaging || {};
window.Smartly.locales.modules.en.back_royal.messaging.messaging = {...window.Smartly.locales.modules.en.back_royal.messaging.messaging, ...{
    "messages": "Messages",
    "in_chat": "In chat",
    "search_all_chats": "Search All Chats",
    "search_this_chat": "Search This Chat",
    "this_chat": "This Chat",
    "all_chats": "All Chats",
    "search": "Search",
    "search_placeholder": "Search...",
    "reset": "Reset",
    "more": "More",
    "from": "From",
    "to": "To",
    "no_results": "No results",
    "no_members": "No Members",
    "load_more": "Load More",
    "order": "Order",
    "most_relevant": "Most Relevant",
    "oldest_to_newest": "Oldest to Newest",
    "newest_to_oldest": "Newest to Oldest",
    "chats": "Chats",
    "image": "Image",
    "video": "Video",
    "file": "File",
    "today": "Today",
    "yesterday": "Yesterday",
    "Sunday": "Sunday",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "formattedDate": "{formattedDate}",
    "profile": "Profile",
    "online": "Online",
    "offline": "Offline",
    "local_time": "local time",
    "message": "Message",
    "view_in_network": "View in Network",
    "programs": "Programs",
    "additional_actions": "Additional Actions",
    "archive_chat": "Archive Chat",
    "block_user": "Block User",
    "unblock_user": "Unblock User",
    "newMessage": "New Message",
    "searchClassmates": "Search classmates and recent chat partners...",
    "suggested": "Suggested",
    "thisConversation1": "This conversation is just between ",
    "thisConversation2": " and you. Check out their profile to learn more about them.",
    "beginningOfHistory": "This is the beginning of your message history with ",
    "period": ".",
    "and": " and ",
    "comma": " , ",
    "viewProfile": "View Profile",
    "toInput": "To:",
    "admin": "Admin",
    "you": "You",
    "repliedTo": "{current} replied to {parent}",
    "replyTo": "Reply to message",
    "replyToAdmin": "Reply to Admin",
    "replyToName": "Reply to {name}",
    "messageTo": "Message {name}",
    "messageGroup": "Message group",
    "startNewMessage": "Start a new message",
    "files": "files",
    "chat_information": "Chat Information",
    "and_n_others": "and {count} others",
    "members": "Members",
    "leave_chat": "Leave Chat",
    "location_not_set": "Location not set",
    "profile_not_available": "Profile not available"
}
}
    