import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    type BaseMessage,
    MessageSearchOrder,
    type MessageSearchQuery,
    type MessageSearchQueryParams,
} from '@sendbird/chat/message';
import { useForm, yupResolver } from 'FrontRoyalReactHookForm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdSelectors } from '@sendbird/uikit-react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { getActiveChannelUrl } from '../selectors';
import { setShowMessageSearch } from '../actions';
import { MessageSearchResultsList } from './MessageSearchResultsList';
import { Select } from './Select';

const classes = {
    container: clsx(
        'tw-h-full',
        'tw-w-full',
        'tw-bg-white',
        'tw-border-s',
        'tw-border-solid',
        'tw-border-slate-grey-mid-light',
        'tw-ml-[-1px]',
    ),
    header: {
        container: clsx(
            'tw-w-full',
            'tw-h-[64px]',
            'tw-ps-5',
            'tw-pe-3',
            'tw-flex',
            'tw-items-center',
            'tw-justify-between',
            'tw-border-b',
            'tw-border-solid',
            'tw-border-slate-grey-mid-light',
        ),
        title: clsx('tw-text-lg', 'tw-font-semibold', 'tw-text-black'),
        closeButton: clsx(
            'tw-h-[40px]',
            'tw-w-[40px]',
            'tw-flex',
            'tw-rounded-lg',
            'tw-items-center',
            'tw-justify-center',
            'tw-text-black',
            'tw-text-lg',
            'hover:tw-bg-slate-grey-lightest',
        ),
    },
    main: {
        container: clsx(
            'tw-w-full',
            'tw-h-[calc(100%-64px)]',
            'tw-max-h-full',
            'tw-flex',
            'tw-flex-col',
            'tw-justify-start',
            'tw-items-start',
            'tw-overflow-hidden',
        ),
        form: {
            wrapper: clsx(
                'tw-w-full',
                'tw-min-h-[50px]',
                'tw-grow-0',
                'tw-shrink-0',

                'tw-border-b',
                'tw-border-solid',
                'tw-border-slate-grey-mid-light',
                'tw-px-[22px]',
                'tw-py-[10px]',
                'tw-bg-white',
            ),
            advancedFieldsWrapper: (showingAdvancedFields: boolean) =>
                clsx('tw-flex', 'tw-items-end', 'tw-justify-start', 'tw-flex-wrap', {
                    'tw-hidden': !showingAdvancedFields,
                }),
            mainFieldWrapper: clsx('tw-w-full', 'tw-flex', 'tw-justify-start', 'tw-items-start', 'tw-flex-wrap'),
            mainField: clsx('tw-grow', 'tw-me-4', 'tw-mb-[10px]'),
            input: clsx('tw-p-2', 'tw-border', 'tw-border-solid', 'tw-rounded-md', 'tw-border-slate-grey-mid-light'),
            mainFieldInput: clsx(
                'tw-w-full',
                'tw-min-w-[200px]',
                'tw-p-2',
                'tw-border',
                'tw-border-solid',
                'tw-rounded-md',
                'tw-border-slate-grey-mid-light',
            ),
            advancedToggle: clsx(
                'tw-grow-0',
                'tw-cursor-pointer',
                'tw-flex',
                'tw-mb-[10px]',
                'tw-items-center',
                'tw-justify-between',
                'tw-self-center',
            ),
            advancedToggleIcon: (showingAdvancedFields: boolean) =>
                clsx('tw-text-black', 'tw-text-[14px]', 'tw-ms-[8px]', 'tw-transition-all', {
                    'tw-rotate-180': showingAdvancedFields,
                }),
            fieldItem: clsx('tw-me-[15px]', 'tw-flex', 'tw-items-center', 'tw-justify-start', 'tw-mb-[10px]'),
            fieldItemLabel: clsx('tw-text-slate-grey', 'tw-font-semibold', 'tw-text-[16px]', 'tw-me-[10px]', 'tw-mt-2'),
            formActionsWrapper: clsx('tw-flex', 'tw-justify-start', 'tw-items-center', 'tw-ms-auto'),
            submitButton: clsx(
                'tw-h-[40px]',
                'tw-rounded-lg',
                'tw-bg-slate-grey-extra-light',
                'tw-text-black',
                'tw-font-semibold',
                'tw-text-[16px]',
                'tw-px-[20px]',
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'hover:tw-bg-slate-grey-mid-light',
                'tw-cursor-pointer',
                'disabled:tw-opacity-40',
                'disabled:tw-cursor-not-allowed',
                'tw-me-[10px]',
            ),
            resetButton: clsx(
                'tw-h-[40px]',
                'tw-rounded-lg',
                'tw-bg-slate-grey-extra-light',
                'tw-text-black',
                'tw-font-semibold',
                'tw-text-[16px]',
                'tw-px-[20px]',
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'tw-cursor-pointer',
                'hover:tw-bg-slate-grey-mid-light',
                'disabled:tw-opacity-40',
                'disabled:tw-cursor-not-allowed',
            ),
        },
        results: {
            container: clsx('tw-w-full', 'tw-h-full', 'tw-p-5', 'tw-grow', 'tw-shrink', 'tw-overflow-auto'),
            noResults: clsx('tw-text-[16px]', 'tw-text-slate-grey', 'tw-font-normal'),
            messagesList: clsx('tw-w-full', 'tw-mb-5'),
            loadMoreButton: clsx(
                'tw-h-[40px]',
                'tw-rounded-lg',
                'tw-bg-slate-grey-extra-light',
                'tw-text-black',
                'tw-font-semibold',
                'tw-text-[16px]',
                'tw-px-[20px]',
                'tw-flex',
                'tw-justify-center',
                'tw-items-center',
                'hover:tw-bg-slate-grey-mid-light',
            ),
        },
    },
};

const formSchema = Yup.object().shape({
    keyword: Yup.string().required(),
    messageTimestampFrom: Yup.date().nullable(),
    messageTimestampTo: Yup.date().nullable(),
    ordering: Yup.string().oneOf(['score', 'time asc', 'time desc']).required(),
    searchChannels: Yup.string().oneOf(['all', 'active']).required(),
});

export const MessageSearch = () => {
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const dispatch = useDispatch();
    const [query, setQuery] = useState<MessageSearchQuery | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resultMessages, setResultMessages] = useState<BaseMessage[]>([]);
    const [showingAdvancedFields, setShowingAdvancedFields] = useState(false);
    const [showingChannelName, setShowingChannelName] = useState(false);
    const activeChannelUrl = useSelector(getActiveChannelUrl);

    const uniqueResultMessages = useMemo(
        () => Object.values<BaseMessage>(resultMessages.reduce((obj, m) => ({ ...obj, [m.messageId]: m }), {})),
        [resultMessages],
    );

    const sbContext = useSendbirdStateContext();
    const sdk = sendBirdSelectors.getSdk(sbContext);

    const { getValues, reset, handleSubmit, formState, register, watch } = useForm({
        defaultValues: {
            keyword: '',
            messageTimestampFrom: null,
            messageTimestampTo: null,
            ordering: 'score',
            searchChannels: 'active',
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(formSchema),
    });

    const valid = !!watch('keyword')?.trim();
    const dirty = formState.isDirty;
    const searchChannels = watch('searchChannels');

    const submitHandler = useCallback(() => {
        if (!valid) return;

        setQuery(null);
        setResultMessages([]);

        const values = getValues();

        const buildQuery = () => {
            const baseParams: MessageSearchQueryParams = {
                // Remove special characters from the keyword; the search API does not support them
                keyword: values.keyword.replace(/[^\s\w]/g, ''),
                messageTimestampFrom: values.messageTimestampFrom ? new Date(values.messageTimestampFrom).getTime() : 0,
                messageTimestampTo: values.messageTimestampTo ? new Date(values.messageTimestampTo).getTime() : 0,
                order: values.ordering === 'score' ? MessageSearchOrder.SCORE : MessageSearchOrder.TIMESTAMP,
                reverse: values.ordering === 'time asc',
            };
            if (searchChannels === 'active') {
                baseParams.channelUrl = activeChannelUrl;
            }
            setQuery(sdk.createMessageSearchQuery(baseParams));
        };
        buildQuery();
        setShowingChannelName(values.searchChannels === 'all');
    }, [activeChannelUrl, getValues, sdk, searchChannels, valid]);

    const handleCloseClick = useCallback(() => {
        dispatch(setShowMessageSearch(false));
    }, [dispatch]);

    const handleResetClick = useCallback(() => {
        reset();
        setQuery(null);
        setResultMessages([]);
    }, [reset]);

    const handleLoadMoreClick = useCallback(async () => {
        if (!query) return;

        const queryMessages = (await query?.next()) ?? [];

        setResultMessages(curr => [...curr, ...queryMessages]);
    }, [query]);

    useEffect(() => {
        if (!query) return;
        if (resultMessages.length !== 0) return;

        const fetchMessages = () => {
            if (query && query.hasNext && !query.isLoading) {
                return query.next().then(messages => messages || []);
            }
            return Promise.resolve([]) as Promise<BaseMessage[]>;
        };

        setIsLoading(true);

        fetchMessages().then(messages => {
            setResultMessages(messages);
            setIsLoading(false);
        });
    }, [query, resultMessages.length]);

    const hasNext = query?.hasNext;
    const noResults = !isLoading && !!query && query.totalCount === 0;

    return (
        <div className={classes.container}>
            <div className={classes.header.container}>
                <h3 className={classes.header.title}>
                    {t(`messaging.${searchChannels === 'all' ? 'search_all_chats' : 'search_this_chat'}`)}
                </h3>
                <button type="button" className={classes.header.closeButton} onClick={handleCloseClick}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>
            <div className={classes.main.container}>
                <div className={classes.main.form.wrapper}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <form onSubmit={handleSubmit(submitHandler)}>
                            <div className={classes.main.form.mainFieldWrapper}>
                                <div className={classes.main.form.mainField}>
                                    <input
                                        {...register('keyword')}
                                        placeholder={`${t('messaging.search_placeholder')}`}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                submitHandler();
                                            }
                                        }}
                                        className={classes.main.form.mainFieldInput}
                                    />
                                </div>
                                <div className={classes.main.form.mainField}>
                                    <Select {...register('searchChannels')}>
                                        <option value="active">{t('messaging.this_chat')}</option>
                                        <option value="all">{t('messaging.all_chats')}</option>
                                    </Select>
                                </div>
                                <button
                                    type="button"
                                    className={classes.main.form.advancedToggle}
                                    onClick={() => setShowingAdvancedFields(!showingAdvancedFields)}
                                >
                                    <span>{t('messaging.more')}</span>
                                    <FontAwesomeIcon
                                        icon={faChevronDown}
                                        className={classes.main.form.advancedToggleIcon(showingAdvancedFields)}
                                    />
                                </button>
                            </div>
                            <div className={classes.main.form.advancedFieldsWrapper(showingAdvancedFields)}>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="messageTimestampFrom">
                                        {t('messaging.from')}
                                    </label>
                                    <input
                                        {...register('messageTimestampFrom')}
                                        type="datetime-local"
                                        className={classes.main.form.input}
                                    />
                                </div>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="messageTimestampTo">
                                        {t('messaging.to')}
                                    </label>
                                    <input
                                        {...register('messageTimestampTo')}
                                        type="datetime-local"
                                        className={classes.main.form.input}
                                    />
                                </div>
                                <div className={classes.main.form.fieldItem}>
                                    <label className={classes.main.form.fieldItemLabel} htmlFor="ordering">
                                        {t('messaging.order')}
                                    </label>
                                    <Select {...register('ordering')}>
                                        <option value="score">{t('messaging.most_relevant')}</option>
                                        <option value="time asc">{t('messaging.oldest_to_newest')}</option>
                                        <option value="time desc">{t('messaging.newest_to_oldest')}</option>
                                    </Select>
                                </div>
                            </div>
                            <div className={classes.main.form.formActionsWrapper}>
                                <button
                                    className={classes.main.form.submitButton}
                                    type="submit"
                                    disabled={isLoading || !valid}
                                    onClick={submitHandler}
                                >
                                    {t('messaging.search')}
                                </button>
                                <button
                                    className={classes.main.form.resetButton}
                                    type="submit"
                                    onClick={handleResetClick}
                                    disabled={!dirty && !uniqueResultMessages.length}
                                >
                                    {t('messaging.reset')}
                                </button>
                            </div>
                        </form>
                    </LocalizationProvider>
                </div>
                <div className={classes.main.results.container}>
                    {!isLoading && noResults && (
                        <p className={classes.main.results.noResults}>{t('messaging.no_results')}</p>
                    )}
                    {!!uniqueResultMessages.length && (
                        <MessageSearchResultsList
                            messages={uniqueResultMessages}
                            showChannelName={showingChannelName}
                        />
                    )}
                    {isLoading && <FrontRoyalSpinner color="force-brand" className="no-delay" />}

                    {hasNext && !isLoading && uniqueResultMessages.length > 0 && (
                        <button
                            type="button"
                            className={classes.main.results.loadMoreButton}
                            onClick={handleLoadMoreClick}
                        >
                            {t('messaging.load_more')}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
