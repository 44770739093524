// window.navigator.onLine is not reliable in cordova
function checkCordovaConnection() {
    // This stuff causes errors in js specs, since there is no
    // window.navigator.connection.  Didn't want to have to mock it
    // in different places, and we never need it to run in specs,
    // so just turning it off.
    if (window.RUNNING_IN_TEST_MODE) {
        return true;
    }

    if (window.navigator.connection.type !== window.Connection.NONE) {
        return true;
    }

    return false;
}

const NetworkConnection = {
    get online() {
        if (window.CORDOVA) {
            return checkCordovaConnection();
        }

        return window.navigator.onLine;
    },

    get offline() {
        return !this.online;
    },
};

export default NetworkConnection;
