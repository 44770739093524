import canNavigateToCurrentRouteInOfflineMode from './canNavigateToCurrentRouteInOfflineMode';

export default async function resolveRouteInOfflineMode(injector) {
    const $rootScope = injector.get('$rootScope');
    const canNavigateToRoute = await canNavigateToCurrentRouteInOfflineMode(injector);

    if (canNavigateToRoute) {
        return undefined;
    }

    return $rootScope.redirectHomeWhileRouting();
}
