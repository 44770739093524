/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type User, UserOnlineState } from '@sendbird/chat';
import { locationString } from 'Location';
import { type FC } from 'react';
import clsx from 'clsx';
import { type AnyObject } from '@Types';
import { type CareerProfile } from 'CareerProfiles';
import { MemberAvatars } from './MemberAvatars';

const classes = {
    listContainer: clsx('tw-h-[calc(100%-36px)]', 'tw-overflow-y-auto'),
    listItem: clsx(
        'tw-flex',
        'tw-min-h-[30px]',
        'tw-w-full',
        'tw-items-center',
        'tw-mb-[15px]',
        'tw-px-[10px]',
        'tw-py-[5px]',
        'tw-cursor-pointer',
        'tw-transition-colors',
        'hover:tw-bg-slate-grey-extra-light/60',
        'hover:tw-rounded-lg',
        'focus:tw-bg-slate-grey-extra-light/60',
        'focus:tw-rounded-lg',
        'focus:tw-outline-none',
    ),
    avatar: clsx('tw-h-[30px]', 'tw-w-[30px]', 'tw-me-2', 'tw-shrink-0'),
    nickname: clsx('tw-font-semibold', 'tw-text-[16px]', 'tw-text-black', 'tw-me-2'),
    status: (online = true) =>
        clsx(
            'tw-rounded-full',
            online ? 'tw-bg-[#21A170]' : 'tw-bg-white tw-border-[1.5px] tw-border-slate-grey',
            'tw-h-[10px]',
            'tw-w-[10px]',
            'tw-me-2',
        ),
    location: clsx('tw-flex', 'tw-text-[16px]', 'tw-text-slate-grey'),
    checkboxContainer: clsx('tw-flex', 'tw-grow', 'tw-justify-end'),
    checkbox: (checked = false) =>
        clsx(
            'tw-w-[20px]',
            'tw-h-[20px]',
            'tw-flex',
            'tw-justify-center',
            'tw-items-center',
            'tw-border',
            'tw-border-black',
            checked ? 'tw-bg-black' : 'tw-bg-white',
        ),
    checkIcon: clsx('tw-text-[16px]', 'tw-text-white'),
};

export const SelectUsersList: FC<{
    users: User[];
    handleListItemClick: (user: User) => void;
    checked: (user: User) => boolean;
    userProfilesById: AnyObject<CareerProfile>;
}> = ({ users, handleListItemClick, checked, userProfilesById }) =>
    users.length ? (
        <ul className={classes.listContainer}>
            {users.map(u => {
                const userProfile = userProfilesById[u.userId];
                return (
                    <li
                        key={u.userId}
                        className={classes.listItem}
                        onClick={() => handleListItemClick(u)}
                        tabIndex={0}
                        onKeyDown={e => {
                            if (e.key !== 'Enter') return;
                            e.preventDefault();
                            handleListItemClick(u);
                        }}
                    >
                        <MemberAvatars members={[u]} className={classes.avatar} showOnlineStatus={false} />
                        <div className={classes.nickname}>{u.nickname}</div>
                        <div className={classes.status(u.connectionStatus === UserOnlineState.ONLINE)} />
                        {userProfile && (
                            <div className={classes.location}>{locationString(userProfile.placeDetails)}</div>
                        )}
                        <div className={classes.checkboxContainer}>
                            {checked(u) ? (
                                <div className={classes.checkbox(true)}>
                                    <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
                                </div>
                            ) : (
                                <div className={classes.checkbox()} />
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    ) : null;
