import 'ExtensionMethods/string';
import { generateGuid } from 'guid';

export default angular.module('noApply', []);

'change click dblclick mousedown mouseup mouseover mouseout mousemove mouseenter mouseleave keydown keyup keypress submit focus blur copy cut paste touchstart touchend'
    .split(' ')
    .forEach(eventName => {
        const directiveName = `noApply${eventName.camelize(true)}`;

        angular.module('noApply').directive(directiveName, [
            '$injector',
            '$parse',
            '$window',
            ($injector, $parse) => ({
                restrict: 'A',
                compile($element, attr) {
                    const id = generateGuid();
                    $element.attr('id', id);

                    // We expose the powerful $event object on the scope that provides access to the Window,
                    // etc. that isn't protected by the fast paths in $parse.  We explicitly request better
                    // checks at the cost of speed since event handler expressions are not executed as
                    // frequently as regular change detection.
                    const fn = $parse(attr[directiveName], /* interceptorFn */ null, /* expensiveChecks */ true);
                    return function noApplyEventHandler(scope, element) {
                        element.on(eventName, event => {
                            fn(scope, {
                                $event: event,
                            });
                        });
                    };
                },
            }),
        ]);
    });
