import clsx from 'clsx';
import { type ButtonHTMLAttributes, type ReactNode } from 'react';
import { twMerge } from 'Utils/customTwMerge';

const classes = {
    button: (variant: 'danger' | 'standard') =>
        clsx(
            'tw-border',
            'tw-border-solid',

            'tw-rounded-[4px]',
            'tw-w-full',
            'tw-h-[40px]',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
            'tw-uppercase',
            'hover:tw-bg-slate-grey-lightest',
            'tw-cursor-pointer',
            'disabled:tw-cursor-not-allowed',
            {
                [clsx('tw-text-slate-grey', 'tw-border-slate-grey-mid-light')]: variant === 'standard',
                [clsx(
                    'tw-text-coral',
                    'tw-border-coral',
                    'tw-border-opacity-25',
                    'hover:tw-bg-coral',
                    'hover:tw-bg-opacity-15',
                )]: variant === 'danger',
            },
            'tw-font-semibold',
            'tw-mb-[10px]',
            'tw-transition-all',
        ),
};

export const DetailsPanelActionButton = ({
    children,
    className,
    variant = 'standard',
    ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & {
    children: ReactNode;
    className?: string;
    variant?: 'danger' | 'standard';
}) => (
    <button type="button" {...rest} className={twMerge(classes.button(variant), className)}>
        {children}
    </button>
);
