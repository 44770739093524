import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import RPC from 'FrontRoyalFrameRpc';
import Cookies from 'js-cookie';

// Provides support for custom route resolvers to respond to navigation flows as necessary
angularModule
    .constant('Navigation.RouteResolvers.AVAILABLE_RESOLVERS', [
        'profileConfirmationRequired',
        'redirectToOnboardingRegisterIfMiyaMiya',
        'redirectToHomeIfAuthorized',
        'redirectToHome',
        'redirectToRootDefault',
        'loadJoinAndRedirect',
        'unrestrictedAccess',
        'hasLearnerAccess',
        'hasEditorAccess',
        'hasSuperEditorAccess',
        'hasAdminAccess',
        'hasReportsAccess',
        'hasSuperEditorOrInterviewerAccess',
        'hasStudentNetworkEventsAccessForEventId',
        'scormAccess',
        'demoAccess',
        'handle404',
        'hasCordovaNoNetwork',
        'hasBotPageAccess',
        'hasNominationsAccess',
    ])

    .factory('Navigation.RouteResolvers', [
        '$injector',
        $injector => {
            const $rootScope = $injector.get('$rootScope');
            const $q = $injector.get('$q');
            const blockAuthenticatedAccess = $injector.get('blockAuthenticatedAccess');
            const $location = $injector.get('$location');
            const $window = $injector.get('$window');
            const RouteAssetLoader = $injector.get('Navigation.RouteAssetLoader');
            const ConfigFactory = $injector.get('ConfigFactory');
            const ValidationResponder = $injector.get('ValidationResponder');
            const LearnerContentCache = $injector.get('LearnerContentCache');
            const offlineModeManager = $injector.get('offlineModeManager');

            function waitForNetworkBootstrap() {
                return $q(resolve => {
                    const cancel = $rootScope.$watch('networkBootstrapCompleted', networkBootstrapCompleted => {
                        if (networkBootstrapCompleted) {
                            resolve();
                            cancel();
                        }
                    });
                });
            }

            function redirectWithTarget(path) {
                return {
                    redirect: path,
                    search: {
                        target: $location.url(),
                    },
                };
            }

            function waitForAuthCallComplete() {
                let chain = $q.when();

                // We have to wait for the network bootstrap before
                // we can try to load config
                chain = chain.then(waitForNetworkBootstrap);

                // enter offline mode if there is no network connection
                // and redirect if necessary
                chain = chain.then(() => offlineModeManager.resolveRoute());

                // So that we can use getSync inside of most of our directives,
                // we make sure that the config is loaded before rendering the
                // main view
                chain = chain.then(ConfigFactory.getConfig.bind(ConfigFactory));
                chain = chain.then(ValidationResponder.waitForAuthCallComplete.bind(ValidationResponder));
                return chain;
            }

            function getSignInRedirect() {
                const path = $rootScope.postLogoutSignInPath;
                return redirectWithTarget(path);
            }

            function hasAppropriateAccess(type) {
                const propertyName = `has${type.charAt(0).toUpperCase()}${type.slice(1)}Access`;

                return loadAccountAndRejectIfInvalid($injector).then(currentUser =>
                    $q((resolve, reject) => {
                        if (!currentUser || !currentUser[propertyName]) {
                            reject(getSignInRedirect());
                        } else {
                            const stopWatching = $rootScope.$watch(() => {
                                const hasAccess = currentUser[propertyName];
                                if (!hasAccess) {
                                    $rootScope.goHome();
                                }
                            });
                            $rootScope.$on('$routeChangeStart', stopWatching);
                            resolve();
                        }
                    }),
                );
            }

            function loadAccountAndRejectIfInvalid() {
                return $q((resolve, reject) => {
                    const $route = $injector.get('$route');

                    if (blockAuthenticatedAccess.block) {
                        reject(getSignInRedirect());
                        return;
                    }

                    waitForAuthCallComplete()
                        .then(() => {
                            if ($rootScope.currentUser) {
                                // preload student dashboard data so it will be there when we need it
                                // We don't need this on every page, but we need it broadly enough that
                                // it seems worth it to always preload it.  We need it for sure on the
                                // student dashboard, stream dashbard, and player (the last two are needed
                                // so that we can calculate whether prereqs are met)
                                if ($window.location && /^\/admin/.test($window.location.pathname)) {
                                    return undefined;
                                }

                                /*
                                    We have to wrap any api requests in route resolvers in
                                    rejectInOfflineMode.  If we don't, then a network error will
                                    cause the request to hang forever (see comment near rejectInOfflineMode).
                                    When we use rejectInOfflineMode, the request can throw a DisconnectedError.
                                    If that error is thrown, it will be caught in routes.js, in the resolvers
                                    implementation.

                                    ensureStudentDashboard uses rejectInOfflineMode internally,
                                    se we don't have to call it explicitly here.
                                */
                                return LearnerContentCache.ensureStudentDashboard();
                            }

                            return undefined;
                        })
                        .then(() => {
                            const currentUser = $rootScope.currentUser;

                            if (!currentUser) {
                                resolve(undefined);
                            }

                            // If the user has to confirm zir name and email, send zir to the
                            // confirm profile page
                            else if (
                                !currentUser.confirmed_profile_info &&
                                !$route.current.$$route.regexp.test('/complete-registration')
                            ) {
                                // retain target redirect support since this can happen to older users who might be reloading a path
                                reject(redirectWithTarget('/complete-registration'));
                            }

                            // If we have an override for where to send the user after logging in
                            else if (Cookies.get('signup_target')) {
                                const redirectTarget = Cookies.get('signup_target');
                                Cookies.remove('signup_target', {
                                    path: '/',
                                });

                                // prevent any more redirects for this user session
                                currentUser.$$hasBeenRedirectedToApplication = true;
                                reject({
                                    redirect: redirectTarget, // "redirectWithoutTarget"
                                });
                            }

                            // On first loading the app, send the user to the application if they
                            // need to fill one out. Unless deeplinking beyond the dashboard.
                            //  You can also add `try_lesson` as a query param to
                            // the route to bypass this behavior (e.g. `/dashboard?try_lesson=true`).
                            else if (
                                $route.current.params?.try_lesson !== 'true' &&
                                $route.current.$$route?.regexp.test('/dashboard') &&
                                currentUser.shouldBeRedirectedToApplication &&
                                !currentUser.$$hasBeenRedirectedToApplication &&
                                !$route.current.$$route?.allowedWhenOnboardingIncomplete
                            ) {
                                // Since $$hasBeenRedirectedToApplication is not stored, it will be
                                // undefined on login/reload, preventing an infinite redirect loop.

                                currentUser.$$hasBeenRedirectedToApplication = true;
                                reject({
                                    redirect: '/settings/application', // "redirectWithoutTarget"
                                });
                            }

                            // On first loading the app, send the user to the cohort billing page
                            // if necessary
                            else if (
                                !currentUser.$$hasCompletedFirstLoad &&
                                $route.current.$$route &&
                                $route.current.$$route.regexp.test($rootScope.homePath) &&
                                currentUser.shouldVisitApplicationStatus
                            ) {
                                reject({
                                    redirect: '/settings/application_status', // "redirectWithoutTarget"
                                });
                            } else {
                                currentUser.$$hasCompletedFirstLoad = true;

                                // Remove the try_lesson query param before we resolve to the desired route.
                                // The user doesn't need to see it after we resolve the route and it mucks
                                // up the URL unnecessarily.
                                if ($route.current.params?.try_lesson) {
                                    $injector.get('$location').search('try_lesson', null);
                                }

                                resolve(currentUser);
                            }
                        })
                        .catch(err => {
                            // Make sure redirects thrown anywhere above bubble up to
                            // the route event handler (or any other error too, really)
                            reject(err);
                        });
                });
            }

            return {
                profileConfirmationRequired() {
                    return $q((resolve, reject) => {
                        waitForAuthCallComplete().then(() => {
                            if ($rootScope.currentUser && !$rootScope.currentUser.confirmed_profile_info) {
                                resolve();
                            } else {
                                reject({
                                    redirect: $rootScope.homePath,
                                });
                            }
                        });
                    });
                },

                redirectToHome() {
                    const deferred = $q.defer();
                    if (blockAuthenticatedAccess.block) {
                        return undefined;
                    }

                    waitForAuthCallComplete().then(() => {
                        // If the user has auth headers, but they're expired or
                        // otherwise invalid, then send them to the sign-in page
                        if (!$rootScope.currentUser && window.hasAuthCookie) {
                            deferred.reject(getSignInRedirect());
                        } else {
                            deferred.reject({
                                redirect: $rootScope.homePath,
                            });
                        }
                    });
                    return deferred.promise;
                },

                redirectToRootDefault() {
                    if ($window.CORDOVA) {
                        ValidationResponder.forwardToTargetUrl(); // NOTE: this handles last_visited_route for us
                    } else {
                        $window.location.href = '/';
                    }
                },

                redirectToOnboardingRegisterIfMiyaMiya() {
                    const deferred = $q.defer();
                    if ($window.CORDOVA?.miyaMiya) {
                        deferred.reject({
                            redirect: '/onboarding/hybrid/register?animate',
                        });
                    } else {
                        deferred.resolve();
                    }
                    return deferred.promise;
                },

                redirectToHomeIfAuthorized() {
                    const deferred = $q.defer();
                    if (blockAuthenticatedAccess.block) {
                        return undefined;
                    }
                    waitForAuthCallComplete().then(() => {
                        if ($rootScope.currentUser) {
                            deferred.reject({
                                redirect: $rootScope.homePath,
                            });
                        } else {
                            deferred.resolve();
                        }
                    });
                    return deferred.promise;
                },

                loadJoinAndRedirect() {
                    const $route = $injector.get('$route');
                    const deferred = $q.defer();

                    const urlPrefix =
                        $window.CORDOVA && $window.CORDOVA.forcedUrlPrefix
                            ? $window.CORDOVA.forcedUrlPrefix
                            : $route.current.pathParams.url_prefix;

                    if (urlPrefix) {
                        deferred.reject({
                            redirect: `/${urlPrefix}/join/account`,
                            search: $injector.get('$location').search(),
                        });
                    } else {
                        throw new Error('urlPrefix required');
                    }
                    return deferred.promise;
                },

                // user access-level validation responders

                unrestrictedAccess() {
                    return loadAccountAndRejectIfInvalid();
                },

                hasLearnerAccess() {
                    return hasAppropriateAccess('learner');
                },

                // We use the same route for both the open-access networkTab and event drilldown.
                // If an event-id was provided ensure the user has full event access.
                hasStudentNetworkEventsAccessForEventId() {
                    return hasAppropriateAccess('studentNetwork').then(() => {
                        if ($injector.get('$route').current.params['event-id']) {
                            return hasAppropriateAccess('studentNetworkEvents');
                        }
                        return $q.when();
                    });
                },

                hasEditorAccess() {
                    return hasAppropriateAccess('editor').then(() => RouteAssetLoader.loadEditorDependencies());
                },

                hasSuperEditorAccess() {
                    return hasAppropriateAccess('superEditor').then(() => RouteAssetLoader.loadAdminDependencies());
                },

                hasAdminAccess() {
                    return hasAppropriateAccess('admin').then(() => RouteAssetLoader.loadAdminDependencies());
                },

                hasReportsAccess() {
                    return hasAppropriateAccess('reports').then(() => RouteAssetLoader.loadReportsDependencies());
                },

                hasSuperEditorOrInterviewerAccess() {
                    return hasAppropriateAccess('superEditorOrInterviewer').then(() =>
                        RouteAssetLoader.loadAdminDependencies(),
                    );
                },

                hasBotPageAccess() {
                    return hasAppropriateAccess('botPage');
                },

                hasNominationsAccess() {
                    return hasAppropriateAccess('nominations');
                },

                // SCORM Authentication

                scormAccess() {
                    return $q(resolve => {
                        const origin = document.referrer;
                        const rpcFunc = RPC;
                        const rpc = rpcFunc($window, $window.parent, origin);

                        // make a request for the SCO-stamped scorm_token
                        // eslint-disable-next-line no-console
                        console.info('making RPC call to requestScormToken ...');
                        rpc.call('requestScormToken', scormToken => {
                            // eslint-disable-next-line no-console
                            console.info('scormToken received from SCO', scormToken);
                            $window.scormToken = scormToken;
                            resolve();
                        });
                    });
                },

                // Demo Route

                demoAccess() {
                    return $q((resolve, reject) => {
                        if (blockAuthenticatedAccess.block) {
                            reject(getSignInRedirect());
                            return;
                        }

                        waitForAuthCallComplete().then(() => {
                            if (!$rootScope.currentUser) {
                                resolve(undefined);
                            } else {
                                resolve($rootScope.currentUser);
                            }
                        });
                    });
                },

                // 404
                handle404() {
                    if ($window.CORDOVA) {
                        // possible the route has been removed since last visit
                        $location.url($rootScope.homePath);
                        return undefined;
                    }

                    // use a resolver that will never resolve so
                    // that nothing renders as we forward back to the rails
                    // /404 page
                    $window.location.href = '/404';
                    return $injector.get('$q')(() => {});
                },

                hasCordovaNoNetwork() {
                    return $q((resolve, reject) => {
                        if ($window.CORDOVA && !$rootScope.networkBootstrapCompleted) {
                            resolve();
                        } else {
                            reject({
                                redirect: $rootScope.homePath,
                            });
                        }
                    });
                },
            };
        },
    ]);
