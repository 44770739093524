import { type FileMessage } from '@sendbird/chat/message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { isImageMessage, isVideoMessage } from '../utils';
import { MessagingModal } from './MessagingModal';

type Props = {
    onCancel: () => void;
    message: FileMessage;
};

export const FileViewer = ({ onCancel, message }: Props) => {
    if (!message) return null;

    return (
        <MessagingModal>
            <div className="header tw-flex tw-h-[64px] tw-items-center tw-justify-start tw-border-b tw-border-solid tw-border-slate-grey-mid-light tw-py-1 tw-pe-3 tw-ps-5">
                <h3 className="tw-me-2 tw-line-clamp-1 tw-overflow-ellipsis tw-text-lg tw-font-semibold tw-text-black">
                    {message.name}
                </h3>
                <div className="tw-text-[16px] tw-text-slate-grey">{message.sender.nickname}</div>
                <div className="tw-ms-auto tw-flex tw-shrink tw-items-center tw-justify-end">
                    <a
                        href={message.url}
                        download={message.name}
                        className="-tw-mt-[3px] tw-me-1 tw-ms-auto tw-inline-flex tw-items-center tw-justify-center tw-rounded-lg tw-p-2 hover:tw-bg-slate-grey-lightest"
                    >
                        <FontAwesomeIcon icon={faDownload} className="tw-text-lg tw-text-black" />
                    </a>
                    <button
                        type="button"
                        className="-tw-mt-[3px] tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-p-2 hover:tw-bg-slate-grey-lightest"
                        onClick={onCancel}
                    >
                        <FontAwesomeIcon icon={faTimes} className="tw-text-lg tw-text-black" />
                    </button>
                </div>
            </div>
            <div className="tw-flex tw-h-[calc(100%-64px)] tw-items-center tw-justify-center tw-overflow-y-auto tw-p-5">
                {isImageMessage(message) && (
                    <img
                        src={message.url}
                        alt={message.name}
                        className="tw-max-h-full tw-max-w-full tw-object-contain"
                    />
                )}
                {isVideoMessage(message) && (
                    <video src={message.url} controls className="tw-max-h-full tw-max-w-full">
                        <track kind="captions" />
                    </video>
                )}
            </div>
        </MessagingModal>
    );
};
