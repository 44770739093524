import { type RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { type MessageContentProps } from '@sendbird/uikit-react/ui/MessageContent';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons/faArrowTurnLeft';
import { useTargetBrandConfig } from 'FrontRoyalAngular';
import { useSelector, useDispatch } from 'react-redux';
import { type CoreMessageType } from '@sendbird/uikit-react/types/utils';
import { useTranslation } from 'react-i18next';
import { getHighlightedMessageId } from '../selectors';
import { Menus } from './Menus';
import { ReadReceipt } from './ReadReceipt';
import { MessageBody } from './MessageBody';
import { MessageReactions } from './MessageReactions';
import { MemberAvatars } from './MemberAvatars';
import { setShowChannelInfo, setShowProfileUserId } from '../actions';

const classes = {
    container: (isOtherUser: boolean, isHighlighted: boolean) =>
        clsx(
            'tw-flex',
            'tw-p-[2px]',
            'tw-mb-[10px]',
            'tw-transition-all',
            'tw-duration-300',
            'tw-rounded-lg',
            'tw-border',
            'tw-border-solid',
            isOtherUser ? 'tw-justify-start' : 'tw-justify-end',
            {
                'tw-border-yellow tw-bg-yellow-50': isHighlighted,
                'tw-border-transparent tw-bg-transparent': !isHighlighted,
            },
        ),
    avatar: clsx('tw-h-[40px]', 'tw-w-[40px]', 'tw-me-2', 'tw-mt-[19px]', 'tw-shrink-0'),
    adminAvatar: clsx('tw-h-[40px]', 'tw-w-[40px]', 'tw-me-2', 'tw-mt-[19px]', 'tw-shrink-0', 'tw-rounded-full'),
    menuAndContentWrapper: (isOtherUser: boolean) =>
        clsx(
            'tw-flex',
            'tw-mt-[5px]',
            'tw-max-w-[75%]',
            'tw-relative',
            isOtherUser ? 'tw-flex-row' : 'tw-flex-row-reverse',
        ),
    contentContainer: (isOtherUser: boolean) =>
        clsx('tw-flex tw-w-full tw-flex-col', {
            'tw-items-end': !isOtherUser,
        }),
    icons: {
        arrow: clsx('tw-mr-[8px]', 'tw-mt-[-2px]'),
    },
    reactionsContainer: clsx('tw-w-full', 'tw-max-w-full'),
    body: {
        container: (isOtherUser: boolean) =>
            clsx('tw-flex', 'tw-flex-col', 'tw-w-full', isOtherUser ? 'tw-items-start' : 'tw-items-end'),
        replyAndName: (isOtherUser: boolean) =>
            clsx(
                'tw-flex',
                'tw-min-h-[14px]',
                isOtherUser ? 'tw-flex-row' : 'tw-flex-row-reverse',
                'tw-ms-[15px] tw-justify-start',
            ),
        replyIconWrapper: (isOtherUser: boolean) =>
            clsx(
                'tw-text-[14px]',
                'tw-font-semibold',
                'tw-text-slate-grey',
                'tw-flex',
                'tw-items-center',
                isOtherUser ? 'tw-me-[8px]' : 'tw-ms-[8px]',
            ),
        nameAndDate: {
            container: clsx('tw-flex', 'tw-min-h-[14px]'),
            name: clsx(
                'tw-text-[14px]',
                'tw-font-semibold',
                'tw-text-slate-grey',
                'tw-me-2',
                'tw-leading-none',
                'tw-text-start',
                'hover:tw-underline',
            ),
            date: clsx('tw-text-[14px]', 'tw-text-slate-grey', 'tw-leading-none'),
        },
        parentReply: clsx(
            'tw-flex',
            'tw-w-fit',
            'tw-min-h-[42px]',
            'tw-max-w-[100%]',
            'tw-z-[1]',
            'tw-text-[14px]',
            'tw-py-[10px]',
            'tw-px-[15px]',
            'tw-rounded-[20px]',
            'tw-text-[16px]',
            'tw-text-black',
            'tw-text-opacity-75',
            'tw-bg-slate-grey-mid',
            'tw-mb-[-5px]',
        ),
        content: (isOtherUser: boolean, showEmojis: boolean) =>
            clsx(
                'tw-w-fit',
                'tw-z-[5]',
                'tw-min-h-[40px]',
                'tw-min-w-[50px]',
                'tw-max-w-full',
                'tw-text-[14px]',
                'first:*:tw-mx-[15px]',
                'first:*:tw-my-[8px]',
                { 'first:*:tw-mb-[5px]': showEmojis },
                'tw-rounded-[20px]',
                'tw-text-[16px]',
                'tw-flex',
                'tw-flex-col',
                isOtherUser
                    ? clsx('tw-bg-slate-grey-lightest', 'tw-text-black')
                    : clsx(
                          'tw-text-white',
                          'tw-font-medium',
                          'tw-bg-gradient-to-r',
                          'quantic:tw-from-[#FF4D63]',
                          'quantic:tw-to-[#FE5F5F]',
                          'valar:tw-from-blue',
                          'valar:tw-to-blue',
                      ),
            ),
    },
};

type Props = Omit<MessageContentProps, 'className'> & {
    onRefSet: (messageId: number, ref: RefObject<HTMLDivElement | null> | null) => void;
};

export const MessageContent = ({ message, userId, onRefSet, ...rest }: Props) => {
    const dispatch = useDispatch();
    const BrandConfig = useTargetBrandConfig();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const isFromOtherUser = useMemo(
        () => message.isAdminMessage() || message.sender.userId !== userId,
        [message, userId],
    );
    const highlightedMessageId = useSelector(getHighlightedMessageId);
    const isHighlighted = highlightedMessageId === message.messageId;

    const showEmojisInMessage = useMemo(() => !!message.reactions.length, [message.reactions.length]);
    const hasParentMessage = useMemo(() => !!message.parentMessage, [message.parentMessage]);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const [parentMessageName, currentMessageName] = useMemo(() => {
        if (!message.parentMessage) return ['', ''];

        const parentMessage = message.parentMessage as CoreMessageType;

        const parentMessageSender = parentMessage.isAdminMessage()
            ? t('messaging.admin')
            : parentMessage.sender.userId === userId
            ? t('messaging.you')
            : parentMessage.sender.nickname;

        const messageSender = message.isAdminMessage()
            ? t('messaging.admin')
            : message.sender.userId === userId
            ? t('messaging.you')
            : message.sender.nickname;

        return [parentMessageSender, messageSender];
    }, [message, t, userId]);

    const [hovered, setHovered] = useState(false);

    useEffect(() => {
        onRefSet(message.messageId, wrapperRef);

        return () => {
            onRefSet(message.messageId, null);
        };
    }, [onRefSet, wrapperRef, message]);

    return (
        <div
            ref={wrapperRef}
            className={classes.container(isFromOtherUser, isHighlighted)}
            onMouseOver={() => setHovered(true)}
            onFocus={() => undefined}
            onMouseLeave={() => {
                setHovered(false);
            }}
        >
            {isFromOtherUser &&
                (message.isAdminMessage() ? (
                    <img
                        className={classes.adminAvatar}
                        src={BrandConfig.avatarDefault || BrandConfig.avatarAlt!}
                        alt="admin"
                    />
                ) : (
                    <button
                        type="button"
                        className="tw-h-full tw-self-end"
                        onClick={() => {
                            dispatch(setShowChannelInfo(false));
                            dispatch(setShowProfileUserId(message.sender.userId));
                        }}
                    >
                        <MemberAvatars members={[message.sender]} className={classes.avatar} showOnlineStatus={false} />
                    </button>
                ))}

            <div className={classes.body.container(isFromOtherUser)}>
                <div className={classes.body.replyAndName(isFromOtherUser)}>
                    {message.parentMessage && (
                        <div className={classes.body.replyIconWrapper(isFromOtherUser)}>
                            <FontAwesomeIcon icon={faArrowTurnLeft} className={classes.icons.arrow} />
                            {t('messaging.repliedTo', {
                                parent: parentMessageName,
                                current: currentMessageName,
                            })}
                        </div>
                    )}
                    <div className={classes.body.nameAndDate.container}>
                        {isFromOtherUser && !hasParentMessage && !message.isAdminMessage() && (
                            <button
                                type="button"
                                className={classes.body.nameAndDate.name}
                                onClick={() => {
                                    dispatch(setShowChannelInfo(false));
                                    dispatch(setShowProfileUserId(message.sender.userId));
                                }}
                            >
                                {message.sender.nickname}
                            </button>
                        )}
                        {!isFromOtherUser && !message.isAdminMessage() && <ReadReceipt message={message} />}
                        <div className={classes.body.nameAndDate.date}>
                            {moment(message.createdAt).format('hh:mm A')}
                        </div>
                    </div>
                </div>
                <div className={classes.menuAndContentWrapper(isFromOtherUser)}>
                    <div className={classes.contentContainer(isFromOtherUser)}>
                        {message.parentMessage && (
                            <div className={classes.body.parentReply}>
                                <MessageBody message={message.parentMessage} parentMessage />
                            </div>
                        )}

                        <div className={classes.body.content(isFromOtherUser, showEmojisInMessage)}>
                            <MessageBody message={message} parentMessage={false} />
                        </div>
                        {showEmojisInMessage && (
                            <div className={classes.reactionsContainer}>
                                <MessageReactions
                                    message={message}
                                    nicknamesMap={rest.nicknamesMap!}
                                    userId={userId}
                                    isFromOtherUser={isFromOtherUser}
                                />
                            </div>
                        )}
                    </div>
                    {hovered && <Menus message={message} isFromOtherUser={isFromOtherUser} userId={userId} {...rest} />}
                </div>
            </div>
        </div>
    );
};
