import 'SafeApply/angularModule';

export default angular.module('onImageLoad', ['safeApply']).directive('onImageLoad', [
    '$injector',
    $injector => {
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'A',
            link(scope, element, attrs) {
                element.on('load', evt => {
                    scope.$eval(attrs.onImageLoad, {
                        $event: evt,
                    });
                    safeApply(scope);
                });

                // If the image failed to load, set a flag on the parent.
                element.bind('error', () => {
                    scope.$parent.imageLoadError = true;
                });
            },
        };
    },
]);
