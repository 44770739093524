import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import { setupBrandScopeProperties } from 'AppBranding';

angularModule.directive('appMenuUserAvatar', [
    '$injector',
    function factory($injector) {
        const ConfigFactory = $injector.get('ConfigFactory');

        return {
            restrict: 'E',
            scope: {
                user: '<',
            },
            link(scope, elem) {
                setupBrandScopeProperties($injector, scope, ['avatarDefault']);
                let watchingTargetBrandAvatarDefault = false;

                function watchTargetBrandAvatarDefault() {
                    if (!watchingTargetBrandAvatarDefault) {
                        scope.$watch('avatarDefault', setAvatarBackgroundImage);
                        watchingTargetBrandAvatarDefault = true;
                    }
                }

                // FIXME: Consider not watching active_institution here in favor of something more generalized.
                // See https://trello.com/c/ah19J3sf.
                function setAvatarBackgroundImage() {
                    let url;

                    if (scope.user?.avatarUrl) {
                        url = scope.user.avatarUrl;
                    } else if (ConfigFactory.isInitialized()) {
                        url = scope.avatarDefault;
                        watchTargetBrandAvatarDefault();
                    } else {
                        // We saw evidence that when the user relaunches the mobile app, the config hasn't yet
                        // been initialized, so if we see that this is the case, we retrieve the config and use
                        // it to determine what background-image should be shown. Note that in this case we don't
                        // bother setting the `url` until after the config has been retrieved. This is okay because
                        // we don't render the mobile app's UI until this API call has finished, so it's not like
                        // the user sees this directive without a background-image.
                        url = scope.avatarDefault;
                        elem.css('background-image', `url(${url})`);
                        watchTargetBrandAvatarDefault();
                    }

                    // If we have to retrieve the config with an API call to determine what background-image
                    // should be shown, the `url` variable shouldn't be set. This is okay because we don't
                    // render the mobile app's UI until this API call has finished, so it's not like the user
                    // sees this directive without a background-image.
                    if (url) {
                        elem.css('background-image', `url(${url})`);
                    }
                }

                scope.$watch('user.avatar_url', setAvatarBackgroundImage);
            },
        };
    },
]);
