import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import getFilePathsFromManifest from 'WebpackManifestHelper';

// Loads asset requirements designed for use in route changes
angularModule.factory('Navigation.RouteAssetLoader', [
    '$injector',
    $injector => {
        const $ocLazyLoad = $injector.get('$ocLazyLoad');
        const $q = $injector.get('$q');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');
        const Locale = $injector.get('Locale');
        const $translate = $injector.get('$translate');
        let googlePlacesDependencyPromise = false;

        function lazyLoad(options) {
            return (
                $ocLazyLoad
                    .load(options)

                    // retry in case there was a network error (We didn't put this here to fix
                    // any particular problem. Just guessing it might be helpful.
                    .catch(() => $ocLazyLoad.load(options))
                    .catch(rejection => {
                        ErrorLogService.notify(rejection, { level: 'warning', options });
                    })
            );
        }

        return {
            loadGooglePlacesDependencies() {
                return ConfigFactory.getConfig().then(config => {
                    const mapsUrl = `js!https://maps.googleapis.com/maps/api/js?libraries=places&key=${config.google_api_key}`;

                    // Seems crazy that we have to track this promise ourself.  LazyLoad should be
                    // doing this for us.  But, it didn't seem like it was working that way.  Isaac and Nate
                    // saw lazyload injecting the script tag twice on the edit student network event page.
                    if (googlePlacesDependencyPromise) {
                        return googlePlacesDependencyPromise;
                    }

                    // We intentionally disable Google Maps for users in China (see https://trello.com/c/fm9wnYk5).
                    // This obviously breaks parts of the site, but we're okay with this for the time being until
                    // we can come up with a viable strategy for an alternative solution.
                    if (
                        !config.chinaRegionMode() &&
                        (!$window.google || !$window.google.maps || !$window.google.maps.places)
                    ) {
                        googlePlacesDependencyPromise = lazyLoad({
                            files: [mapsUrl],
                        });
                        return googlePlacesDependencyPromise;
                    }

                    return $q.when();
                });
            },

            loadEditorDependencies() {
                const files = getFilePathsFromManifest('editor', 'css')
                    .concat(['assets/mathjax/MathJax.js?config=TeX-AMS_CHTML-full&delayStartupUntil=configured'])
                    .concat(getFilePathsFromManifest('editor', 'js'));
                const promises = [
                    lazyLoad({
                        files,
                        serie: true,
                    }),
                ];

                // if we haven't already loaded the Arabic font due to being in that locale,
                // load it up so that Arabic content will display properly.
                promises.push(this.loadArabicFontDependencies());

                return $q.all(promises);
            },

            loadAdminDependencies() {
                const files = getFilePathsFromManifest('admin', 'css').concat(getFilePathsFromManifest('admin', 'js'));
                // Admin needs some editor stuff, like for batch lesson grading
                // and ContentItemEditorModule, etc. Must be loaded first!
                return this.loadEditorDependencies()
                    .then(this.loadReportsDependencies.bind(this))
                    .then(() =>
                        lazyLoad({
                            files,
                            serie: true,
                        }),
                    );
            },

            loadReportsDependencies() {
                const files = getFilePathsFromManifest('reports', 'css').concat(
                    getFilePathsFromManifest('reports', 'js'),
                );
                const promises = [
                    lazyLoad({
                        files,
                        serie: true,
                    }).then(() => {
                        // Since loading reports.js added some locales to window.Smartly.locales.modules.DEFAULT_LOCALE,
                        // we need to tell $translate to refresh, which will tell the translationLoader to reload
                        // window.Smartly.locales.modules.DEFAULT_LOCALE.  (If the user is not currently
                        // in the default locale, the translationLoader will be smart enough to do nothing.)
                        const currentLang = $translate.use(false);
                        $translate.refresh(currentLang);
                    }),
                ];

                return $q.all(promises);
            },

            loadArabicFontDependencies() {
                return lazyLoad({
                    files: getFilePathsFromManifest('arabic-font-families', 'css'),
                });
            },

            loadGlobalDependencies() {
                const files = getFilePathsFromManifest('front-royal', 'css');

                // main stylesheet and translations
                const promises = [
                    lazyLoad({
                        files,
                    }),
                ];

                // if our locale is arabic, preload the fonts
                if (Locale.activeCode === 'ar') {
                    promises.push(this.loadArabicFontDependencies());
                }

                return $q.all(promises);
            },
        };
    },
]);
