import BuildConfig from 'BuildConfig';
import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import template from 'Navigation/angularModule/views/privacy_terms_footer.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('privacyTermsFooter', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            scope: {},
            templateUrl,
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                scope.build_number = BuildConfig.build_number;
            },
        };
    },
]);
