import { type User } from '@sendbird/chat';
import { type AnyObject } from '@Types';
import { type CareerProfile, getMostRecentPositionText } from 'CareerProfiles';
import { clsx } from 'clsx';
import { type FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MemberAvatars } from './MemberAvatars';
import { setShowProfileUserId } from '../actions';

const classes = {
    container: clsx('tw-flex', 'tw-flex-col', 'tw-justify-end', 'tw-h-full', 'tw-px-[5px]', 'tw-py-[5px]'),
    button: {
        container: clsx('tw-mb-[22px]'),
        element: clsx(
            'tw-h-[40px]',
            'tw-w-[136px]',
            'tw-border',
            'tw-border-slate-grey-mid-light',
            'tw-rounded-[4px]',
            'tw-text-[16px]',
            'tw-font-semibold',
            'tw-text-slate-grey',
            'tw-pt-[1px]',
            'tw-uppercase',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
            'tw-transition-colors',
            'hover:tw-bg-slate-grey-lightest',
        ),
    },
    usersText: (oneUser: boolean) => clsx(oneUser ? 'tw-mb-5' : 'tw-mb-[30px]', 'tw-text-black', 'tw-text-[16px]'),
    images: {
        container: (oneUser: boolean) => clsx(oneUser ? 'tw-mb-[17px]' : 'tw-mb-[21px]', 'tw-flex'),
        imagesContainer: clsx(''),
        soloAvatar: clsx(
            'tw-w-[100px]',
            'tw-h-[100px]',
            'sm:tw-w-[80px]',
            'sm:tw-h-[80px]',
            'md:tw-w-[100px]',
            'md:tw-h-[100px]',
            'tw-me-[12px]',
            'tw-shrink-0',
        ),
        multiAvatarItem: clsx('tw-me-2', 'tw-h-[60px]', 'tw-w-[60px]'),
        text: clsx(
            'tw-flex',
            'tw-flex-col',
            'tw-justify-center',
            'tw-text-black',
            'tw-leading-5',
            'tw-flex',
            'tw-break-anywhere',
        ),
        name: clsx('tw-text-black', 'tw-text-lg', 'tw-font-semibold', 'tw-flex', 'tw-break-anywhere'),
    },
};

export const NewChannelInfo: FC<{
    users: User[];
    userProfilesById: AnyObject<CareerProfile>;
}> = ({ users, userProfilesById }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('back_royal', { keyPrefix: 'messaging' });
    const { t: tWithoutPrefix } = useTranslation('back_royal');
    const oneUserSelected = useMemo(() => users.length === 1, [users.length]);

    return users.length ? (
        <div className={classes.container}>
            <div className={classes.images.container(oneUserSelected)}>
                {oneUserSelected ? (
                    <>
                        <MemberAvatars
                            members={[users[0]]}
                            showOnlineStatus={false}
                            className={classes.images.soloAvatar}
                        />
                        <div className={classes.images.text}>
                            <p className={classes.images.name}>{users[0].nickname}</p>
                            {userProfilesById[users[0].userId] && (
                                <p>{getMostRecentPositionText(userProfilesById[users[0].userId], tWithoutPrefix)}</p>
                            )}
                        </div>
                    </>
                ) : (
                    users.map(u => (
                        <button
                            key={u.userId}
                            type="button"
                            onClick={() => {
                                dispatch(setShowProfileUserId(u.userId));
                            }}
                        >
                            <MemberAvatars
                                members={[u]}
                                showOnlineStatus={false}
                                className={classes.images.multiAvatarItem}
                            />
                        </button>
                    ))
                )}
            </div>
            <div className={classes.usersText(oneUserSelected)}>
                {oneUserSelected ? (
                    <p>
                        {t('messaging.thisConversation1')}
                        <span className="tw-font-semibold">{users[0].nickname}</span>
                        {t('messaging.thisConversation2')}
                    </p>
                ) : (
                    <p>
                        {t('messaging.beginningOfHistory')}
                        {users.map((u, _, a) => (
                            <Fragment key={u.userId}>
                                <span className="tw-font-semibold">{u.nickname}</span>
                                {u === a.at(-1)
                                    ? t('messaging.period')
                                    : u === a.at(-2)
                                    ? t('messaging.and')
                                    : t('messaging.comma')}
                            </Fragment>
                        ))}
                    </p>
                )}
            </div>
            {oneUserSelected && (
                <div className={classes.button.container}>
                    <button
                        className={classes.button.element}
                        type="button"
                        onClick={() => {
                            dispatch(setShowProfileUserId(users[0].userId));
                        }}
                    >
                        {t('messaging.viewProfile')}
                    </button>
                </div>
            )}
        </div>
    ) : null;
};
