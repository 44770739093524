import canNavigateToCurrentRouteInOfflineMode from './canNavigateToCurrentRouteInOfflineMode';
import 'OfflineMode/locales/OfflineMode/showOfflineModal-en.json';

export default async function showOfflineModal(injector) {
    const $q = injector.get('$q');
    const DialogModal = injector.get('DialogModal');
    const TranslationHelper = injector.get('TranslationHelper');
    const translationHelper = new TranslationHelper('OfflineMode.showOfflineModal');
    let messageKey;

    if (await canNavigateToCurrentRouteInOfflineMode(injector)) {
        messageKey = 'OfflineMode.showOfflineModal.only_offline_courses_available';
    } else {
        messageKey = 'OfflineMode.showOfflineModal.click_to_continue_to_dashboard';
    }

    return $q(resolve => {
        DialogModal.alert({
            content: `
                <p translate-once="{{messageKey}}"></p>
                <p class="center">
                    <button
                        class="flat green"
                        ng-click="DialogModal.hideAlerts()"
                        translate-once="OfflineMode.showOfflineModal.continue"
                    ></button>
                </p>
            `,
            title: translationHelper.get('you_are_offline'),
            close: () => {
                resolve();
            },
            scope: {
                DialogModal,
                messageKey,
            },
        });
    });
}
