import { createAsyncThunk } from '@reduxjs/toolkit';
import { SendbirdSdkProvider } from 'SendbirdSdkProvider';
import { careerProfilesApi } from 'CareerProfiles';
import { type AnyObject } from '@Types';
import { actions } from './messagingSlice';

export const {
    setActiveChannelUrl,
    setOpenChat,
    setReplyMessageId,
    setLoading,
    setShowNewMessage,
    setPresetNewMessageRecipient,
    setShowChannelInfo,
    setShowProfileUserId,
    setUserChannelMap,
    setShowMessageSearch,
    setTargetSearchMessage,
    setHighlightedMessageId,
    registerChannel,
    setDraftUser,
} = actions;

export const openChatAndCreateDraftChannelIfNeeded = createAsyncThunk(
    'messaging/openChatAndCreateDraftChannelIfNeeded',
    async (
        { currentUserId, token, userToMessageId }: { currentUserId: string; userToMessageId: string; token: string },
        { dispatch },
    ) => {
        dispatch(setLoading(true));
        dispatch(setOpenChat(true));
        dispatch(setActiveChannelUrl(''));

        const sdk = SendbirdSdkProvider.sdk!;
        await sdk.connect(currentUserId, token);

        const users = await careerProfilesApi.makeRequest('getRelevantSendbirdUserCareerProfiles', [userToMessageId]);

        const userToMessageProfile = users?.find(u => u.userId === userToMessageId);

        const userQuery = sdk.createApplicationUserListQuery({ userIdsFilter: [userToMessageId] });

        const [user] = await userQuery.next();

        const draftUserObject = {
            id: userToMessageId,
            profile: userToMessageProfile!,
            serializedUser: user.serialize() as AnyObject,
        };

        dispatch(setPresetNewMessageRecipient(draftUserObject));
        dispatch(setDraftUser(draftUserObject));
        dispatch(setShowNewMessage(true));
        dispatch(setLoading(false));
    },
);
