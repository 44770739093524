import angularModule from 'Navigation/angularModule/scripts/navigation_module';
import template from 'Navigation/angularModule/views/previous_button_desktop.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('previousButtonDesktop', [
    '$injector',
    $injector => {
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const SoundManager = $injector.get('SoundManager');
        const SoundConfig = $injector.get('SoundConfig');
        const TranslationHelper = $injector.get('TranslationHelper');

        const translationHelper = new TranslationHelper('navigation.previous_button_desktop');

        return {
            restrict: 'E',
            templateUrl,

            link(scope) {
                scope.onClick = () => {
                    SoundManager.playUrl(SoundConfig.DEFAULT_CLICK);
                    AppHeaderViewModel.backButtonClick();
                };

                scope.previousButtonLabel = translationHelper.get('previous');
            },
        };
    },
]);
