import angularModule from 'Navigation/angularModule/scripts/navigation_module';

angularModule.factory('RouteEventHandler', [
    '$injector',
    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const $location = $injector.get('$location');
        const $timeout = $injector.get('$timeout');
        const $route = $injector.get('$route');
        const $window = $injector.get('$window');
        const safeApply = $injector.get('safeApply');
        const blacklistedRouteDirectives = ['sign-in', 'show-stream', 'stream-completed', 'disconnected-mobile-init'];
        const preventSuccessiveRouteDirectives = ['settings'];

        $rootScope.pageHistory = []; // This is on $rootScope for testing

        return {
            deleteOnboardingHistory() {
                // Iterate through the history in reverse, splicing any 'onboarding' entries.
                // NOTE: This needs to be done in reverse because the array is re-indexed after
                // every splice.
                for (let i = $rootScope.pageHistory.length - 1; i >= 0; i--) {
                    if (
                        $rootScope.pageHistory[i].url.match(/onboarding/gi) ||
                        $rootScope.pageHistory[i].directive.match(/onboarding/gi)
                    ) {
                        $rootScope.pageHistory.splice(i, 1);
                    }
                }
            },

            setupEventListeners() {
                //-----------------------------------------
                // Cordova history management
                //-----------------------------------------

                // Below, we override Cordova's backbutton handling and attempt to pop the stack
                // in order to navigate to the previous activity/directive. Android's default behavior
                // is to exit the application on a backbutton press when the history/activity stack is
                // empty. After opening the app and logging in for the first time, we have a history that
                // contains onboarding directives. However, we do not allow a logged in user to navigate
                // to any onboarding directives. This is a problem on Android, and doesn't allow the
                // backbutton to ever exit the application in such a scenario.
                //
                // This isn't a practical issue for iOS, but it still makes sense to reset the history
                // in Cordova after successful authentication, since we don't allow navigation to the
                // onboarding directives anyway.
                $rootScope.$on('validation-responder:login-success', () => {
                    if ($window.CORDOVA) {
                        this.deleteOnboardingHistory();
                    }
                });

                //-----------------------------
                // Route Failure Management
                //-----------------------------

                $rootScope.$on('$routeChangeError', (ev, current, previous, rejection) => {
                    ev.preventDefault();
                    let redirectPath = rejection && rejection.redirect;

                    // if there is no redirectPath, then this was an unexpected error. Notify
                    // about the error, but still redirect to the homepage
                    if (!redirectPath) {
                        // don't log as error if failing on pipelined assets that can change during deployments.
                        // see also: https://trello.com/c/mo9VUY0N/235-chore-silence-sentry-error-when-unable-to-load-dependency
                        if (rejection && rejection.message && !rejection.message.includes('Unable to load assets')) {
                            rejection.message = `routeChangeError navigating to ${current.$$route.originalPath}: ${rejection.message}`;
                            $injector.get('ErrorLogService').notify(rejection);
                        }

                        redirectPath = $rootScope.homePath;
                    }

                    // see lazyLoad function in this file to
                    // see where handled is defined
                    if (!rejection || !rejection.handled) {
                        // $timeout so that we make sure all resolvers are finished before we redirect.  Otherwise
                        // a resolver can fail after the redirect has already happened and then chaos ensues
                        $timeout(() => {
                            $location.url(redirectPath);
                            $location.search((rejection && rejection.search) || {});
                        });
                    }
                });

                //-----------------------------
                // Back State Tracking
                //-----------------------------

                $rootScope.$on('$routeChangeSuccess', () => {
                    // breaks down during unit testing
                    if (window.RUNNING_IN_TEST_MODE) {
                        return;
                    }

                    // get the directive associated with the current route
                    let currentRouteDirective;
                    try {
                        currentRouteDirective = $route.current.$$route.directive;
                    } catch (e) {
                        // catch errors that happen because any of those intermediate things are unset
                    }
                    if (!currentRouteDirective) {
                        return;
                    }

                    // ensure we're not trying to add duplicate history entries for the last url we've encountered
                    const newUrl = $location.$$url;
                    if (
                        $rootScope.pageHistory.length > 0 &&
                        $rootScope.pageHistory[$rootScope.pageHistory.length - 1].url === newUrl
                    ) {
                        return;
                    }

                    // ensure we're not blacklisted from adding history
                    if (blacklistedRouteDirectives.includes(currentRouteDirective)) {
                        return;
                    }

                    // ensure we're not adding redundant history
                    if (
                        preventSuccessiveRouteDirectives.includes(currentRouteDirective) &&
                        $rootScope.pageHistory.length > 0 &&
                        $rootScope.pageHistory[$rootScope.pageHistory.length - 1].directive === currentRouteDirective
                    ) {
                        return;
                    }

                    // update history
                    $rootScope.pushBackButtonHistory(newUrl, currentRouteDirective);
                });

                // add backwards-navigation support if available
                if ($window.CORDOVA) {
                    $window.document.addEventListener(
                        'backbutton',
                        () => {
                            $rootScope.pageHistory.pop();

                            if ($rootScope.pageHistory.length !== 0) {
                                $location.url($rootScope.pageHistory[$rootScope.pageHistory.length - 1].url);
                                safeApply($rootScope);
                            } else if (window.clientIdentifier === 'android') {
                                // Before exiting the application, we should check that we are on
                                // the "root" path represented by `$rootScope.homePath`.
                                if ($location.url() === $rootScope.homePath) {
                                    window.navigator.app.exitApp();
                                } else {
                                    $location.url($rootScope.homePath);
                                    safeApply($rootScope);
                                }
                            }
                        },
                        false,
                    );
                }
            },
        };
    },
]);
