export default async function canNavigateToCurrentRouteInOfflineMode(injector) {
    const route = injector.get('$route').current;
    const directive = route.$$route.directive;
    const navigatingToStreamPage =
        directive === 'show-stream' || directive === 'stream-dashboard' || directive === 'stream-completed';
    const offlineModeManager = injector.get('offlineModeManager');

    if (directive === 'student-dashboard') {
        return true;
    }

    if (navigatingToStreamPage) {
        return offlineModeManager.streamIdIsAvailableOffline(route.params.stream_id);
    }
    return false;
}
