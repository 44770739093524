import GroupChannelHeader from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useDispatch } from 'react-redux';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { useCallback, useMemo } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { useChannelMetaData } from '../utils/useChannelMetaData';
import { useMessagingConfig } from '../utils/useMessagingConfig';
import { getChannelName } from '../utils/getChannelName';
import { type SerializedChannel } from '../messaging.types';
import {
    setActiveChannelUrl,
    setShowChannelInfo,
    setShowMessageSearch,
    setShowNewMessage,
    setShowProfileUserId,
} from '../actions';
import { MemberAvatars } from './MemberAvatars';

const classes = {
    left: {
        mobileBackButton: clsx(
            'sm:tw-hidden',
            'tw-me-3',
            'tw-flex',
            'tw-items-center',
            'tw-justify-center',
            'hover:tw-bg-slate-grey-lightest',
            'tw-rounded-lg',
            'tw-h-[40px]',
            'tw-w-[40px]',
            '-tw-ms-[15px]',
        ),
        avatars: {
            container: clsx('tw-h-[40px]', 'tw-w-[40px]'),
            indicatorOuter: clsx('tw-w-[12px]', 'tw-h-[12px]'),
            indicatorInner: clsx('tw-min-w-[9px]', 'tw-min-h-[9px]'),
        },
    },
    middle: {
        button: clsx(
            'tw-flex',
            'tw-cursor-pointer',
            'tw-items-center',
            'tw-justify-start',
            'tw-p-2',
            'hover:tw-bg-slate-grey-lightest',
            'tw-rounded-lg',
        ),
        channelName: clsx(
            'tw-line-clamp-2',
            'tw-overflow-ellipsis',
            'tw-text-left',
            'tw-text-lg',
            'tw-text-black',
            'tw-font-semibold',
            'tw-leading-[1]',
        ),
    },
    right: {
        container: clsx('tw-flex', 'tw-items-center'),
        search: {
            button: clsx(
                'tw-h-[40px]',
                'tw-w-[40px]',
                'tw-flex',
                'tw-items-center',
                'tw-justify-center',
                'tw-rounded-lg',
                'hover:tw-bg-slate-grey-lightest',
                'sm:-tw-me-[5px]',
                '-tw-me-[3px]',
            ),
            icon: clsx('tw-text-[20px]', 'tw-text-black'),
        },
    },
};

export const ChannelHeader = ({ channel }: { channel: GroupChannelType | null }) => {
    const dispatch = useDispatch();
    const { userId } = useMessagingConfig();
    const metaData = useChannelMetaData(channel);

    const channelType = metaData?.channelType;

    const handleChannelNameClick = useCallback(() => {
        if (!channel || !channelType) return;

        if (channelType === 'group') {
            dispatch(setShowProfileUserId(null));
            dispatch(setShowChannelInfo(true));
            return;
        }

        if (channelType === '1-1') {
            const otherMember = channel.members.find(member => member.userId !== userId);
            if (!otherMember) return;
            dispatch(setShowProfileUserId(otherMember.userId));
        }
    }, [dispatch, channelType, channel, userId]);

    const handleSearchClick = useCallback(() => {
        dispatch(setShowMessageSearch(true));
        dispatch(setShowProfileUserId(null));
        dispatch(setShowChannelInfo(false));
    }, [dispatch]);

    const channelName = useMemo(() => {
        if (!channel || !userId) return null;
        return getChannelName(channel.serialize() as SerializedChannel, userId);
    }, [channel, userId]);

    const membersForAvatar = useMemo(
        () => channel?.members.filter(member => member.userId !== userId) ?? [],
        [userId, channel],
    );

    if (!channelName || !channel) return null;

    return (
        <GroupChannelHeader
            renderLeft={() => (
                <>
                    <button
                        type="button"
                        className={classes.left.mobileBackButton}
                        onClick={() => {
                            dispatch(setActiveChannelUrl(''));
                            dispatch(setShowNewMessage(false));
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <button type="button" onClick={handleChannelNameClick} title={channelName}>
                        <MemberAvatars
                            className={classes.left.avatars.container}
                            onlineIndicatorOuterClassName={classes.left.avatars.indicatorOuter}
                            onlineIndicatorInnerClassName={classes.left.avatars.indicatorInner}
                            members={membersForAvatar}
                            showOnlineStatus
                        />
                    </button>
                </>
            )}
            renderMiddle={() => (
                <div>
                    <button
                        type="button"
                        className={classes.middle.button}
                        onClick={handleChannelNameClick}
                        title={channelName}
                    >
                        <span className={classes.middle.channelName}>{channelName}</span>
                    </button>
                </div>
            )}
            renderRight={() => (
                <div className={classes.right.container}>
                    <button type="button" className={classes.right.search.button} onClick={handleSearchClick}>
                        <FontAwesomeIcon icon={faSearch} className={classes.right.search.icon} />
                    </button>
                </div>
            )}
        />
    );
};
