import { type GroupChannel } from '@sendbird/chat/groupChannel';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { UserProfile } from './UserProfile';
import { GroupChannelInfo } from './GroupChannelInfo';
import { getShowChannelInfo, getShowProfileUserId } from '../selectors';

type Props = {
    channel: GroupChannel | null;
};

const classes = {
    container: clsx('tw-h-full'),
};

export const ChannelDetailsPanel = ({ channel }: Props) => {
    const showProfileUserId = useSelector(getShowProfileUserId);
    const showChannelInfo = useSelector(getShowChannelInfo);

    if (!channel) return <FrontRoyalSpinner color="force-brand" />;

    return (
        <div className={classes.container}>
            {!!showProfileUserId && <UserProfile profileUserId={showProfileUserId} channel={channel} />}
            {showChannelInfo && !showProfileUserId && <GroupChannelInfo channel={channel} />}
        </div>
    );
};
