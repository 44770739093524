
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.navigation = window.Smartly.locales.modules.en.back_royal.navigation || {};
window.Smartly.locales.modules.en.back_royal.navigation.navigation_helper = window.Smartly.locales.modules.en.back_royal.navigation.navigation_helper || {};
window.Smartly.locales.modules.en.back_royal.navigation.navigation_helper = {...window.Smartly.locales.modules.en.back_royal.navigation.navigation_helper, ...{
    "you_are_offline": "It looks like you're offline!",
    "still_want_to_navigate": "Do you still want to navigate to this external link?",
    "continue": "Continue"
}
}
    