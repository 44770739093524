import oreo from 'Oreo';

export default angular
    .module('FrontRoyal.Oreo', [])
    .constant(oreo.constants)
    .factory('colorHelper', [
        // See http://stackoverflow.com/a/13348458/1747491
        () => ({
            hexToRgb(hex) {
                const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                return result
                    ? {
                          r: parseInt(result[1], 16),
                          g: parseInt(result[2], 16),
                          b: parseInt(result[3], 16),
                      }
                    : null;
            },

            componentToHex(c) {
                const hex = c.toString(16);
                return hex.length === 1 ? `0${hex}` : hex;
            },

            rgbToHex(r, g, b) {
                return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`;
            },

            desaturateColor(sat, hex) {
                sat /= 100;
                const col = this.hexToRgb(hex);
                const gray = col.r * 0.1086 + col.g * 0.2094 + col.b * 0.082;

                col.r = Math.round(col.r * sat + gray * (1 - sat));
                col.g = Math.round(col.g * sat + gray * (1 - sat));
                col.b = Math.round(col.b * sat + gray * (1 - sat));
                return this.rgbToHex(col.r, col.g, col.b);
            },
        }),
    ]);
