import { type BaseChannel, type MetaData } from '@sendbird/chat';
import { useEffect, useState } from 'react';

export const useChannelMetaData = (channel: BaseChannel | null) => {
    const [metaData, setMetaData] = useState<MetaData | null>(null);

    useEffect(() => {
        if (!channel) return;
        channel.getAllMetaData().then(m => setMetaData(m));
    }, [channel]);

    return metaData;
};
