import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { type GroupChannel as GroupChannelType } from '@sendbird/chat/groupChannel';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { Fade } from '@mui/material';
import { type RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useStudentsToMessage } from '../utils/useStudentsToMessage';
import {
    getActiveChannelUrl,
    getShowChannelDetailsPanel,
    getShowMessageSearch,
    getTargetSearchMessage,
} from '../selectors';
import { DateSeparator } from './DateSeparator';
import { MessageContent } from './MessageContent';
import { MessageInputInContext } from './MessageInput';
import { ChannelHeader } from './ChannelHeader';
import { ChannelWrapperContext } from '../ChannelWrapperContext';
import { ChannelDetailsPanel } from './ChannelDetailsPanel';
import { setActiveChannelUrl, setHighlightedMessageId, setTargetSearchMessage, setShowNewMessage } from '../actions';
import { MessageSearch } from './MessageSearch';

const classes = {
    wrapper: clsx('sm:tw-flex', 'tw-w-full'),
    channel: (showChannelDetailsPanel: boolean, showMessageSearch: boolean) =>
        clsx(
            { 'tw-hidden': showMessageSearch },
            {
                'tw-w-full': !showChannelDetailsPanel,
                'md:tw-block tw-hidden': showChannelDetailsPanel,
            },
            'tw-h-full',
            'tw-transition-all',
            'tw-grow',
        ),
    channelDetails: {
        container: (showChannelDetailsPanel: boolean) =>
            clsx('tw-z-10', 'tw-border-s', 'tw-border-slate-grey-light', 'tw-transition-all', {
                'tw-w-0 tw-h-0': !showChannelDetailsPanel,
                'tw-w-full tw-h-full md:tw-basis-[300px] md:tw-max-w-[300px] md:tw-grow-0 md:tw-shrink-0':
                    showChannelDetailsPanel,
            }),
        fade: clsx('tw-h-full'),
        innerWrapper: clsx('tw-h-full'),
    },
};

export const Channel = () => {
    const dispatch = useDispatch();
    const activeChannelUrl = useSelector(getActiveChannelUrl);
    const showChannelDetailsPanel = useSelector(getShowChannelDetailsPanel);
    const [messageRefs, setMessageRefs] = useState<Record<number, RefObject<HTMLDivElement | null> | null>>({});
    const showMessageSearch = useSelector(getShowMessageSearch);
    const targetSearchMessage = useSelector(getTargetSearchMessage);
    const ChannelContainer = useRef<HTMLDivElement>(null);
    const [channel, setChannel] = useState<GroupChannelType | null>(null);

    const sbState = useSendbirdStateContext();
    const sdk = sendbirdSelectors.getSdk(sbState);
    const { isLoading: userDataIsLoading } = useStudentsToMessage();
    useEffect(() => {
        if (!activeChannelUrl) return;
        const getChannel = async () => {
            const ch = await sdk.groupChannel.getChannel(activeChannelUrl);
            setChannel(ch);
        };
        getChannel();
    }, [sdk, activeChannelUrl]);

    // If there is a selected message from message search,
    // poll until the target message is rendered, then scroll to it;
    // see also GroupChannel `startingPoint` prop
    useEffect(() => {
        if (!targetSearchMessage) return undefined;

        let timeout: NodeJS.Timeout;

        const interval = setInterval(() => {
            const targetSearchMessageEl = messageRefs[targetSearchMessage.messageId]?.current;
            if (!targetSearchMessageEl) return;

            targetSearchMessageEl.scrollIntoView({ block: 'center', behavior: 'instant' });
            dispatch(setHighlightedMessageId(targetSearchMessage.messageId));

            // Wait before unsetting in order to highlight the target message for a moment
            timeout = setTimeout(() => {
                dispatch(setTargetSearchMessage(null));
                dispatch(setHighlightedMessageId(null));
            }, 1500);
        }, 500);

        return () => {
            clearInterval(interval);
            clearTimeout(timeout);
        };
    }, [targetSearchMessage, dispatch, messageRefs]);

    const handleMessageRefSet = useCallback((messageId: number, ref: RefObject<HTMLDivElement | null> | null) => {
        setMessageRefs(prev => ({ ...prev, [messageId]: ref }));
    }, []);

    return (
        <ChannelWrapperContext.Provider value={ChannelContainer}>
            <div className={classes.wrapper} ref={ChannelContainer}>
                <div className={classes.channel(showChannelDetailsPanel, showMessageSearch)}>
                    <GroupChannel
                        channelUrl={activeChannelUrl}
                        renderChannelHeader={() => <ChannelHeader channel={channel} />}
                        onBackClick={() => {
                            dispatch(setActiveChannelUrl(''));
                            dispatch(setShowNewMessage(false));
                        }}
                        renderCustomSeparator={DateSeparator}
                        renderMessageContent={p => <MessageContent {...p} onRefSet={handleMessageRefSet} />}
                        renderMessageInput={() => <MessageInputInContext />}
                        startingPoint={targetSearchMessage?.createdAt ? targetSearchMessage.createdAt - 1 : undefined}
                    />
                </div>
                <div className={classes.channelDetails.container(showChannelDetailsPanel)}>
                    <Fade
                        in={!userDataIsLoading}
                        timeout={{ appear: 150, exit: 0 }}
                        className={classes.channelDetails.fade}
                    >
                        <div className={classes.channelDetails.innerWrapper}>
                            <ChannelDetailsPanel channel={channel} />
                        </div>
                    </Fade>
                </div>
                {showMessageSearch && <MessageSearch />}
            </div>
        </ChannelWrapperContext.Provider>
    );
};
