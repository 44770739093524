import { useState, useEffect, useMemo } from 'react';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import sbSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import { type User } from '@sendbird/chat';
import { uniqBy } from 'lodash/fp';
import {
    type CareerProfile,
    type CareerProfileIguanaClass,
    careerProfilesApi,
    type CareerProfileSnakeCasedAttrs,
} from 'CareerProfiles';
import { type AnyObject } from '@Types';
import { useDispatch, useSelector } from 'react-redux';
import transformKeyCase from 'Utils/transformKeyCase';
import { type CandidateListCardViewHelper, type SummarizedProfileInfo } from 'StudentNetwork/StudentNetwork.types';
import { useAngularContext } from 'AngularContext';
import { getDraftUser } from '../selectors';
import { setUserChannelMap } from '../actions';
import { useMessagingConfig } from './useMessagingConfig';

const { useGetRecommendedClassmatesQuery, useLazyGetRelevantSendbirdUserCareerProfilesQuery } = careerProfilesApi;

export const useStudentsToMessage = () => {
    const { userId } = useMessagingConfig();
    const sbContext = useSendbirdStateContext();
    const sdk = sbSelectors.getSdk(sbContext);
    const draftUser = useSelector(getDraftUser);
    const dispatch = useDispatch();
    const $injector = useAngularContext();
    const CareerProfile = $injector.get<CareerProfileIguanaClass>('CareerProfile');
    const { getSummarizedProfileInfo } = $injector.get<CandidateListCardViewHelper>('candidateListCardViewHelper');

    const [triggerCareerProfileQuery, { data: relevantUsers, isLoading: relevantSendbirdUserProfilesIsLoading }] =
        useLazyGetRelevantSendbirdUserCareerProfilesQuery();
    const { data: recommendedClassmates, isLoading: recommendedClassmatesIsLoading } =
        useGetRecommendedClassmatesQuery();

    const [usersToAdd, setUsersToAdd] = useState<User[]>([]);
    const [suggestedUsers, setSuggestedUsers] = useState<User[]>([]);
    const [relevantSendbirdUsersIsLoading, setRelevantSendbirdUsersIsLoading] = useState(false);
    const [classmateSendbirdUsersIsLoading, setClassmateSendbirdUsersIsLoading] = useState(false);
    const [channelsIsLoading, setChannelsIsLoading] = useState(false);

    const [dataIsLoading, setDataIsLoading] = useState(true);

    const userProfilesById = useMemo(() => {
        if (!relevantUsers || !recommendedClassmates) return {};

        return relevantUsers
            .concat(recommendedClassmates, draftUser ? [draftUser.profile] : [])
            .reduce<AnyObject<CareerProfile>>((prev, curr) => ({ ...prev, [curr.userId]: curr }), {});
    }, [relevantUsers, recommendedClassmates, draftUser]);

    const summarizedProfilesByUserId = useMemo(
        () =>
            Object.values(userProfilesById).reduce<AnyObject<SummarizedProfileInfo>>((acc, careerProfileCamelCased) => {
                const snakeCasedAttrs = transformKeyCase<CareerProfileSnakeCasedAttrs>(careerProfileCamelCased, {
                    to: 'snakeCase',
                });
                const careerProfileIguanaObject = CareerProfile.new(snakeCasedAttrs);
                return {
                    ...acc,
                    [careerProfileIguanaObject.user_id]: getSummarizedProfileInfo(careerProfileIguanaObject),
                };
            }, {}),
        [userProfilesById, CareerProfile, getSummarizedProfileInfo],
    );

    const sendbirdUsersByUserId = useMemo(
        () =>
            usersToAdd
                .concat(suggestedUsers, draftUser ? [sdk.buildUserFromSerializedData(draftUser.serializedUser)] : [])
                .reduce<AnyObject<User>>((acc, sendbirdUser) => ({ ...acc, [sendbirdUser.userId]: sendbirdUser }), {}),
        [usersToAdd, suggestedUsers, draftUser, sdk],
    );

    useEffect(() => {
        if (relevantUsers) {
            const query = sdk.createApplicationUserListQuery({ userIdsFilter: relevantUsers.map(u => u.userId) });

            const fetchAll = (q: typeof query, users: User[] = []) => {
                setRelevantSendbirdUsersIsLoading(true);
                if (q.hasNext) {
                    q.next().then(sendbirdUsers => {
                        fetchAll(q, users.concat(sendbirdUsers));
                    });
                } else {
                    setUsersToAdd(
                        users.concat(draftUser ? [sdk.buildUserFromSerializedData(draftUser.serializedUser)] : []),
                    );
                    setRelevantSendbirdUsersIsLoading(false);
                }
            };

            fetchAll(query);
        }
    }, [draftUser, relevantUsers, sdk]);

    useEffect(() => {
        if (!recommendedClassmates) return;

        const channelQuery = sdk.groupChannel.createMyGroupChannelListQuery();
        const userQuery = sdk.createApplicationUserListQuery({
            userIdsFilter: recommendedClassmates.map(u => u.userId),
        });

        const channelPromise = new Promise(r => {
            const fetchAllChannels = (q: typeof channelQuery, u: User[] = [], channelMap: AnyObject<string> = {}) => {
                setChannelsIsLoading(true);
                if (q.hasNext) {
                    q.next().then(channels => {
                        const updatedChannelMap = channels.reduce(
                            (prev, curr) => ({
                                ...prev,
                                [JSON.stringify(
                                    curr.members
                                        .filter(m => m.userId !== userId)
                                        .map(m => m.userId)
                                        .sort(),
                                )]: curr.url,
                            }),
                            channelMap,
                        );
                        const users = channels.flatMap(c => c.members.filter(m => m.userId !== userId));
                        fetchAllChannels(q, [...(users as User[]), ...u], updatedChannelMap);
                    });
                } else {
                    triggerCareerProfileQuery(u.map(user => user.userId).filter(id => id !== userId));
                    dispatch(setUserChannelMap(channelMap));
                    r(u);
                    setChannelsIsLoading(false);
                }
            };
            fetchAllChannels(channelQuery);
        });

        const recommendedClassmatesPromise = new Promise(r => {
            const fetchAllRecommendedClassmates = (q: typeof userQuery, u: User[] = []) => {
                setClassmateSendbirdUsersIsLoading(true);
                if (q.hasNext) {
                    q.next().then(sendbirdUsers => {
                        fetchAllRecommendedClassmates(q, [...u, ...sendbirdUsers]);
                    });
                } else {
                    r(u);
                    setClassmateSendbirdUsersIsLoading(false);
                }
            };
            if (recommendedClassmates?.length) {
                fetchAllRecommendedClassmates(userQuery);
            } else {
                r([]);
            }
        });

        Promise.all([channelPromise, recommendedClassmatesPromise]).then(usersTuple => {
            const [u1, u2] = usersTuple as [User[], User[]];
            setSuggestedUsers(uniqBy('userId', [...u1, ...u2]).filter((_, i) => i < 20));
        });
    }, [dispatch, recommendedClassmates, sdk, triggerCareerProfileQuery, userId, usersToAdd.length]);

    useEffect(() => {
        if (
            !relevantSendbirdUsersIsLoading &&
            !recommendedClassmatesIsLoading &&
            !channelsIsLoading &&
            !classmateSendbirdUsersIsLoading &&
            !relevantSendbirdUserProfilesIsLoading
        ) {
            setDataIsLoading(false);
        }
    }, [
        relevantSendbirdUsersIsLoading,
        recommendedClassmatesIsLoading,
        channelsIsLoading,
        classmateSendbirdUsersIsLoading,
        relevantSendbirdUserProfilesIsLoading,
    ]);

    return {
        usersToAdd,
        suggestedUsers,
        sendbirdUsersByUserId,
        userProfilesById,
        summarizedProfilesByUserId,
        isLoading: dataIsLoading,
    };
};
