import { Route } from 'react-router-dom';
import { route } from 'react-router-typesafe-routes/dom';

export const ROUTES = {
    NOMINATIONS: route('nominations'),
};

async function getLazyNominationsComponent(component: 'Layout' | 'ScholarshipNominations') {
    const components = await import(/* webpackPrefetch: true */ './Nominations');
    return { Component: components[component] };
}

export function getNominationsRoutes() {
    return (
        <Route path={ROUTES.NOMINATIONS.relativePath} lazy={() => getLazyNominationsComponent('Layout')}>
            <Route index lazy={() => getLazyNominationsComponent('ScholarshipNominations')} />
        </Route>
    );
}
