import React, { useCallback, useRef, useState, type ReactNode } from 'react';
import ReactDOM from 'react-dom';
import ReactEmojiPicker from 'emoji-picker-react';
import { useClickAway } from 'react-use';

type Props = {
    // Whether the emoji picker is open. The position should be set via the
    // setter exported from the hook before setting `open` to `true`.
    open: boolean;
    // The emoji key is the unified code (includes skintone) for the emoji.
    // Example: '1f44b-1f3fe' is the waving hand with medium-dark skin tone.
    onEmojiSelection: (emojiKey: string) => void;
    // Whether to show the expandable reactions shortlist on open.
    // Otherwise, shows the full, expanded emoji menu.
    showReactions?: boolean;
    // A callback that can be invoked when clicking outside the emoji container
    onClickOutside?: (e: Event) => void;
};

type EmojiPickerPosition = {
    height: number;
    width: number;
} & ({ top: number } | { bottom: number }) &
    ({ left: number } | { right: number });

/**
 * A hook to open and position an emoji picker
 * and to handle emoji selection
 */
export const useEmojiPicker = ({ open, onEmojiSelection, showReactions = false, onClickOutside }: Props) => {
    const [position, setPosition] = useState<EmojiPickerPosition>({ height: 0, width: 0, top: 0, left: 0 });
    const wrapperRef = useRef<HTMLDivElement>(null);

    useClickAway(wrapperRef, e => {
        onClickOutside?.(e);
    });

    const setPositionFromElement = useCallback(
        (
            triggerElement: Element,
            // The container used to determine the boundaries of where to position the picker.
            // Defaults to the window.
            containerElement?: Element,
        ) => {
            setPosition(calculatePickerPositionForTriggerElement(triggerElement, containerElement));
        },
        [setPosition],
    );

    const EmojiPicker = ReactDOM.createPortal(
        <div data-testid="emoji-picker-wrapper" ref={wrapperRef}>
            <ReactEmojiPicker
                open={open}
                reactionsDefaultOpen={showReactions}
                onReactionClick={reactionClick => onEmojiSelection(reactionClick.unified)}
                onEmojiClick={emojiClick => onEmojiSelection(emojiClick.unified)}
                previewConfig={{ showPreview: false }}
                allowExpandReactions
                // Some of the library styling is overridden in Messaging.scss
                className="messaging-emoji-picker"
                style={{
                    maxHeight: `${position.height}px`,
                    maxWidth: `${position.width}px`,
                    position: 'absolute',
                    zIndex: 100000000,
                    top: 'top' in position ? `${position.top}px` : undefined,
                    right: 'right' in position ? `${position.right}px` : undefined,
                    bottom: 'bottom' in position ? `${position.bottom}px` : undefined,
                    left: 'left' in position ? `${position.left}px` : undefined,
                }}
            />
        </div>,
        document.getElementsByTagName('body')[0],
    ) as ReactNode;

    return { EmojiPicker, setPositionFromElement };
};

/**
 * Calculate the position of the emoji picker based on a click event
 * and the container element. If no container is provided, the window is used.
 */
function calculatePickerPositionForTriggerElement(
    triggerElement: Element,
    containerElement?: Element,
): EmojiPickerPosition {
    // Get the bounding rect of the trigger element and the container element.
    const trigger = triggerElement.getBoundingClientRect();
    // If no container is provided, use the window.
    const container = containerElement
        ? containerElement.getBoundingClientRect()
        : new DOMRect(0, 0, window.innerWidth, window.innerHeight);

    const PICKER_BUFFER = 10;
    const PICKER_MAX_W = Math.min(300, container.width - 20);

    const leftSpace = trigger.right - container.left;
    const rightSpace = container.right - trigger.left;
    const topSpace = trigger.top - container.top;
    const bottomSpace = container.bottom - trigger.bottom;

    const positions = {
        topLeft: {
            height: topSpace - PICKER_BUFFER,
            width: PICKER_MAX_W,
            bottom: window.innerHeight - trigger.top,
            right:
                leftSpace < PICKER_MAX_W
                    ? window.innerWidth - trigger.right - (PICKER_MAX_W - leftSpace)
                    : window.innerWidth - trigger.right,
        },
        bottomLeft: {
            height: bottomSpace - PICKER_BUFFER,
            width: PICKER_MAX_W,
            top: trigger.bottom,
            right:
                leftSpace < PICKER_MAX_W
                    ? window.innerWidth - trigger.right - (PICKER_MAX_W - leftSpace)
                    : window.innerWidth - trigger.right,
        },
        topRight: {
            height: topSpace - PICKER_BUFFER,
            width: PICKER_MAX_W,
            bottom: window.innerHeight - trigger.top,
            left: rightSpace < PICKER_MAX_W ? trigger.left - (PICKER_MAX_W - rightSpace) : trigger.left,
        },
        bottomRight: {
            height: bottomSpace - PICKER_BUFFER,
            width: PICKER_MAX_W,
            top: trigger.bottom,
            left: rightSpace < PICKER_MAX_W ? trigger.left - (PICKER_MAX_W - rightSpace) : trigger.left,
        },
    };

    // Determine the best position based on available space.
    if (leftSpace > rightSpace) return topSpace > bottomSpace ? positions.topLeft : positions.bottomLeft;
    return topSpace > bottomSpace ? positions.topRight : positions.bottomRight;
}
