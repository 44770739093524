import { type SerializedChannel } from '../messaging.types';
import { isSerializedOpenChannel } from './typeUtils';

const DEFAULT_GROUP_CHANNEL_NAME = 'Group Channel';

export const getChannelName = (serializedChannel: SerializedChannel, userId: string) => {
    if (!serializedChannel) return '';

    if (isSerializedOpenChannel(serializedChannel)) return serializedChannel.name;

    if (serializedChannel.name !== DEFAULT_GROUP_CHANNEL_NAME) return serializedChannel.name;

    const members = serializedChannel.members;
    const otherMembers = members.filter(m => m.userId !== userId);

    if (otherMembers.length > 1) return otherMembers.map(m => m.nickname.split(/\s+/g)[0]).join(', ');

    if (otherMembers.length === 1) return otherMembers[0].nickname;

    return '';
};
